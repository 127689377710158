import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Homepages/Footer';
import Finalnav from '../Homepages/Finalnav';
import styled from 'styled-components';

const InitiateCheckoutPage = () => {
  const location = useLocation(); // Access state from navigation
  const navigate = useNavigate(); // Hook to navigate to the payment page
  const cart = location.state?.cart || []; // Extract cart data or fallback to empty array
  const total = cart.reduce((sum, item) => sum + item.price, 0);

  const proceedToPayment = () => {
    navigate('/payment', { state: { cart, total } }); // Pass cart and total to the PaymentPage
  };

  return (
    <Wrapper>
    <div className="page-wrapper">
    <Finalnav />
    <div className="container mt-5">
      <h2>Checkout</h2>
      {cart.length > 0 ? (
        <>
          <ul className="list-group">
            {cart.map((item) => (
              <li key={item.id} className="list-group-item">
                {item.name} - ₹{item.price}
              </li>
            ))}
          </ul>
          <h3 className="mt-3">Total: ₹{total}</h3>
          <button className="btn btn-primary mt-3" onClick={proceedToPayment}>
            Proceed to Payment
          </button>
        </>
) : (
        <p>Your cart is empty. Please add some items to proceed.</p>
      )}
    </div>
    </div>
    <Footer />
    </Wrapper>
  );
};

export default InitiateCheckoutPage;
const Wrapper = styled.div`
/* Ensure full-page layout with fixed navbar and footer */
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 

}

`; 