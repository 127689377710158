import React, { useState } from "react";
import styled from "styled-components";
import Finalnav from "./Homepages/Finalnav";
import { Helmet } from "react-helmet";
import bgcareer from "../components/images/career.webp";
import herocareer from "../components/images/careerhero.webp";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import cogoToast from "cogo-toast";
import Footer from "./Homepages/Footer";

function Career() {
  const [cv, setCv] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [apply_for, setApply_for] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const handleFileChange = (e) => {
    setCv(e.target.files[0]);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleApplyChange = (e) => {
    setApply_for(e.target.value);
  };
  const handlePhone_noChange = (e) => {
    setPhone_no(e.target.value);
  };
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("cv", cv);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("apply_for", apply_for);
      formData.append("phone_no", phone_no);

      await axios.post("https://doaguru.com/api/auth/uploadcv", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      cogoToast.success("Apply successsfully");

      e.target.reset();

      setCv(null);
      setName("");
      setEmail("");
      setApply_for("");
      setPhone_no("");
    } catch (error) {
      cogoToast.error("Something went wrong");
      console.error("Error uploading data:", error);
    }
  };

  return (
    <>
      <Finalnav />
      <Breadcrumb />

      <Wrapper>
        <Helmet>
          <title>Career | In Jabalpur | DOAGuru InfoSystems</title>
          <meta
            name="description"
            content="DOAGuru Info Systems is a rapidly growing IT company. Join us and help the company and your career grow. Call At +91 7440992424"
          />
          <link rel="canonical" href="https://doaguru.com/career" />
        </Helmet>

        <div
          className="container-fluid"
          id="cont7"
          style={{
            backgroundImage: `url(${bgcareer})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row67">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
                Join Our Innovative IT Team and Shape the Future of Technology
              </h1>
              <h5 className="hdd text-white">
                "Welcome to Doaguru Company, where development, creativity, and
                teamwork form the basis of our operations."
              </h5>
              <p className="pdd text-white mt-4">
                As the best IT company and top-tier digital marketing agency, we
                pride ourselves on delivering cutting-edge solutions that drive
                businesses forward in the ever-evolving digital
                landscape.everything we do.
              </p>
              <button
                type="button"
                className="btn  mt-4"
                style={{ color: "white", backgroundColor: " #fe7604" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12" id="heroimg">
              <img
                src={herocareer}
                className="img3 d-block"
                alt="..."
                id="fullscreen"
              />
            </div>
          </div>
        </div>

        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-6">
              <h4>CAREERS</h4>

              <h2 className="ppid mt-4">Find your Future</h2>
              <p className="sys mt-4">
                DOAGuru Info Systems is a fast growing IT Company, Join us and
                Boost the growth of company and your careers.
              </p>
              <p>
                DOAGURU Infosystem offers an in-depth experience in Digital
                Marketing, SEO, BigData Analysis, Android & IOS App development,
                Web Development, Data Analysis for various sectors like
                Healthcare, Manufacturing, Retail, Education, Gas, Power,
                banking & finance etc.
              </p>
              <p>
                We are designing, developing, measuring, and improving next-gen
                solutions across a range of industry verticals.
              </p>
              <p>
                We are a web & mobile app development company specializing in
                the development of iPhone, Android and Web applications.
              </p>
            </div>
            <div className="col-sm-6">
              <form
                onSubmit={handleUpload}
                className="w-100 rounded-1 p-4 border "
                style={{ backgroundColor: "#053B50" }}
              >
                <h2 className="text-white">Drop Your Details</h2>
                <label className="d-block mb-4 text-white">
                  <span className="form-label d-block">Your name</span>
                  <input
                    required
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder=" Enter Your Name"
                    onChange={handleNameChange}
                    value={name}
                  />
                </label>

                <label className="d-block mb-4 text-white">
                  <span className="form-label d-block">Email address</span>
                  <input
                    required
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Emial Id"
                    onChange={handleEmailChange}
                    value={email}
                  />
                </label>
                <label className="d-block mb-4 text-white">
                  <span className="form-label d-block">Phone Number</span>
                  <input
                    required
                    name="phone_no"
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Number"
                    value={phone_no}
                    maxLength={10}
                    onChange={handlePhone_noChange}
                  />
                </label>

                <label className="d-block mb-4 text-white">
                  <span className="form-label d-block">Apply For</span>
                  <input
                    required
                    name="appy_for"
                    type="text"
                    className="form-control"
                    placeholder="E.g web developer"
                    onChange={handleApplyChange}
                    value={apply_for}
                  />
                </label>

                <label className="d-block mb-4 text-white">
                  <span className="form-label d-block">Your CV</span>
                  <input
                    required
                    name="cv"
                    accept=".pdf"
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                </label>

                <div className="but mb-3 text-white">
                  <button type="submit" className="btn  px-3 rounded-3 ">
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="footer12 mt-5 text-center">
            <h3>LEARN AND GROW WITH DOAGURU</h3>
          </div>
        </div>
        <Footer />
      </Wrapper>
    </>
  );
}

export default Career;

const Wrapper = styled.div`
  button {
    background-color: white;
  }
  button:hover {
    background-color: #4caf50;
    color: white;
  }
  #cont2 {
    height: 30rem;

    @media screen and (max-width: 768px) {
      height: 42rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 25rem;
    }
  }
  #about-id {
    text-align: center;
    @media screen and (max-width: 768px) {
    }
  }
  #cardsh {
    text-shadow: 0 1px 0 #ccc, 0 0px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
      0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
      0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
      0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
      0 20px 20px rgba(0, 0, 0, 0.15);
    margin-top: 19rem;

    @media screen and (max-width: 768px) {
      margin-top: 17rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 10rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 19rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }

  #cont7 {
    height: 33rem;

    @media screen and (max-width: 768px) {
      height: 58rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 53rem;
    }
  }
  #row67 {
    margin-left: 3rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  .img3 {
    margin-top: 3rem;
    width: 85%;
    margin-left: 4rem;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 3rem;
      width: 50%;
      height: 20rem;
      margin-left: 11rem;
    }

    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 0rem;
      width: 70%;
      margin-left: 10rem;
    }
  }
`;
