import React, { useState } from "react";
import logodental from "../components/images/logodental.webp";
import img1 from "../components/images/productImage/3.png";
import img2 from "../components/images/productImage/4.png";
import img3 from "../components/images/productImage/1.png";
import img4 from "../components/images/productImage/2.png";
import img5 from "../components/images/productImage/5.png";
import img6 from "../components/images/productImage/6.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Finalnav from "./Homepages/Finalnav";
import { Helmet } from "react-helmet";
import { MdDoubleArrow } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PricingCards from "./PaymentGatewayPages/PricingCards";

function Dentalguru() {
  const [cart, setCart] = useState([]);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };

  const [isShowMore, setIsShowMore] = useState(false);
  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  const addToCart = (product) => setCart([...cart, product]);

  return (
    <Wrapper>
      <Finalnav />
      <Helmet>
        <title>
          Best Dental Software | Dental Practice Management Solution
        </title>
        <meta
          name="description"
          content="DentalGuru is the best dental software for managing appointments, diagnoses, prescriptions, and staff. Experience the best dental software in India today.
          "
        />
        <link rel="canonical" href="https://doaguru.com/dental-guru" />
      </Helmet>
      <div className="container-fluid product-page">

        <div className="logo-container ">
          <div className="logo">
            <img src={logodental} alt="Logo" />
          </div>
          <div className="content-name Product-name d-flex flex-column  justify-content-center align-items-center">

            <h1 style={{ color: "#00C7C7" }}>Dental Clinic Management</h1>
            <h2 className="">
              We Care About Your Clinic

            </h2>
          </div>
        </div>

        <div className="container product-box ">
          <div className="row">
            <div className="col-lg-6 col-md-6 m-auto " id="mobile">
              <h1 style={{ "color": "#00C7C7", "margin": "0" }}>DentalGuru Pro</h1>
              {/* <img id="clientpages6" src={heroimg} className="" /> */}
              <div className="image-slider">
                <Slider {...sliderSettings}>
                  {[img1, img2, img3, img4, img5, img6].map((img, index) => (
                    <div key={index}>
                      <img src={img} alt={`Slide ${index + 1}`} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 m-auto fs-2">
              <ul className="feature-list">
                {[
                  "Patient Appointment",
                  "Smart Diagnosis System",
                  "Smart Prescription System",
                  "Staff Management",
                  "Patient Data Management",
                  "Account Management",
                  "Inventory Management",
                  "Multi Branch Management",
                ].map((feature, index) => (
                  <li key={index}>
                    <MdDoubleArrow color="blue" /> {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="btnDiv ">
            <div className="flex buttons">
              <Link
                to="/contact_us"
                onClick={handleFooterLink}
                className="btn mt-4 mx-5 rounded-3 buttonBook "
                style={{ backgroundColor: "transperent", border: "solid blue " }}
                id="btn6"
              >
                Book Now
              </Link>
              <Link
                to=""

                onClick={toggleReadMoreLess}
                className="btn mt-4 mx-5 rounded-3 buttonBook "
                style={{ backgroundColor: "transperent", border: "solid green " }}
                id="btn6"
              >
                Check Plans
              </Link>
            </div>

            <h2 className="mt-4 font-weight-bold" style={{ color: "#002c63" }}>
              FREE 15 DAY TRIAL
            </h2>
          </div>
        </div>

        {isShowMore && (

          <div className="d-flex flex-column justify-content-center align-items-center ">
            <div className="PriceCards d-flex justify-content-center align-items-center">

              <PricingCards />
            </div>
            <div>

              <span style={{ color: "red" }}>*</span>An additional 18% GST will be applicable to all plans.
            </div>
          </div>
        )}

        <div className="product-box p-0 px-2">
          <div class="cta-section text-center my-5">
            <h2 style={{ color: "#00C7C7" }}>Discover More About Us</h2>
            <p>
              Ready to enhance your dental practice? Visit our website to learn more about our features and services. Click the link below to explore the comprehensive solutions we offer for dental clinics.
            </p>
            <a href="https://dentalguru.software/" target="_blank" rel="noopener noreferrer" class="btn btn-primary">
              Visit DentalGuru
            </a>
          </div>
        </div>
        <div className="product-box">
          <div class="product-description">
            <h2>DentalGuru - Comprehensive Dental Clinic Management Solution</h2>
            <p><strong>DentalGuru</strong> is an all-in-one, cutting-edge web application designed specifically for dentists, helping you streamline clinic management, improve patient care, and maximize efficiency. With a host of powerful features, <strong>DentalGuru</strong> simplifies the daily operations of dental clinics, whether you're a single clinic or managing multiple branches. Below is an in-depth breakdown of the features:</p>

            <h2>1. Patient Appointment Management</h2>
            <p>Efficient appointment management is the backbone of any successful dental clinic. <strong>DentalGuru</strong> provides a fully automated, user-friendly appointment booking system that allows patients to book, reschedule, or cancel appointments online. Dentists and clinic staff can monitor schedules in real-time, avoid double bookings, and allocate time slots effectively. The system sends automated reminders via SMS or email to reduce no-shows. With color-coded schedules and a calendar view, you can easily keep track of appointments across multiple dentists and treatment rooms.</p>
            <ul>
              <li><MdDoubleArrow /> Real-time synchronization of appointments</li>
              <li><MdDoubleArrow /> Automated patient reminders</li>
              <li><MdDoubleArrow /> Conflict-free scheduling</li>
              <li><MdDoubleArrow /> Better utilization of clinic resources</li>
            </ul>

            <h2>2. Smart Diagnosis System</h2>
            <p>This feature leverages intelligent algorithms to assist dentists in making quicker, more accurate diagnoses. After entering patient symptoms, clinical observations, and test results, the system suggests potential diagnoses based on medical history and standard dental procedures. The smart diagnosis system helps reduce the time spent on manual research and supports decision-making for complex cases, ensuring better patient outcomes.</p>
            <ul>
              <li><MdDoubleArrow /> Faster and accurate diagnosis suggestions</li>
              <li><MdDoubleArrow /> Reduces manual errors</li>
              <li><MdDoubleArrow /> Data-driven treatment options</li>
              <li><MdDoubleArrow /> Enhanced decision support for complex cases</li>
            </ul>

            <h2>3. Smart Prescription System</h2>
            <p>The smart prescription system simplifies the process of prescribing medications, reducing errors and saving valuable time for dentists. After diagnosing the patient, the system can generate a prescription that includes accurate drug information, dosage, and instructions. Dentists can also store and reuse frequently prescribed medications, creating templates for quicker prescription generation in future visits.</p>
            <p>Additionally, patients receive digital copies of their prescriptions, and the system integrates with pharmacies to ensure smooth prescription fulfillment.</p>
            <ul>
              <li><MdDoubleArrow /> Automated prescription generation</li>
              <li><MdDoubleArrow /> Error-free medication and dosage details</li>
              <li><MdDoubleArrow /> Prescription templates for quick access</li>
              <li><MdDoubleArrow /> Digital prescriptions shared with patients and pharmacies</li>
            </ul>

            <h2>4. Staff Management</h2>
            <p>Managing a dental clinic’s staff can be complex, especially when tracking performance, managing payroll, and scheduling shifts. <strong>DentalGuru</strong> provides a centralized system to manage all HR-related functions. Keep track of staff attendance, working hours, and assign tasks to ensure the smooth operation of your clinic. The system also integrates with payroll, automating salary calculations and reducing administrative work. Managers can generate reports to assess staff performance and make data-driven decisions regarding staff training or hiring.</p>
            <ul>
              <li><MdDoubleArrow /> Simplifies HR management</li>
              <li><MdDoubleArrow /> Automates payroll processing</li>
              <li><MdDoubleArrow /> Monitors staff performance and attendance</li>
              <li><MdDoubleArrow /> Task and shift management for efficient operations</li>
            </ul>

            <h2>5. Patient Data Management</h2>
            <p>Patient information is one of the most valuable assets in a dental clinic. <strong>DentalGuru</strong> ensures that all patient data, including medical history, treatment plans, X-rays, and other clinical documents, are securely stored and easily accessible. The system complies with healthcare data security standards, ensuring privacy and protection. With an intuitive interface, dentists can pull up patient records instantly, aiding in consultations and follow-up care. This streamlined access to data allows for personalized patient experiences and continuity of care.</p>
            <ul>
              <li><MdDoubleArrow /> Centralized and secure patient records</li>
              <li><MdDoubleArrow /> Easy retrieval of medical history and X-rays</li>
              <li><MdDoubleArrow /> Enhanced patient care with data-driven insights</li>
              <li><MdDoubleArrow /> Compliance with healthcare data privacy standards</li>
            </ul>

            <h2>6. Account Management</h2>
            <p>Financial management is a key part of running a successful clinic. <strong>DentalGuru</strong> offers comprehensive account management tools that help you keep track of income, expenses, and profits. From invoicing patients to managing payments for services and treatments, this system automates many of the manual tasks involved in managing a clinic’s finances. You can also generate detailed financial reports, which help in assessing the financial health of your clinic and making informed business decisions.</p>
            <ul>
              <li><MdDoubleArrow /> Automated invoicing and billing</li>
              <li><MdDoubleArrow /> Detailed financial reporting</li>
              <li><MdDoubleArrow /> Easy tracking of income and expenses</li>
              <li><MdDoubleArrow /> Simplifies tax preparation and accounting tasks</li>
            </ul>

            <h2>7. Multi-Branch Management</h2>
            <p>If you own or manage multiple dental clinics, keeping track of operations across different locations can be challenging. <strong>DentalGuru</strong> simplifies this with its multi-branch management system, allowing you to manage all branches from a single platform. You can monitor each branch’s performance, standardize procedures across locations, and ensure that all data is centrally accessible. The system also supports individual reporting for each branch, allowing you to assess profitability and performance for each location separately.</p>
            <ul>
              <li><MdDoubleArrow /> Centralized control over multiple branches</li>
              <li><MdDoubleArrow /> Standardized processes across locations</li>
              <li><MdDoubleArrow /> Branch-specific performance monitoring</li>
              <li><MdDoubleArrow /> Consolidated reporting for multi-clinic operations</li>
            </ul>

            <h3>Conclusion</h3>
            <p><strong>DentalGuru</strong> is more than just a clinic management tool—it’s a complete solution designed to improve every aspect of a dental practice. By streamlining operations, ensuring data security, and enhancing patient care, <strong>DentalGuru</strong> empowers dental professionals to focus more on what matters most: their patients.</p>
          </div>
          <div className="btnDiv ">

            <Link
              to="/contact_us"
              onClick={handleFooterLink}
              id="btn6"
            ><button className="btn mt-4 mx-5 rounded-3 buttonBook " style={{ backgroundColor: "transperent", border: "solid blue " }}>
                Book Now
              </button></Link>

            <h2 className="mt-4 font-weight-bold" style={{ color: "#002c63" }}>
              FREE 15 DAY TRIAL
            </h2>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Dentalguru;
const Wrapper = styled.div`

.product-page{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
ul {
list-style: none;
}
  .underline {
    height: 4px;
    width: 33rem;
    background-color: #4cd3d9;
    margin-top: 2;
    margin-bottom: 10;
    @media screen and (max-width: 768px) {
      width: 22rem;
    }
  }
// Mobile Screen ke liye 
@media screen and (max-width: 588px) {
.logo-container {
  display: flex;
  
}
.Product-name{
  width: 50%;
}
  .feature-list{
  font-size: 17px; 
  }
  .buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  }
}
// Mobile Screen ke liye 
@media screen and (max-width: 360px) {
.product-box{
padding: 1rem;!important
}

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-container img{
  width: 6rem;
}
.Product-name{
  width: 50%;
  
}
  .logo{
    width: 40%
  }
  .content-name{
    width: 60%;
  }
  .feature-list{
  font-size: 15px; 
  }
.PriceCards{
  
}
}
@media screen and (min-width: 600px) {
.logo-container {
      display: flex;
    justify-content: center;
    align-items: center;
  }
    .product-box{
      padding: 3rem;
    }
  }



  .btnDiv{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .image1{
  display: flex;
  justify-content: center;
  width: 25rem;
  }
  .product-box{
    border: solid;
    border-radius: 2.5rem;
    border-color: cadetblue;
    margin: 1rem;
    
  }
.buttonBook:hover {
  background-color: black;
  color: white;
  font-weight: bold;
}
    .image-slider img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
  }


`;
