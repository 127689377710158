import React, { useEffect } from "react";
import styled from "styled-components";
import Finalnav from "../Homepages/Finalnav";

import img1 from "../../components/img/webBased/1.png";
import img2 from "../../components/img/webBased/2.png";
import img3 from "../../components/img/webBased/3.png";
import img4 from "../../components/img/webBased/4.png";
import img5 from "../../components/img/webBased/5.png";
import img6 from "../../components/img/webBased/6.png";
import img55 from "../../components/img/webBased/55.png";
import { Link } from "react-router-dom";
import Slidebtn from "../../components/Slidebtn";
import Footer from "../Homepages/Footer";


const Webbased = () => {

  return (
    <>
    <Slidebtn pathData={"/webbased"}/>
      <Finalnav />
      <WebBased>
        <div className="container-fluid" id="webbased">
          <div className="row d-flex gap-5">
            <div className="col-12  d-flex justify-content-center">
              {/* doaGurcard start */}
              <div className="cardDoa mt-5">
                <div className="row p-5 h-100 d-flex align-items-center gap-3 gap-md-0">
                  <div className="col-md-8">
                    <div className="row d-flex gap-4">
                      <div className="col-12">
                        <h2>Web Based Application</h2>
                      </div>
                      <div className="col-12">
                        <p>
                          Boost your brand with DOAGuru InfoSystems, where happy
                          clients are our success story. Experience digital
                          growth like never before
                        </p>
                      </div>
                      <div className="col-12">
                        <Link to="/contact_us" className="doaBtn border-0 text-white p-2 rounded-3">
                          Connect Us
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 d-flex justify-content-center">
                    <img
                      className="img-fluid rounded-1"
                      style={{ width: "210px" }}
                      src="https://img.freepik.com/free-vector/isometric-cms-concept_23-2148807389.jpg"
                    />
                  </div>
                </div>
              </div>
              {/* doaGurcard end */}
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div
                className="bg-dark rounded-2"
                style={{
                  height: "7px",
                  width: "90px",
                }}
              ></div>
            </div>

            <div className="col-12 d-flex justify-content-center">
              <div className="WebAppcom d-flex flex-wrap gap-5 gap-lg-5  justify-content-evenly">
                <div className="cardWeb rounded-3">
                  <div className="h-100 overflow-hidden p-1 rounded-1 d-flex justify-content-center">
                    <img className="img img-fluid" src={img1} alt="" />
                  </div>
                  <div
                    className="text-white gap-1 h-25 position-absolute bottom-0 d-flex flex-column justify-content-center align-items-center"
                    style={{
                      background:
                        "linear-gradient(to top,rgba(0, 0, 0, 0.60) 60%,transparent",
                    }}
                  >
                    <a className="border-0 p-1 px-2 rounded-2" href="#"> 
                      {/* https://grocery4jabalpur.com/ */}
                      Visit Website
                    </a>
                    <h5 className="m-0">Grocery4jabalpur</h5>
                    <p style={{ fontSize: "13px" }} className="m-0">
                      E-Commerce Website
                    </p>
                  </div>
                </div>

                <div className="cardWeb rounded-3">
                  <div className="h-100 overflow-hidden p-1 rounded-1 d-flex justify-content-center">
                    <img className="img img-fluid" src={img3} alt="" />
                  </div>
                  <div
                    className="text-white gap-1 h-25 position-absolute bottom-0 d-flex flex-column justify-content-center align-items-center"
                    style={{
                      background:
                        "linear-gradient(to top,rgba(0, 0, 0, 0.60) 60%,transparent",
                    }}
                  >
                    <a className="border-0 px-2 p-1 rounded-2" href="https://grocery4jabalpur.com/">
                      {/* https://shadivivah.co.in/about-us/ */}
                      Visit Website
                    </a>
                    <h5 className="m-0">Shadi Vivah</h5>
                    <p style={{ fontSize: "13px" }} className="m-0">
                      {"Shadi Vivah"}
                    </p>
                  </div>
                </div>

                <div className="cardWeb rounded-3 ">
                  <div className="h-100 overflow-hidden p-1 rounded-1 d-flex justify-content-center shadow">
                    <img className="img  img-fluid" src={img4} alt="" />
                  </div>
                  <div
                    className="text-white gap-1 h-25 position-absolute bottom-0 d-flex flex-column justify-content-center align-items-center"
                    style={{
                      background:
                        "linear-gradient(to top,rgba(0, 0, 0, 0.60) 60%,transparent",
                    }}
                  >
                    <a className="border-0 px-2 p-1 rounded-2" href="https://www.shridhamhospitalgotegaon.com/">
                      Visit Website
                    </a>
                    <h5 className="m-0">Shridham Hospital</h5>
                    <p style={{ fontSize: "13px" }} className="m-0">
                      {"Hospital"}
                    </p>
                  </div>
                </div>

                <div className="cardWeb rounded-3">
                  <div className="h-100 overflow-hidden p-1 rounded-1 d-flex justify-content-center">
                    <img className="img img-fluid" src={img5} alt="" />
                  </div>
                  <div
                    className="text-white gap-1 h-25 position-absolute bottom-0 d-flex flex-column justify-content-center align-items-center"
                    style={{
                      background:
                        "linear-gradient(to top,rgba(0, 0, 0, 0.60) 60%,transparent",
                    }}
                  >
                    <a className="border-0 px-2 p-1 rounded-2" href="https://drpawansthapak.com/">
                      Visit Website
                    </a>
                    <h5 className="m-0">Dr. Pawan Sthapak</h5>
                    <p style={{ fontSize: "13px" }} className="m-0">
                      {"Hospital"}
                    </p>
                  </div>
                </div>

                <div className="cardWeb rounded-3">
                  <div className="h-100 overflow-hidden p-1 rounded-1 d-flex justify-content-center">
                    <img className="img img-fluid" src={img55} alt="" />
                  </div>
                  <div
                    className="text-white gap-1 h-25 position-absolute bottom-0 d-flex flex-column justify-content-center align-items-center"
                    style={{
                      background:
                        "linear-gradient(to top,rgba(0, 0, 0, 0.60) 60%,transparent",
                    }}
                  >
                    <a className="border-0 px-2 p-1 rounded-2" href="https://janjyotieyehospital.com">
                      Visit Website
                    </a>
                    <h5 className="m-0">Jan Jyotieye</h5>
                    <p style={{ fontSize: "13px" }} className="m-0">
                      {"Hospital"}
                    </p>
                  </div>
                </div>

                <div className="cardWeb rounded-3">
                  <div className="h-100 overflow-hidden p-1 rounded-1 d-flex justify-content-center">
                    <img className="img img-fluid" src={img6} alt="" />
                  </div>
                  <div
                    className="text-white gap-1 h-25 position-absolute bottom-0 d-flex flex-column justify-content-center align-items-center"
                    style={{
                      background:
                        "linear-gradient(to top,rgba(0, 0, 0, 0.60) 60%,transparent",
                    }}
                  >
                    <a className="border-0 px-2 p-1 rounded-2" href="https://bharatroofers.com">
                      Visit Website
                    </a>
                    <h5 className="m-0">Bharatroofers</h5>
                    <p style={{ fontSize: "13px" }} className="m-0">
                      {"House"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WebBased>
      <Footer />
    </>
  );
};

export default Webbased;

const WebBased = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 29px;
  background-color: #e2eafc;

  //  btn
  button,
  a {
    background-color: rgb(0, 43, 98);
    cursor: pointer;
    position: relative;
    color: white;
    text-decoration: none;
    transition: all 0.4s ease;
  }
  button:hover,
  a:hover {
    transform: scale(0.9);
    background-color: green;
    color: white;
  }

  //cardDoa
  .cardDoa {
    width: 900px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.253);
    transition: all 0.4s;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .cardDoa:hover {
    transform: scale(0.9);
    background-color: white;
    color: black;
  }

  //  WebAppcom
  .WebAppcom {
    width: 1000px;
  }
  .cardWeb {
    width: 280px;
    height: 400px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.267);
    transition: all 0.6s;
    box-shadow: 37px 38px 26px -24px rgba(0, 0, 0, 0.28);
    background-color: white;
  }
  .cardWeb:hover {
    transform: scale(1.1);
  }
  .cardWeb div {
    width: 100%;
  }
  .cardWeb .img {
    height: 200%;
    background-color: antiquewhite;
    color: black;
    transition: all 1s;
  }
  .cardWeb:hover .img {
    transform: translateY(-50%);
    cursor: pointer;
  }
`;
