const blogsData = [
  {
    title: "10 Essential Skills Every Digital Marketer Needs to Know",
    description:
      "Unlock the secrets to success in the digital marketing arena with our comprehensive guide. Explore the core skills essential for every digital marketer to thrive. From SEO mastery to content strategy, dive into the 10 fundamental skills that will propel your digital marketing expertise to new heights.",
    link: "/10-Essential-Skills-Every-Digital-Marketer-Needs-to-Know",
    image: require("../../components/images/blog.jpeg"),
  },
  {
    title: "Optimizing Your Business: Because Google Can't Read Your Mind... Yet",
    description:
      "Enhance your business's digital presence with strategic optimization techniques. From SEO to user-friendly design, we'll ensure your online visibility speaks the language of success, even before Google can read your mind.",
    link: "/Optimizing-Your-Business-Because-Google-Can't-Read-Your-Mind-Yet",
    image: require("../../components/images/blogimg2.webp"),
  },
  {
    title: "Email Marketing: Because Your Inbox Was Feeling Lonely",
    description:
      "Dive into engaging articles that breathe life into your inbox, offering strategies to boost engagement, craft compelling content, and maximize the potential of this powerful marketing tool. Join us on this digital journey to make your emails stand out in the crowd!",
    link: "/Email-Marketing-Because-Your-Inbox-Was-Feeling-Lonely",
    image: require("../../components/images/blogimg3.webp"),
  },
  {
    title: "Unveiling the Secrets of the Best Graphic Designing Company for Your Creative Dreams!",
    description:
      "Discover our vibrant blog - a treasure trove of insights, tips, and trends in the world of email marketing. Dive into engaging articles that breathe life into your inbox, offering strategies to boost engagement, craft compelling content, and maximize the potential of this powerful marketing tool.",
    link: "/Unveiling-the-Secrets-of-the-Best-Graphic-Designing-Company-for-Your-Creative-Dreams!",
    image: require("../../components/images/blog4.webp"),
  },
  {
    title: "Best Graphic Designing Company in Jabalpur: Elevate Your Brand Presence",
    description:
      "Elevate your brand to new heights with the best graphic designing company in Jabalpur. Our expert team combines creativity and precision to craft visually stunning designs that leave a lasting impression.",
    link: "/Best-Graphic-Designing-Company-in-Jabalpur-Elevate-Your-Brand-Presence",
    image: require("../../components/images/blog5.webp"),
  },
  {
    title: "Cybersecurity Essentials: Protecting Your Business in a Digital Age",
    description:
      "In today's era, the need for cybersecurity measures is more crucial than ever. As businesses increasingly rely on platforms, the risk of cyber threats becomes a concern. DOAGuru InfoSystems, renowned as the best digital marketing company in Jabalpur, understands this need.",
    link: "/Cybersecurity-Essentials-Protecting-Your-Business-in-a-Digital-Age",
    image: require("../../components/images/blog6.webp"),
  },
  {
    title: "The Future of SEO: Predictions and Strategies for 2024",
    description:
      "In the dynamic world of digital marketing, peering into the future to anticipate trends and adapt strategies is crucial for staying ahead. As we approach 2024, the landscape of search engine optimization (SEO) is on the brink of significant evolution.",
    link: "/The-Future-of-SEO:-Predictions-and-Strategies-for-2024",
    image: require("../../components/images/blogsimage.jpg"),
  },
  {
    title: "Performance Marketing vs. Digital Marketing: Understanding the Key Differences",
    description:
      "In today's world of online business, companies are always looking for ways to make the most of their online presence and achieve tangible results. Two strategies that often come into play are performance marketing and digital marketing.",
    link: "/Performance-Marketing-vs-Digital-Marketing:-Understanding-the-Key-Differences",
    image: require("../../components/images/performblog.png"),
  },
  {
    title: "Boost Your Online Presence: Best SEO Company in Madhya Pradesh",
    description:
      "In today's era, establishing an online presence is essential for any business looking to succeed in the competitive market environment. With websites competing for attention, how can yours make a mark? The key lies in SEO (Search Engine Optimization).",
    link: "/Best-SEO-company-in-Madhya-pradesh",
    image: require("../../components/images/blog9.jpg"),
  },
  {
    title: "The Best IT Company in Jabalpur - DOAGuru InfoSystems",
    description:
      "In the bustling realm of Information Technology within Jabalpur, there’s one name that resonates with excellence and innovation - DOAGuru InfoSystems. Established with a vision to redefine software solutions, DOAGuru has solidified its position as the leading IT company in Jabalpur.",
    link: "/The-Best-IT-Company-in-Jabalpur-DOAGuru-Infosystems",
    image: require("../../components/images/blog10.jpg"),
  },
  {
    title: "Best Software Development Company in Jabalpur: DOAGuru InfoSystems",
    description:
      "Welcome to DOAGuru InfoSystems, recognized as the best software company in Jabalpur. In this blog, we'll explore why DOAGuru InfoSystems stands out as the leading software development firm in Jabalpur and how we can help businesses like yours thrive in the digital age.",
    link: "/Best-Software-Development-Company-in-Jabalpur",
    image: require("../../components/images/Blog11.png"),
  },
  {
    title: "The Best Digital Marketing Company in Madhya Pradesh",
    description:
      "At DOAGuru InfoSystems, we pride ourselves on being the best digital marketing company in Madhya Pradesh. Our commitment to excellence, innovative strategies, and customer-centric approach make us the preferred choice for businesses looking to enhance their online presence.",
    link: "/Best-Digital-Marketing-Company-in-Madhya-Pradesh",
    image: require("../../components/images/Blog12.jpg"),
  },
  {
    title: "Ultimate Dental Clinic Practice Management Software",
    description:
      "Managing a dental practice is a complex process that requires exceptional precision and efficiency. Enter Dental Guru, the ultimate dental clinic practice management software, designed to streamline operations and enhance patient care.",
    link: "/Ultimate-Dental-Practice-Management-Software",
    image: require("../../components/images/dentalGuruBlog.jpg"),
  },
  {
    title: "AI in Digital Marketing: Revolutionizing the Industry with DOAGuru IT Solutions",
    description:
      "The rise of artificial intelligence (AI) is reshaping industries globally, and digital marketing is no exception. AI is transforming how businesses reach, engage, and retain customers, offering personalized experiences and . . .",
    link: "/ai-in-digital-marketing",
    image: require("../../components/images/BlofAiImage.jpg"),
  },
  {
    title: "Can You Get a Digital Marketing Job Without Any Experience?",
    description:
      "Kickstart your career in digital marketing with no prior experience! Learn essential skills, explore free resources, and build a strong foundation with expert guidance from DOAGuru InfoSystem, the Best Digital Marketing Company in Jabalpur...",
    link: "/Can-You-Get-a-Digital-Marketing-Job-Without-Any-Experience-?",
    image: require("../../components/images/Blog15img.jpg"),
  },
  {
    title: "What is Digital Marketing? A Comprehensive Guide by DOAGuru InfoSystems",
    description:
      "In today’s fast-paced, tech-savvy world, businesses have shifted their focus from traditional marketing strategies to digital marketing. But what exactly is digital marketing? Why is it so important, and how can businesses leverage ....",
    link: "/What-is-Digital-Marketing-A-Comprehensive-Guide-by-DOAGuru-InfoSystems",
    image: require("../../components/images/Blog16img.jpg"),
  },
  {
    title: "10 Skills You Need to Become a Digital Marketing Manager",
    description:
      "In today’s fast-paced digital world, businesses rely heavily on digital marketing to grow and thrive. A Digital Marketing Manager plays a pivotal role in strategizing and executing campaigns that drive brand awareness, customer engagement, and revenue growth....",
    link: "/10-Skills-You-Need-to-Become-a-Digital-Marketing-Manager",
    image: require("../../components/images/Blog17img.jpg"),
  },
  {
    title: "How to Create an AI Digital Marketing Strategy?",
    description:
      "Artificial Intelligence (AI) has revolutionized digital marketing, enabling businesses to understand their audience, automate processes, and deliver highly personalized experiences. Crafting an AI-driven digital marketing strategy is not just a trend but a necessity for....",
    link: "/How-to-Create-an-AI-Digital-Marketing-Strategy?",
    image: require("../../components/images/Blog18img.jpg"),
  },
  {
    title: "Digital Marketing Skills, Career Opportunities, and Success Tips for 2025",
    description:
      "Discover essential digital marketing skills, career opportunities, and strategies to excel in 2025. Learn how to stay ahead in this dynamic field with actionable insights....",
    link: "/Digital-Marketing-Career-Opportunities-in-2025",
    image: require("../../components/images/Blog19img.jpg"),
  },
  {
    title: "DeepSeek: The New AI Challenger to ChatGPT",
    description:
      "Discover DeepSeek, a powerful open-source AI model from China, designed as a cost-effective alternative to ChatGPT. Learn about its development, featuress....",
    link: "/DeepSeek-The-New-AI-Challenger-to-ChatGPT",
    image: require("../../components/images/Blog20img.jpg"),
  },
  {
    title: "Doaguru Infosystems: Best Digital Marketing Agency in Jabalpur",
    description:
      "Doaguru Infosystems is a leading digital marketing agency in Jabalpur offering SEO, website design, PPC, and social media marketing at affordable prices.....",
    link: "/Digital-Marketing-Agency-in-Jabalpur",
    image: require("../../components/images/Blog21img.png"),
  },
  {
    title: "Top Digital Marketing Tools for Success in 2025: Essential Tools You Should Use ",
    title: "Top Digital Marketing Tools for Success in 2025: Essential Tools You Should Use ",
    description:
      "Discover the top digital marketing tools for SEO, content marketing, social media, email campaigns, and more. Learn how to optimize your marketing efforts with the best tools in 2025......",
    link: "/Top-Digital-Marketing-Tools-for-Success-in-2025",
    image: require("../../components/images/Blog22img.jpg"),
  },
  {
    title: "Influencer Marketing: The Ultimate Guide | DOAGuru InfoSystems ",
    description:
      "Boost your brand with influencer marketing! DOAGuru InfoSystems, the Best Digital Marketing Company in Jabalpur, helps you enhance visibility & engagement.......",
    link: "/Influencer-Marketing-The-Ultimate-Guide ",
    image: require("../../components/images/Blog23img.jpg"),
  },
  {
    title: "Should You Choose a Career in Digital Marketing? | Growth & Opportunities",
    description:
      "Explore the benefits of a career in digital marketing, top job roles, skills required & future trends. Learn why it's the best field to grow & succeed!.......",
    link: "/Should-You-Choose-a-Career-in-Digital-Marketing?",
    image: require("../../components/images/Blog24img.jpg"),
  },
  {
    title: "How to Do a Competitive Analysis in Digital Marketing – Best SEO & Marketing Strategies",
    description:
      "Learn how to conduct a competitive analysis in digital marketing. Discover top strategies to stay ahead and rank as the best digital marketing company in Jabalpur........",
    link: "/How-to-Do-a-Competitive-Analysis-in-Digital-Marketing",
    image: require("../../components/images/blog25img.jpg"),
  },
  {
    title: "Best Mobile App Development in Jabalpur | Doaguru Infosystems",
    description:
      "In today’s fast-paced digital world, mobile apps have become a necessity for businesses looking to grow and expand their customer base. Whether you own a startup, a local business, or a well-established company,......",
    link: "/Best-Mobile-App-Development-in-Jabalpur",
    image: require("../../components/images/blog26img.jpg"),
  },
  {
    title: "Best Social Media Marketing Agency in Jabalpur - DOAGuru InfoSystems",
    description:
      "In today’s digital era, having a strong online presence is essential for any business. If you are looking for the best social media marketing agency in Jabalpur, look no further than DOAGuru InfoSystems. ......",
    link: "/Best-Social-Media-Marketing-Agency-in-Jabalpur-DOAGuru-InfoSystems",
    image: require("../../components/images/blog27img.jpg"),
  },
  {
    title: "Best Digital Marketing Company in Indore: Unlocking Online Success for Businesses",
    description:
      "In today’s digital-first world, businesses in Indore are rapidly embracing online strategies to stay competitive and reach their target audience. As one of India’s fastest-growing commercial hubs, Indore is home to a thriving......",
    link: "/Best-Digital-Marketing-Company-in-Indore",
    image: require("../../components/images/blog28img.jpg"),
  },
  {
    title: "Best Digital Marketing Company in Bhopal – Elevate Your Online Presence",
    description:
      "In today’s competitive digital landscape, businesses in Bhopal are increasingly relying on digital marketing to expand their reach, attract customers, and boost sales. Whether you’re a startup, small business, or large enterprise,  ......",
    link: "/Best-Digital-Marketing-Company-in-Bhopal",
    image: require("../../components/images/blog29img.jpg"),
  },
  {
    title: "Best SEO Company in Bhopal – DOAGuru InfoSystems",
    description:
      "In today’s digital world, every business needs a strong online presence to succeed. If you are searching for the best SEO company in Bhopal, look no further than DOAGuru InfoSystems. We offer top-notch SEO services that help  ......",
    link: "/Best-SEO-Company-in-Bhopal",
    image: require("../../components/images/blog30img.jpg"),
  },
  {
    title: "Best SEO Company in Indore – Get Found Online & Grow Your Business",
    description:
      "In today’s competitive digital landscape, businesses in Bhopal are increasingly relying on digital marketing to expand their reach, attract customers, and boost sales. Whether you’re a startup,......",
    link: "/Best-SEO-Company-in-Indore",
    image: require("../../components/images/blog31img.jpg"),
    date: "Mar 15, 2025",
  },
  {
    title: "Best Website Development Company in Indore",
    description:
      "In today's digital world, having a strong online presence is essential for any business. A well-designed website not only attracts customers but also helps in building brand credibility. If you are looking for......",
    link: "/Best-Website-Development-Company-in-Indore",
    image: require("../../components/images/blog32img.jpg"),
    date: "Mar 25, 2025",
  },
  {
    title: "Best Website Development Company in Bhopal ",
    description:
      "In today’s digital era, having a robust online presence is no longer optional; it’s a necessity. If you’re searching for the best website development company in Bhopal, look no further than DOAGuru InfoSystems......",
    link: "/Best-Website-Development-Company-in-Bhopal",
    image: require("../../components/images/blog33img.jpg"),
    date: "Mar 25, 2025",
  },
  {
    title: "Top 10 Best SEO Services Companies in India ",
    description:
      "Search Engine Optimization (SEO) is crucial for businesses aiming to improve their online presence, rank higher on search engines, and attract organic traffic. In India, numerous SEO companies provide cutting-edge ......",
    link: "/Best-SEO-Services-Companies-in-India",
    image: require("../../components/images/blog34img.jpg"),
    date: "Mar 27, 2025",
  },
  {
    title: "Best SEO company in India ",
    description:
      "In today’s competitive digital world, ranking higher on search engines is essential for business success. DOAGuru InfoSystems is a leading SEO company in India, helping businesses increase their organic traffic and dominate search results. With our ......",
    link: "/seo-company-in-India",
    image: require("../../components/images/blog35img.jpg"),
    date: "Mar 28, 2025",
  },



].reverse();

export default blogsData;
