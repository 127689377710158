

// const Options = (props) => {
//   const options = [
//     // eslint-disable-next-line react/prop-types
//     { text: "Portfolio", handler: props.actionProvider.handleProjectDetails, id: 1 },
//     // eslint-disable-next-line react/prop-types
//     { text: "Contact Us", handler: props.actionProvider.handleContactUs, id: 2 },
//     // eslint-disable-next-line react/prop-types
//     { text: "About us", handler: props.actionProvider.handleServices, id: 3 },
   
//   ];

//   const optionsMarkup = options.map((option) => (
//     <button key={option.id} onClick={option.handler} className="option-button py-0.5 px-1 bg-transparent text-black border border-gray-900 hover:bg-slate-900 hover:text-white rounded-md  m-3" >
//       {option.text}
//     </button>
//   ));

//   return <div className="options-container"
//   style={{

//   }}
//   >{optionsMarkup}</div>;

// };

// export default Options;

import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

// Button Animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components for Better UI
const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  gap: 10px;
`;

const OptionButton = styled(Link)`
  background: #002c63;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  // font-weight: lighter;
  font-size: 12px;
  transition: all 0.2s ease;
  animation: ${fadeIn} 0.1s ease-in-out;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  &:hover {
    background: #0056b3;
    transform: scale(1.05);
  }
`;

const Options = () => {
  const [currentOptions, setCurrentOptions] = useState([
    { text: "Services", handler: () => showNextOptions("services"), id: 1 },
    { text: "Contact Us", handler: () => showNextOptions("contact"), id: 2 },
    { text: "About Us", handler: () => showNextOptions("about"), id: 3 },
  ]);

  // Function to Show Next Options Dynamically
  const showNextOptions = (type) => {
    if (type === "services") {
      setCurrentOptions([
        { text: "SEO Service", link: "/Best-SEO-Company-in-Jabalpur", id: 4 },
        { text: "SMM Service", link: "/Best-social-media-marketing-company-in-Jabalpur-India", id: 5 },
        { text: "PPC Google Adwords", link: "/ppc", id: 6 },
        { text: "Digital Marketing", link: "/Best-Digital-Marketing-Company-Jabalpur", id: 7 },
        { text: "Software & Website Development", link: "/Top-Software-Company-in-Jabalpur", id: 8 },
        { text: "Mobile App Development", link: "/mobile-development", id: 9 },
        { text: "Graphic Designing", link: "/graphic-designing", id: 10 },
        { text: "PR Service", link: "/pr-service", id: 11 },
        { text: "<-Back", handler: () => resetOptions(), id: 12 },
      ]);
    } else if (type === "contact") {
      setCurrentOptions([
        { text: "Email : doaguruinfosystems@gmail.com", link: "/contact_us", id: 13 },
        { text: "Phone : +91 74409 92424", link: "/contact_us", id: 14 },
        { text: "<-Back", handler: () => resetOptions(), id: 15 },
      ]);
    } else if (type === "about") {
      setCurrentOptions([
        { text: "Company Info", link: "/about_us", id: 16 },
        { text: "Case Studies", link: "/case-studies", id: 17 },
        { text: "<-Back", handler: () => resetOptions(), id: 18 },
      ]);
    }
  };

  // Reset to Default Options
  const resetOptions = () => {
    setCurrentOptions([
      { text: "Services", handler: () => showNextOptions("services"), id: 1 },
      { text: "Contact Us", handler: () => showNextOptions("contact"), id: 2 },
      { text: "About Us", handler: () => showNextOptions("about"), id: 3 },
    ]);
  };

  return (
    <OptionsContainer>
      {currentOptions.map((option) =>
        option.link ? (
          <OptionButton key={option.id} to={option.link}>
            {option.text}
          </OptionButton>
        ) : (
          <OptionButton as="button" key={option.id} onClick={option.handler}>
            {option.text}
          </OptionButton>
        )
      )}
    </OptionsContainer>
  );
};

export default Options;
