import React, { useState } from "react";
import { AiFillEnvironment, AiFillMail, AiFillPhone } from "react-icons/ai";
import { styled } from "styled-components";
import Finalnav from "./Homepages/Finalnav";
import contactbg from "../components/images/contactbg.webp";
import contacthero from "../components/images/contacthero.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import cogoToast from "cogo-toast";
import Footer from "./Homepages/Footer";

function Contact() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const [values, setValues] = useState({
    name: "",
    phone_no: "",
    email_id: "",
    message: "",
  });

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("https://doaguru.com/api/auth/userdata", values)
      .then((res) => {
        // Clear the input fields
        setValues({
          name: "",
          phone_no: "",
          email_id: "",
          message: "",
        });
        cogoToast.success("Message sent successsfully");
      })
      .catch((err) => cogoToast.error("Something went wrong"));
  };

  return (
    <>
      <Finalnav />
      <Helmet>
        <title>Contacts Us | In Jabalpur | DOAGuru InfoSystems</title>
        <meta
          name="description"
          content="We DOAGuru InfoSystems design, develop, measure & improve next-generation solutions across a variety of industry verticals. Contacts Us +91 7440992424"
        />
        <link rel="canonical" href="https://doaguru.com/contact_us" />
      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div
          className="container-fluid"
          id="cont2"
          style={{
            backgroundImage: `url(${contactbg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row">
            <div class="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <div className="headingpr">
                <h1 className="hdd text-white">Contact DOAGuru InfoSystems</h1>
                <p className="pdd text-white mt-4">
                  We’re always ready to listen to the needs of both new and
                  existing clients and work hard to provide you with a solution
                  that surpasses your expectations.
                </p>
              </div>
            </div>
            <div class="col-sm-6">
              <img
                src={contacthero}
                class="img3 d-block"
                alt="..."
                id="contactfull"
              />
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="row mt-4">
            <div className="col-sm-6" id="info12">
              <div data-aos="fade-right">
                <h2 className="jfn text-primary mt-2">Contact Info</h2>
                <div className="ct ">
                  <div className="loc d-flex">
                    <div>
                      <AiFillEnvironment size="35px" color="#ea4335" />
                    </div>
                    <div>
                      <span className="m-1">
                        1815 Wright Town,
                        <br /> Jabalpur, Madhya pradesh INDIA <br />
                        482002
                      </span>
                    </div>
                  </div>

                  <br />
                  <AiFillPhone size="35px" color="#7f8ff9" />
                  <span className="">+91-7440992424</span>
                  <span className="mx-2">+91-8827311424</span>
                  <br />
                  <br />
                  <AiFillMail size="35px" color="#d44638" />
                  <span className="m-2">info@doaguru.com</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6" id="formcont12">
              <div data-aos="fade-left">
                <div className="hd12 text-center text-primary">
                  <h2>Contact Us</h2>
                </div>
                <div className="container" id="contform">
                  <form
                    onSubmit={handleSubmit}
                    className="mb-1 rounded-1 p-4 border bg-primary"
                  >
                    <label className="d-block mb-4 text-white">
                      <span className="form-label d-block">Your name</span>
                      <input
                        required
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </label>

                    <label className="d-block mb-4 text-white">
                      <span className="form-label d-block">Phone Number</span>
                      <input
                        required
                        name="phone_no"
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Phone Number"
                        value={values.phone_no}
                        maxLength={10}
                        onChange={handleChange}
                      />
                    </label>

                    <label className="d-block mb-4 text-white">
                      <span className="form-label d-block">Email address</span>
                      <input
                        required
                        name="email_id"
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Email Id"
                        value={values.email_id}
                        onChange={handleChange}
                      />
                    </label>

                    <label className="d-block mb-4">
                      <span className="form-label d-block text-white">
                        Message
                      </span>
                      <textarea
                        name="message"
                        className="form-control"
                        rows="3"
                        placeholder=""
                        value={values.message}
                        onChange={handleChange}
                      ></textarea>
                    </label>

                    <div className="mb-3 text-white">
                      <button type="submit" className="btn  px-3 rounded-3 ">
                        Send message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.0336101698513!2d79.91489297482104!3d23.168973379071414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3981b116195eee4f%3A0x62196cfaef1c23c7!2sDOAGURU%20INFO%20SYSTEMS-%20Best%20Digital%20Marketing%20Company%20in%20Jabalpur%7CBest%20Software%20company%20in%20jabalpur%7CIT%20Company%20in%20Jabalpur!5e0!3m2!1sen!2sin!4v1692714245685!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <Footer />
      </Wrapper>
    </>
  );
}

export default Contact;

const Wrapper = styled.div`
  iframe {
    border: 0;
    width: 100%;
    height: 25rem;
    margin-top: 1rem;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .ct {
    margin-top: 2rem;
  }

  #cont2 {
    height: 27rem;

    @media screen and (max-width: 768px) {
      height: 40rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 37rem;
    }
  }
  .headingpr {
    padding-left: 5rem;
    @media screen and (max-width: 768px) {
      margin-left: -4.5rem;
    }
  }
  #formcont12 {
    margin-left: -10rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: -1.6rem;
    }
  }

  #info12 {
    margin-left: 3rem;
    @media screen and (max-width: 768px) {
      margin-left: 1rem;
    }
  }
  #contactfull {
    margin-top: 3rem;
    margin-left: 4rem;
    @media screen and (max-width: 768px) {
      margin-left: 0.2rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 15rem;
      margin-top: 4rem;
      margin-left: 9.5rem;
      width: 123%;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 2rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }

  button {
    background-color: white;
  }
  button:hover {
    background-color: #4caf50;
    color: white;
  }
  #contform {
    margin-left: 2rem;
    @media screen and (max-width: 768px) {
      margin-left: 10rem;
    }
  }
  .hd12 {
    margin-left: -17rem;
    margin-top: 0.5rem;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      margin-left: 7rem;
      margin-top: 2rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: 10rem;
      margin-top: 2rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: -23rem;
    }
  }
  .jfn {
    color: #1e1666;
  }
  .hdd {
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;
