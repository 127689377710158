import React from "react";
import logodental from "../components/images/Quotation.png";
import img1 from "../components/images/Q1.jpg";
import img2 from "../components/images/productImage/4.png";
import img3 from "../components/images/Q2.jpg";
import img4 from "../components/images/Q3.jpg";
import img5 from "../components/images/productImage/5.png";
import img6 from "../components/images/productImage/6.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Finalnav from "./Homepages/Finalnav";
import { Helmet } from "react-helmet";
import { MdDoubleArrow } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export function QuotationGenerator() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Wrapper>
      <Finalnav />
      <Helmet>
        <title>
          Empower Your Dental Practice with Mini Dental Guru | Comprehensive Software for Dentists & Clinics
        </title>
        <meta
          name="description"
          content="Enhance efficiency and streamline operations in your dental practice with Mini Dental Guru. Our comprehensive software solution is designed to meet the unique needs of dental doctors and clinics, offering tools for appointment scheduling, patient management, billing, and more. Discover how Mini Dental Guru can revolutionize your practice today!
          "
        />
        <link rel="canonical" href="https://doaguru.com/dental-guru" />
      </Helmet>
      <div className="container-fluid product-page">
        <div className="logo-container ">

          <img src={logodental} alt="" className="w-25" />
          <div className="flex justify-content-end align-items-center">

            <h2 style={{ color: "red" }}>Quotation Generator Management System</h2>
            <h1>
              We Care About Your Business<div className="underline"></div>
            </h1>
          </div>
        </div>

        <div className="container product-box my-5 p-2">
          <div className="row">
            <div className="col-lg-6 col-md-6 m-auto " id="mobile">
              {/* <img id="clientpages6" src={heroimg} className="" /> */}
              <div className="image-slider p-2">
                <Slider {...sliderSettings}>
                  <div><img src={img1} alt="Slide 1" /></div>
                  {/* <div><img src={img2} alt="Slide 2" /></div> */}
                  <div><img src={img3} alt="Slide 3" /></div>
                  <div><img src={img4} alt="Slide 4" /></div>
                  {/* <div><img src={img5} alt="Slide 5" /></div> */}
                  {/* <div><img src={img6} alt="Slide 6" /></div> */}
                </Slider>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 m-auto">
              <ul className=" d-flex flex-column gap-3 h5 mt-3">
                <li><MdDoubleArrow color="blue" /> Simple Quotation Creation</li>
                <li><MdDoubleArrow color="blue" /> Client Management</li>
                <li><MdDoubleArrow color="blue" /> PDF Generation</li>
                <li><MdDoubleArrow color="blue" /> User-friendly Interface</li>
                <li><MdDoubleArrow color="blue" /> Secure and Accessible</li>
                {/* <li><MdDoubleArrow color="blue" /> Account Management</li> */}
                {/* <li><MdDoubleArrow color="blue" /> Inventory Management</li> */}
                {/* <li><MdDoubleArrow color="blue" /> Multi Branch Management</li> */}
              </ul>
            </div>
          </div>
          <div className="btnDiv ">

            <Link
              to="/contact_us"
              onClick={handleFooterLink}
              className="btn mt-4 mx-5 rounded-3 buttonBook "
              style={{ backgroundColor: "transperent", border: "solid blue " }}
              id="btn6"
            >
              Book Now
            </Link>

            <h2 className="mt-4 font-weight-bold" style={{ color: "#002c63" }}>
            Experience the Power of Our Quotation Generator – Free Trial Demo
            </h2>
          </div>
        </div>
        <div className="product-box p-0 px-2">
          <div class="cta-section text-center my-5">
            <h2 style={{ color: "#00C7C7" }}>Discover More About Us</h2>
            <p>
            Not sure if the Quotation Generator Management System is the right fit for your business? No problem! 
      Try it out with a <strong>Free Trial Demo</strong> and see how it can revolutionize the way you create and manage quotations. 
      Get hands-on experience with all the features, and see how this tool can streamline your workflow.
            </p>
            <a href="/contact_us" target="_blank" rel="noopener noreferrer" class="btn btn-primary">
              Contact Us
            </a>
          </div>
        </div>
        <div className="product-box">
          <section id="quotation-system">
            <div class="container">
              <h2>Quotation Generator Management System</h2>
              <p><strong>Efficient, Simple, and Comprehensive Quotation Creation Tool</strong></p>
              <p>
                Our <strong>Quotation Generator Management System</strong> is a powerful web-based tool designed for businesses, freelancers, and sales teams who need to create and manage quotations quickly and efficiently. This system streamlines the entire quotation process, saving you time and helping you stay organized.
              </p>

              <h3>Key Features:</h3>
              <ul>
                <li><strong>Simple Quotation Creation:</strong> Easily create professional quotations by entering product/service details, client information, and pricing. Customizable fields to fit your unique business requirements. Automatic calculation of totals, taxes, and discounts.</li>
                <li><strong>Client Management:</strong> Manage and store all client information in one place. Track quotation history for each client, making it easy to follow up and manage recurring clients.</li>
                <li><strong>Quotation Tracking:</strong> Keep track of the status of each quotation (draft, sent, accepted, rejected). Notifications and reminders for pending quotations and follow-ups.</li>
                <li><strong>PDF Generation & Email Integration:</strong> Generate a clean and professional PDF version of the quotation instantly. Send quotations directly to your clients via email without leaving the system. Download and share PDFs with just a click.</li>
                <li><strong>Reporting and Analytics:</strong> Detailed reports to help you analyze your quotation-to-sale conversion rates. Visual dashboard showing the number of quotations created, sent, and accepted.</li>
                <li><strong>User-friendly Interface:</strong> Easy-to-use, intuitive interface that requires minimal training. Designed for non-technical users, so you can start generating quotations immediately.</li>
                <li><strong>Secure and Accessible:</strong> Secure cloud-based storage for all quotations and client data. Accessible from any device, ensuring you can create and send quotations on the go.</li>
              </ul>

              <h3>Limitations:</h3>
              <p>
                The system provides a standard layout for all quotations. Template customization is not available.
              </p>

              <h3>Why Choose Our Quotation Generator?</h3>
              <ul>
                <li><strong>Time-Saving:</strong> Our system automates the quotation creation process, enabling you to generate professional quotes in minutes.</li>
                <li><strong>Accuracy:</strong> Avoid manual errors with automated calculations and consistent formatting.</li>
                <li><strong>Client Retention:</strong> Keep all your client details in one place and track your interactions for better client management.</li>
                <li><strong>Professional Output:</strong> Each quotation is formatted to look professional, increasing your chances of closing the deal.</li>
              </ul>

              <p>
                Take your business to the next level with our <strong>Quotation Generator Management System</strong> and streamline your sales process today!
              </p>
            </div>
          </section>

          <div className="btnDiv ">

            <Link
              to="/contact_us"
              onClick={handleFooterLink}
              id="btn6"
            ><button className="btn mt-4 mx-5 rounded-3 buttonBook " style={{ backgroundColor: "transperent", border: "solid blue " }}>
                Book Now
              </button></Link>

            <h2 className="mt-4 font-weight-bold" style={{ color: "#002c63" }}>
            Experience the Power of Our Quotation Generator – Free Trial Demo
            </h2>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}


const Wrapper = styled.div`

.product-page{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

ul {
list-style: none;
}
  .underline {
    height: 4px;
    width: 38rem;
    background-color: #4cd3d9;
    margin-top: 2;
    margin-bottom: 10;
    @media screen and (max-width: 768px) {
      width: 22rem;
    }
  }

  .logo-container {
      display: flex;
    justify-content: center;
    align-items: center;
  }
  .btnDiv{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .image1{
  display: flex;
  justify-content: center;
  width: 25rem;
  }
  .product-box{
    border: solid;
    border-radius: 2.5rem;
    border-color: cadetblue;
    margin: 1rem;
    padding: 3rem;
  }
.buttonBook:hover {
  background-color: blue;
  color: white;
  font-weight: bold;
}
    .image-slider img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
  }


`;
