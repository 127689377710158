import React from "react";
import {
  Modal,
  Button,
  Container as BootstrapContainer,
} from "react-bootstrap";
// import ContactForm from "../ContactForm";
import ContactForm from "./ContactForm";
import styled from "styled-components";

const ModalComponent = ({ show, handleClose }) => {
  const handleFormSubmit = (e) => {
    // Handle form submission here
    handleClose(); // Close the modal after form submission
  };

  return (
    <Container>


      
      <BootstrapContainer className="me-5 MyModal">
        <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
          <CloseButton
            variant="close"
            onClick={handleClose}
            aria-label="Close"
          />
          <Content>
            <ContactForm
              // sectionTitleUp="WAITING FOR PREGNANCY ?"
              // sectionTitle="Contact us"
              onSubmit={handleFormSubmit} // Pass the handleFormSubmit function
            />
          </Content>
        </Modal>
      </BootstrapContainer>
  
    </Container>
  );
};

export default ModalComponent;

const Container = styled.div`

  .custom-modal .modal-content {
    background: rgba(255, 255, 255, 0.1) !important; /* Transparent white */
    background: red;
    border-radius: 10px;
    backdrop-filter: blur(10px); /* Frosted glass effect */
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow for the glass look */
    border: 1px solid rgba(255, 255, 255, 0.18); /* Soft border */
  }
  .custom-modal .modal-dialog {
    max-width: 100%;
    margin: auto;
    @media (min-width: 576px) {
      max-width: 540px; /* Mobile devices */
    }
    @media (min-width: 768px) {
      max-width: 720px; /* Tablets */
    }
    @media (min-width: 992px) {
      max-width: 960px; /* Laptops */
    }
    @media (min-width: 1200px) {
      max-width: 1140px; /* Large screens */
    }
  }

`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Content = styled.div`
  padding: 20px;
  text-align: center;
  color: white;
      display: flex;
    justify-content: center;
`;
