import React from "react";
import styled from "styled-components";

// import img
import img1 from "../../components/img/smo&smm/1.jpg";
import img2 from "../../components/img/smo&smm/2.jpg";
import img3 from "../../components/img/smo&smm/3.jpg";
import img4 from "../../components/img/smo&smm/4.jpg";
import img5 from "../../components/img/smo&smm/5.jpg";
import img6 from "../../components/img/smo&smm/6.jpg";
import Finalnav from "../Homepages/Finalnav";
import { Link } from "react-router-dom";
import Footer from "../Homepages/Footer";

const Smosmm = () => {
  return (
    <>
    <Finalnav/>
      <SmoSmm>
        <div className="container-fluid">
          <div className="row d-flex gap-5">
            <div className="col-12  d-flex justify-content-center">
              {/* doaGurcard start */}
              <div className="cardDoa mt-5">
                <div className="row p-5 h-100 d-flex align-items-center gap-3 gap-md-0">
                  <div className="col-md-8">
                    <div className="row d-flex gap-4">
                      <div className="col-12">
                        <h2>SMO & SMM Portfolio</h2>
                      </div>
                      <div className="col-12">
                        <p>
                          Boost your brand with DOAGuru InfoSystems, where happy
                          clients are our success story. Experience digital
                          growth like never before
                        </p>
                      </div>
                      <div className="col-12">
                      <Link to="/contact_us" className="doaBtn border-0 text-white p-2 rounded-3">
                          Connect Us
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 d-flex justify-content-center">
                    <img
                      className="img-fluid rounded-1"
                      style={{ width: "210px" }}
                      src="https://www.obiyaninfotech.com/wp-content/uploads/2022/11/SMO-and-SMM.jpg"
                    />
                  </div>
                </div>
              </div>
              {/* doaGurcard end */}
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div
                className="bg-dark rounded-2"
                style={{
                  height: "7px",
                  width: "90px",
                }}
              ></div>
            </div>

            {/* main work  */}
            <div className="col-12 d-flex justify-content-center flex-wrap">
              <div className="GraphicCom d-flex flex-wrap gap-5 gap-lg-5  justify-content-evenly">
                <div className="img">
                  <img className="img-fluid" src={img1} />
                </div>

                <div className="img">
                  <img className="img-fluid" src={img2} />
                </div>

                <div className="img">
                  <img className="img-fluid" src={img3} />
                </div>

                <div className="img">
                  <img className="img-fluid" src={img4} />
                </div>

                <div className="img">
                  <img className="img-fluid" src={img5} />
                </div>

                <div className="img">
                  <img className="img-fluid" src={img6} />
                </div>
              </div>
            </div>
            {/* main work  */}

            <div className="col-12 d-flex justify-content-center align-items-center">
              <div
                className="bg-dark rounded-2"
                style={{
                  height: "7px",
                  width: "90px",
                }}
              ></div>
            </div>

            {/* Testimonials */}

            {/* <div className="col-12 d-flex justify-content-center align-items-center">
              <h1>Testimonials</h1>
            </div>

            <div className="col-12 d-flex justify-content-center flex-wrap">
              <div className="testimonialCom d-flex flex-wrap gap-5 gap-lg-5  justify-content-evenly">

                <div className="Testimonials d-flex flex-column">
                  <div class="col-12 mb-5 d-flex">
                    <div class="testimonial-card d-flex flex-column gap-2">
                      <div
                        class="card-up"
                        style={{ backgroundColor: "#9d789b" }}
                      ></div>
                      <div class="w-100 d-flex justify-content-center">
                        <img
                          src="https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944"
                          className="userImg_"
                          alt=""
                        />
                      </div>
                      <div class="p-0 d-flex flex-column gap-3 align-items-center overflow-auto">
                        <h4>Maria Smantha</h4>
                        <p class="dark-grey-text text-center">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Iure est error assumenda facilis facere, nam ad
                          iusto perspiciatis consectetur Lorem ipsum dolor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Testimonials d-flex flex-column">
                  <div class="col-12 mb-5 d-flex">
                    <div class="testimonial-card d-flex flex-column gap-2">
                      <div
                        class="card-up"
                        style={{ backgroundColor: "#9d789b" }}
                      ></div>
                      <div class="w-100 d-flex justify-content-center">
                        <img
                          src="https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944"
                          className="userImg_"
                          alt=""
                        />
                      </div>
                      <div class="p-0 d-flex flex-column gap-3 align-items-center overflow-auto">
                        <h4>Maria Smantha</h4>
                        <p class="dark-grey-text text-center">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Iure est error assumenda facilis facere, nam ad
                          iusto perspiciatis consectetur Lorem ipsum dolor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Testimonials d-flex flex-column">
                  <div class="col-12 mb-5 d-flex">
                    <div class="testimonial-card d-flex flex-column gap-2">
                      <div
                        class="card-up"
                        style={{ backgroundColor: "#9d789b" }}
                      ></div>
                      <div class="w-100 d-flex justify-content-center">
                        <img
                          src="https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944"
                          className="userImg_"
                          alt=""
                        />
                      </div>
                      <div class="p-0 d-flex flex-column gap-3 align-items-center overflow-auto">
                        <h4>Maria Smantha</h4>
                        <p class="dark-grey-text text-center">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Iure est error assumenda facilis facere, nam ad
                          iusto perspiciatis consectetur Lorem ipsum dolor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Testimonials d-flex flex-column">
                  <div class="col-12 mb-5 d-flex">
                    <div class="testimonial-card d-flex flex-column gap-2">
                      <div
                        class="card-up"
                        style={{ backgroundColor: "#9d789b" }}
                      ></div>
                      <div class="w-100 d-flex justify-content-center">
                        <img
                          src="https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944"
                          className="userImg_"
                          alt=""
                        />
                      </div>
                      <div class="p-0 d-flex flex-column gap-3 align-items-center overflow-auto">
                        <h4>Maria Smantha</h4>
                        <p class="dark-grey-text text-center">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Iure est error assumenda facilis facere, nam ad
                          iusto perspiciatis consectetur Lorem ipsum dolor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Testimonials d-flex flex-column">
                  <div class="col-12 mb-5 d-flex">
                    <div class="testimonial-card d-flex flex-column gap-2">
                      <div
                        class="card-up"
                        style={{ backgroundColor: "#9d789b" }}
                      ></div>
                      <div class="w-100 d-flex justify-content-center">
                        <img
                          src="https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944"
                          className="userImg_"
                          alt=""
                        />
                      </div>
                      <div class="p-0 d-flex flex-column gap-3 align-items-center overflow-auto">
                        <h4>Maria Smantha</h4>
                        <p class="dark-grey-text text-center">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Iure est error assumenda facilis facere, nam ad
                          iusto perspiciatis consectetur Lorem ipsum dolor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Testimonials d-flex flex-column">
                  <div class="col-12 mb-5 d-flex">
                    <div class="testimonial-card d-flex flex-column gap-2">
                      <div
                        class="card-up"
                        style={{ backgroundColor: "#9d789b" }}
                      ></div>
                      <div class="w-100 d-flex justify-content-center">
                        <img
                          src="https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944"
                          className="userImg_"
                          alt=""
                        />
                      </div>
                      <div class="p-0 d-flex flex-column gap-3 align-items-center overflow-auto">
                        <h4>Maria Smantha</h4>
                        <p class="dark-grey-text text-center">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Iure est error assumenda facilis facere, nam ad
                          iusto perspiciatis consectetur Lorem ipsum dolor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Testimonials d-flex flex-column">
                  <div class="col-12 mb-5 d-flex">
                    <div class="testimonial-card d-flex flex-column gap-2">
                      <div
                        class="card-up"
                        style={{ backgroundColor: "#9d789b" }}
                      ></div>
                      <div class="w-100 d-flex justify-content-center">
                        <img
                          src="https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944"
                          className="userImg_"
                          alt=""
                        />
                      </div>
                      <div class="p-0 d-flex flex-column gap-3 align-items-center overflow-auto">
                        <h4>Maria Smantha</h4>
                        <p class="dark-grey-text text-center">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Iure est error assumenda facilis facere, nam ad
                          iusto perspiciatis consectetur Lorem ipsum dolor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div> */}
            {/* Testimonials */}
          </div>
        </div>
      </SmoSmm>
      <Footer />
    </>
  );
};

export default Smosmm;

const SmoSmm = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 29px;
  background-color: #e2eafc;

  //  btn
  button,
  a {
    background-color: rgb(0, 43, 98);
    cursor: pointer;
    position: relative;
    color: white;
    text-decoration: none;
    transition: all 0.4s ease;
  }
  button:hover,
  a:hover {
    transform: scale(0.9);
    background-color: green;
    color: white;
  }

  //cardDoa
  .cardDoa {
    width: 900px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.253);
    transition: all 0.4s;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .cardDoa:hover {
    transform: scale(0.9);
    background-color: white;
    color: black;
  }

  .img {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s;
    box-shadow: 37px 38px 26px -24px rgba(0, 0, 0, 0.28);
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.245);
  }
  .img:hover {
    box-shadow: none;
    transform: scale(1.2);
    border: 1px solid rgba(0, 0, 0, 0.245);
  }

  .GraphicCom {
    width: 1000px;
  }

  .testimonialCom {
    width: 1100px;
    height: 690px;
    overflow-y: auto;
  }

  .Testimonials {
    width: 300px;
    height: 300px;
    border: 1px solid rgba(0, 0, 0, 0.295);
    background-color: black;
    color: white;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.4s;
  }
  .Testimonials:hover {
    transform: scale(0.9);
  }

  .userImg_ {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
`;
