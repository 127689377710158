import React from "react";
import builder1 from "../../components/images/balaweb.webp";
import janyoti from "../../components/images/janyoti.webp";
import devji from "../../components/images/devjiweb.webp";
import ecomerce from "../../components/images/emcomerce.webp";
import school from "../../components/images/school.webp";
import spark from "../../components/images/sparkweb.webp";
import backimg from "../../components/images/2.png";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Cards = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <>
      <Wrapper>
        {/* <div className="container">
          <div className="row">
            <div className="col-12 mb-2">
              <h2 id="our">Our Portfolio</h2>
              <div
                className="underline mx-auto"
                style={{
                  height: 3,
                  width: "10rem",
                  backgroundColor: "#86ff68",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center p-0 w-100" >
              <div className="row g-3  w-100 d-flex justify-content-center align-items-center" >
                <div
                  className=" col-xl-4 gap-5 col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center "
                  id="bulid"
                >
                  <Link
                    to="https://balaconstruction.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card rounded-3 bg-transparent border-1" id="cardshadow1">
                      <div data-aos="flip-left">
                        <img
                          src={builder1}
                          className="card-img-top "
                          alt="..."
                          id="photo"
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">Real Estate & Builder</h5>
                          <p className="card-text">
                            We specialize in creating dynamic and user-centric
                            real estate websites tailored specifically for
                            builders.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-xl-4 gap-xl-5 col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center"
                  id="jan"
                >
                  <Link
                    to="https://janjyotieyehospital.com/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card rounded-3 " id="cardshadow2">
                      <div data-aos="flip-left">
                        <img
                          src={janyoti}
                          className="card-img-top"
                          alt="..."
                          id="photo"
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">
                            Janjyoti Eyecare Hospital
                          </h5>
                          <p className="card-text">
                            We designed the Janjyoti Eyecare Hospital website,
                            where modern technology and crystal-clear vision
                            come together.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div
                  className="col-xl-4 gap-xl-5 col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center"
                  id="jan"
                >
                  <Link
                    to="https://dvjei.org/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card rounded-3 " id="cardshadow3">
                      <div data-aos="flip-left">
                        <img
                          src={devji}
                          className="card-img-top"
                          alt="..."
                          id="photo"
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">Dvjei Eyecare Hospital</h5>
                          <p className="card-text">
                            The website for the Devji Eyecare Hospital was made
                            by us. It serves as a model of excellence for eye
                            health.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-4 gap-xl-5 col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center">
                  <Link
                    to="https://fornearservices.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card rounded-3 " id="cardshadow1">
                      <div data-aos="flip-left">
                        <img
                          src={ecomerce}
                          className="card-img-top "
                          alt="..."
                          id="photo"
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">Fornear Services</h5>
                          <p className="card-text">
                            We have designed this e-commerce website to make
                            buying groceries a breeze for you. With a simple and
                            user-friendly interface.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-xl-4 gap-xl-5 col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center"
                  id="jan"
                >
                  <Link
                    to="https://ciosgovt.co.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card rounded  " id="cardshadow5">
                      <div data-aos="flip-left">
                        <img
                          src={school}
                          className="card-img-top"
                          alt="..."
                          id="photo"
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">School Website</h5>
                          <p className="card-text">
                            Our school websites prioritize accessibility,
                            delivering vital information and updates to foster a
                            collaborative and informed community.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div
                  className="col-xl-4 gap-xl-5 col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center"
                  id="jan"
                >
                  <Link
                    to="https://sparkbuild.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card rounded-3" id="cardshadow6">
                      <div data-aos="flip-left">
                        <img
                          src={spark}
                          className="card-img-top"
                          alt="..."
                          id="photo"
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">
                            Spark Build Contruction
                          </h5>
                          <p className="card-text">
                            This is a website for doing business. We are
                            dedicated to delivering high-quality construction
                            services that exceed our clients’ expectations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            <div className="col-12 mb-2">
              <h2 id="our">Our Portfolio</h2>
              <div
                className="underline mx-auto"
                style={{
                  height: 3,
                  width: "10rem",
                  backgroundColor: "#86ff68",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></div>
            </div>
          </div>

          <div className="row d-flex justify-content-center p-0 w-100">
            <div className="row g-3 w-100 d-flex justify-content-center align-items-center">
              {[
                {
                  link: "https://balaconstruction.in/",
                  src: builder1,
                  title: "Real Estate & Builder",
                  text: "We specialize in creating dynamic and user-centric real estate websites tailored specifically for builders.",
                  id: "cardshadow1",
                },
                {
                  link: "https://janjyotieyehospital.com/",
                  src: janyoti,
                  title: "Jan Jyoti Eyecare Hospital",
                  text: "We designed the Janjyoti Eyecare Hospital website, where modern technology and crystal-clear vision come together.",
                  id: "cardshadow2",
                },
                {
                  link: "https://dvjei.org/",
                  src: devji,
                  title: "Dvjei Eyecare Hospital",
                  text: "The website for the Devji Eyecare Hospital was made by us. It serves as a model of excellence for eye health.",
                  id: "cardshadow3",
                },
                {
                  // link: "https://fornearservices.in/",
                  src: ecomerce,
                  title: "Fornear Services",
                  text: "We have designed this e-commerce website to make buying groceries a breeze for you. With a simple and user-friendly interface.",
                  id: "cardshadow1",
                },
                {
                  // link: "https://ciosgovt.co.in/",
                  src: school,
                  title: "We Make School ",
                  text: "Our school websites prioritize accessibility, delivering vital information and updates to foster a collaborative and informed community.",
                  id: "cardshadow5",
                },
                {
                  link: "https://sparkbuild.in/",
                  src: spark,
                  title: "Spark Build Construction",
                  text: "This is a website for doing business. We are dedicated to delivering high-quality construction services that exceed our clients’ expectations.",
                  id: "cardshadow6",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="col-xl-4 gap-xl-5 col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center"
                  id="jan"
                >
                  <Link
                    to={item.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card " id={item.id} style={{
                      overflow: 'hidden',
                      background: 'rgb(0 0 0 / 0%)',
                      borderRadius: '16px',
                      boxShadow: 'rgb(59 68 100 / 72%) 0px 4px 30px',
                      backdropFilter: 'blur(4.9px)',
                      WebkitBackdropFilter: 'blur(4.9px)',
                      border: '1px solid rgba(206, 206, 234, 0.07)',
                    }}>
                      <div data-aos="flip-left">
                        <img
                          src={item.src}
                          className="card-img-top"
                          alt={item.title}
                          id="photo"
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">{item.title}</h5>
                          <p className="card-text">{item.text}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Cards;
const Wrapper = styled.div`
  background-image: url(${backimg});
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  /* background: #002c63; */
  .card {
    box-shadow: 0px 10px 25px 3px rgba(0, 0, 0, 0.1);
    border: none;
  }
  .card:hover {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  }

  #cardshadow1 {
    border-radius: 2rem;
    height: 25rem;
    width: 20rem;
    /* margin-left: 3rem; */
    @media screen and (max-width: 768px) {
      margin-left: 1.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      /* margin-left: 0rem;
      height: 30rem;
      width: 14rem; */
    }
  }
  #cardshadow2 {
    border-radius: 2rem;
    height: 25rem;
    width: 20rem;
    /* margin-left: 4rem; */
    @media screen and (max-width: 768px) {
      margin-left: 1.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
    }
  }
  #cardshadow3 {
    border-radius: 2rem;
    height: 25rem;
    width: 20rem;
    /* margin-left: 4rem; */
    @media screen and (max-width: 768px) {
      margin-left: 1.5rem;
    }
  }
  #cardshadow5 {
    border-radius: 2rem;
    height: 25rem;
    width: 20rem;
    /* margin-left: 4rem; */
    @media screen and (max-width: 768px) {
      margin-left: 1.5rem;
    }
  }
  #cardshadow6 {
    border-radius: 2rem;
    height: 25rem;
    width: 20rem;
    @media screen and (max-width: 768px) {
      margin-left: 1.5rem;
    }
  }
  #photo {
    width: 19.8rem;
    height: 9rem;
    margin-left: 1px;
    margin-top: 0.2rem;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 1px 2px whitesmoke;
  }
  .card-title {
    margin-top: 1rem;
  }
  .card-text {
    margin-top: 1rem;
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #ffff;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #86ff68; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  #our {
    color: white;
    text-align: center;
    @media screen and (max-width: 768px) {
      margin-top: 3rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 3rem;
    }
  }
  #large {
    margin-left: 2rem;
    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: -1.5rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 5rem;
    }
  }
`;
