import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogImg from "../images/blog32img.jpg";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

export const Blog32Page = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
          Best Website Development Company in Indore
        </title>
        <meta
          name="description"
          content="Looking for the best website development company in Indore? Get customized, SEO-friendly, and mobile-responsive websites from top web developers. Contact us today.
 "
        />
        <link rel="canonical" href="https://doaguru.com/Best-Website-Development-Company-in-Indore" />


      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              Best Website Development Company in Indore

            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={BlogImg} alt="" className="frontimg rounded m-4" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                    In today's digital world, having a strong online presence is essential for any business. A well-designed website not only attracts customers but also helps in building brand credibility. If you are looking for the <Link to="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2">best website development company in Indore</Link>, then you need a team that can create a responsive, fast, and user-friendly website tailored to your needs.
                  </p>
                  <br />
                </div>
              </div>


              {isShowMore && (
                <div>
                  <div className="col-lg-12 mt-3">
                    <div data-aos="fade-left">
                      <h5>Why Choose a Professional Website Development Company?</h5>
                      <ul>
                        <li><strong>Expertise and Experience:</strong> A professional website development company in Indore brings years of experience and technical expertise. They understand the latest trends in web development and create a website that aligns with your business goals.</li>
                        <li><strong>Custom Solutions:</strong> Every business is unique, and so are its website needs. A reputed web development company in Indore provides customized solutions, ensuring that your website stands out from the competition.</li>
                        <li><strong>Mobile-Friendly Designs:</strong> With most users browsing the internet on mobile devices, having a mobile-friendly website is essential. A good website development company ensures that your website is responsive and works seamlessly on all devices.</li>
                        <li><strong>SEO-Optimized Websites:</strong> A website that is not optimized for search engines will not rank well on Google. SEO-friendly web development ensures that your site appears in search results and attracts more organic traffic.</li>
                        <li><strong>Ongoing Support and Maintenance:</strong> Web development is not a one-time process. Regular updates, security checks, and maintenance are crucial for the smooth functioning of your website. A reliable web development company in Indore offers ongoing support to keep your site up to date.</li>
                      </ul>
                      <hr />
                      <h5>Services Offered by the Best Website Development Company in Indore</h5>
                      <ul>
                        <li><strong>Custom Website Development:</strong> Tailor-made websites to fit your business needs.</li>
                        <li><strong>E-commerce Website Development:</strong> Build a robust online store to sell your products and services.</li>
                        <li><strong>WordPress Development:</strong> Get a highly customizable and easy-to-manage website.</li>
                        <li><strong>Website Redesigning:</strong> Improve the look, feel, and performance of your existing website.</li>
                        <li><strong>Website Maintenance:</strong> Regular updates, bug fixes, and security enhancements.</li>
                        <li><strong>Web Hosting Services:</strong> Secure and reliable hosting solutions for your website.</li>
                      </ul>
                      <hr />
                      <h5>How to Choose the Right Website Development Company in Indore?</h5>
                      <ul>
                        <li><strong>Check Their Portfolio:</strong> Before hiring a web development company in Indore, check their previous projects. A good portfolio will give you an idea of their expertise and creativity.</li>
                        <li><strong>Read Client Reviews:</strong> Customer testimonials and reviews provide insights into the company's reliability and customer service.</li>
                        <li><strong>Discuss Your Requirements:</strong> Ensure that the company understands your vision and can deliver a website that meets your expectations.</li>
                        <li><strong>Compare Pricing:</strong> While affordability is important, do not compromise on quality. Choose a company that offers the best value for your investment.</li>
                        <li><strong>Ask About After-Sales Support:</strong> A professional website development company in Indore should offer after-sales support, including regular updates and maintenance.</li>
                      </ul>
                      <hr />
                      <h5>Conclusion</h5>
                      <p>Choosing the best website development company in Indore is crucial for your business success. With the right team, you can create a website that not only looks great but also performs well in search engines and attracts more customers. Whether you need a new website, an e-commerce platform, or a redesign of your existing site, a professional web development company can make all the difference.</p>
                      <p>If you're looking for expert web development services, visit Doaguru to get started today!</p>
                      <hr />
                      <h5>FAQs</h5>
                      <ul>
                        <li><strong>What makes a good website development company?</strong><br />A good website development company offers customized solutions, follows the latest industry trends, ensures SEO optimization, and provides ongoing support.</li>
                        <li><strong>How much does website development cost in Indore?</strong><br />The cost of website development depends on factors such as the type of website, features, and customization. It is best to get a quote from a website development company in Indore for accurate pricing.</li>
                        <li><strong>How long does it take to develop a website?</strong><br />The development time varies based on the complexity of the website. A simple website may take a few weeks, while a more complex site can take a few months.</li>
                        <li><strong>Can I update my website after it is developed?</strong><br />Yes, most websites come with a CMS (Content Management System) like WordPress, allowing you to update content easily. A good web development company in Indore will provide training and support for managing your site.</li>
                        <li><strong>Why should I choose Doaguru for website development?</strong><br />Doaguru is a leading website development company, offering customized, SEO-friendly, and mobile-responsive websites with excellent after-sales support.</li>
                      </ul>
                      <hr />
                      <h5>Contact DOAGuru InfoSystems Today!</h5>
                      <p>Boost your online presence with the best website development company in Indore and take your business to new heights with our expert web solutions! 🚀</p>
                      <ul>
                        <li>Visit our website: <Link href="https://doaguru.com/" target="_blank">DOAGuru Infosystems</Link></li>
                        <li>Fill out the contact form on our Contact Us page: <Link href="https://doaguru.com/contact_us" target="_blank">Contact Us</Link></li>
                      </ul>
                    </div>
                    <hr />
                  </div>
                </div>



              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}


const Wrapper = styled.div`
  button {
    background-color: #fe7604;
    
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li{
    margin-bottom: 0.5rem;
    
  }
  ul{
    list-style  : none;
    
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft{
    margin-left: 2rem;
  }
`;
