import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogImg from "../images/blog34img.jpg";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

export const Blog34Page = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
        Best SEO Services Company in India | Top 10 SEO Agencies
        </title>
        <meta
          name="description"
          content="Looking for the best SEO services company in India? Explore the top 10 SEO agencies, starting with DoaGuru, the leading SEO company. Get expert SEO solutions now."
        />
        <link rel="canonical" href="https://doaguru.com/Best-SEO-Services-Companies-in-India" />


      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
            DoaGuru – The Best SEO Company in India

            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={BlogImg} alt="" className="frontimg rounded m-4" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                  DoaGuru is India's leading <Link to="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2">SEO services company</Link>, offering tailored digital marketing solutions to businesses of all sizes. With a strong team of SEO experts and a data-driven approach, DoaGuru has successfully helped numerous clients achieve higher search engine rankings and improved online visibility.

                  </p>
                  <br />
                </div>
              </div>


              {isShowMore && (
                <div>
                <div className="col-lg-12 mt-3">
                  <div data-aos="fade-left">
                    <h5>Why Choose DoaGuru?</h5>
                    <ul>
                      <li><strong>Expert SEO Team:</strong> The company boasts a team of seasoned SEO professionals with years of industry experience, ensuring top-notch strategies and execution.</li>
                      <li><strong>Comprehensive SEO Services:</strong> DoaGuru offers on-page SEO, off-page SEO, technical SEO, and local SEO, ensuring a complete SEO solution.</li>
                      <li><strong>Customized Strategies:</strong> Every business is unique, and DoaGuru designs personalized SEO strategies tailored to specific industry needs.</li>
                      <li><strong>Ethical & White-Hat SEO Practices:</strong> The company follows ethical SEO practices, ensuring long-term and sustainable growth.</li>
                      <li><strong>Proven Track Record:</strong> DoaGuru has successfully delivered top-ranking results for businesses across industries, including eCommerce, healthcare, real estate, and more.</li>
                      <li><strong>Affordable & Transparent Pricing:</strong> With flexible SEO packages, DoaGuru ensures that businesses of all sizes can leverage professional SEO services.</li>
                    </ul>

                    <h5>Top SEO Companies in India</h5>
                    <ul>
                      <li><strong>PageTraffic:</strong> A leading SEO agency in India specializing in SEO, PPC, and digital marketing services.</li>
                      <li><strong>EZ Rankings:</strong> Provides complete SEO solutions, including local SEO, technical SEO, and keyword optimization.</li>
                      <li><strong>WebFX India:</strong> Offers AI-driven SEO strategies and personalized marketing campaigns.</li>
                      <li><strong>Techmagnate:</strong> Provides services in organic SEO, PPC advertising, and online reputation management.</li>
                      <li><strong>SEOValley:</strong> Known for eCommerce SEO, enterprise SEO, and international SEO with white-hat techniques.</li>
                      <li><strong>Bruce Clay India:</strong> Specializes in SEO audits, keyword research, and technical SEO enhancements.</li>
                      <li><strong>Digital Vidya:</strong> A digital marketing training provider offering specialized SEO consulting services.</li>
                      <li><strong>IndeedSEO:</strong> Affordable and effective SEO solutions for small and medium businesses.</li>
                      <li><strong>WeblinkIndia:</strong> Specializes in mobile SEO, enterprise SEO, and local SEO for competitive market growth.</li>
                    </ul>

                    <h5>Conclusion</h5>
                    <p>Choosing the right SEO company in India can be a game-changer for your business. Whether you are a startup or an established brand, investing in SEO helps improve your online presence, increase website traffic, and generate leads. DoaGuru is the leading SEO agency in India, providing tailored solutions, proven strategies, and expert guidance to help businesses succeed in the digital space.</p>
                    <p>For more details and professional SEO assistance, visit DoaGuru today and take your business to the next level.</p>

                    <h5>FAQs about SEO Services in India</h5>
                    <ul>
                      <li><strong>Why is DoaGuru considered the best SEO company in India?</strong><br />DoaGuru is ranked as the best SEO company in India due to its customized strategies, ethical SEO practices, and a team of experienced professionals who ensure businesses achieve long-term success in search rankings.</li>
                      <li><strong>How can SEO help my business grow?</strong><br />SEO increases your website’s visibility on search engines, drives organic traffic, and improves customer engagement.</li>
                      <li><strong>What is the average cost of SEO services in India?</strong><br />SEO pricing in India varies based on business size, industry, and competition. Typically, it ranges from ₹10,000 to ₹50,000 per month.</li>
                      <li><strong>How long does it take to see SEO results?</strong><br />SEO is a long-term investment. Generally, it takes 3 to 6 months to see noticeable improvements in rankings.</li>
                      <li><strong>What should I look for when choosing an SEO company?</strong><br />Consider experience, success stories, pricing, transparency, ethical practices, and client testimonials.</li>
                    </ul>

                    <h5>Contact DoaGuru Today!</h5>
                    <p>Looking for a professional SEO company in India? We provide expert solutions to enhance your website’s rankings and online presence. Get in touch with us today and take your business to the next level! 🚀</p>
                  </div>
                  <hr />
                </div>
              </div>





              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}


const Wrapper = styled.div`
  button {
    background-color: #fe7604;
    
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li{
    margin-bottom: 0.5rem;
    
  }
  ul{
    list-style  : none;
    
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft{
    margin-left: 2rem;
  }
`;
