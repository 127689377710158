import React from 'react';
import styled from "styled-components";

const Refund = () => {
  return (
    <>
    <Container>
        <div className="container">
        <h1 className="mb-4 mt-5 text-center">Refund & Cancelation Policy</h1>
        <p>Thank you for choosing DOA Guru InfoSystems. We are committed to providing quality products and services. Please review our return, refund, and cancellation policy below.</p>
        <br/>
          <p className='fs-5' style={{ color: "black" }}><strong>Refund Policy:</strong></p>
          {/* <p>There is no refund policy but if solution we provided is not working that particular module amount will be refunded within 45 days after informing to us.</p> */}


          <br />
          <p>At DOAGuru InfoSystems, we strive to ensure that our solutions meet the highest standards of quality and customer satisfaction. While we do not have a standard refund policy, we are committed to addressing any issues that may arise with our services.</p>
          <br />
          <h3>Refund Consideration</h3>
          <br />
          <p>If our solution does not meet your expectations, we may consider a refund for the specific module in question. This decision will be made on a case-by-case basis, taking into account both the company's guidelines and the client's concerns. We aim to ensure that any refund decision is fair and reasonable for both parties.</p>
          <br />
          <h3>Process for Refund Requests</h3>
          <br />
          <p>To initiate a refund request, please contact us at <a href="mailto:ingo@doaguru.com">Email</a>. We will review your request thoroughly and respond accordingly. Our goal is to provide a satisfactory resolution that aligns with our company's policies and customer satisfaction objectives.</p>
          <br />
          <h3>Transparency and Fairness</h3>
          <br />
          <p>We value transparency and fairness in all our interactions. If a refund is approved, we will process it promptly and efficiently. Our commitment to customer satisfaction is paramount, and we will work diligently to ensure that any issues are resolved to your satisfaction.</p>
          <br />
          <p><strong>Contact Us:</strong> For any questions or concerns regarding our refund policy, please feel free to reach out to us.</p>


          <br />
          <p className='fs-5' style={{ color: "black" }}><strong>Cancellation Policy:</strong></p>
          <p>Client needs to inform  us (Doa Guru InfoSystems or Doa Guru IT Solutions) one month in advance for stopping or cancelling the subscription and refund will be done with in 45 Days after informing to us.</p>
          <br />
          <p className='fs-5' style={{ color: "black" }}><strong>Contact Us:</strong></p>
          <p>For any questions regarding returns, refunds, or cancellations, please contact us at doaguruinfosystems@gmail.com.</p>
          <p>We appreciate your business and look forward to providing you with exceptional IT solutions.</p>
          <p><span>[</span> Doa Guru InfoSystems or Doa Guru IT Solutions <span>]</span></p>
        </div>
    </Container>
    </>
  )
}

export default Refund;
const Container = styled.div`
p{
    color: #7d858c;
}
span{
  color: chocolate;
  font-size: 1.1rem;
  font-weight: 700;
}
`;