import React from "react";
import styled from "styled-components";
import Finalnav from "../../page/Homepages/Finalnav";
import bgppc from "../images/bgppc.webp";
import { Link } from "react-router-dom";
import ppchero from "../images/ppchero.webp";
import ppcflex from "../images/ppcflex.webp";
import ppcper from "../images/ppcper.webp";
import ppcenhanced from "../images/ppcenhanced.webp";
import ppcsearch from "../images/ppcsearch.webp";
import ppcdisplay from "../images/pccdisplay.webp";
import ppcsopping from "../images/ppcsopping.webp";
import ppcapp from "../images/pccapp.webp";
import ppcgeo from "../images/ppcgeo.webp";
import ppcvideo from "../images/ppcvideo.webp";
import ppccamp from "../images/ppccamp.webp";
import ppckey from "../images/ppckey.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../Breadcrumb";
import Footer from "../../page/Homepages/Footer";

function Smm() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Finalnav />
      <Helmet>
        <title>
          PPC Power: Click, Convert, Succeed-Best Online marketing firm
        </title>
        <meta
          name="description"
          content="Drive Success with PPC Google AdWords: Measurable Results, Expert Techniques! Achieve PPC Victory 
          "
        />
          <link rel="canonical" href="https://doaguru.com/ppc"/>
      </Helmet>
      <Wrapper>
        <Breadcrumb />

        <div
          className="container-fluid"
          id="cont"
          style={{
            backgroundImage: `url(${bgppc})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row1">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <h1 className="hdd text-white">
                PPC Power: Click, Convert, Succeed
              </h1>
              <h5 className="text-white">
                "PPC Google AdWords: Effective Advertising , Measurable Results"
              </h5>
              <p className="pdd text-white mt-4">
                Grow PPC Success: Where Clicks Result in Conversions and
                Victory. We are PPC experts, offering top-notch techniques that
                guarantee the accomplishment of your digital marketing
                objectives. We are the greatest option for PPC success because
                of our skilled method, which optimises your campaigns for the
                best results.
              </p>
              <button
                type="button"
                className="btn mt-4 "
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12 mt-4">
              <img
                src={ppchero}
                className="img3 d-block w-100"
                alt="..."
                id="pccenter"
              />
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col-md-12 mb-4 mt-5 text-center">
              <h2 style={{}}>Importance of PPC</h2>
              <div
                className="underline mx-auto"
                style={{
                  height: 3,
                  width: "4rem",
                  backgroundColor: "#34495E",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              ></div>
              <p>
                <div data-aos="fade-up" data-aos-duration="3000">
                Pay Per Click (PPC) advertising plays a role, for businesses looking to gain quick and targeted visibility in the digital world. It provides a way to connect with customers who are actively searching for your products or services. By placing ads on search engines and platforms PPC offers visibility driving traffic to your website and generating leads. Its real-time nature allows for targeting, budget management, and measurable results ensuring that every penny spent delivers outcomes. For businesses aiming to enhance their presence increase conversions and capture audience attention promptly PPC is a tool, in the digital marketing toolbox.

                </div>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-2">
              <div className="card shadow" id="cardhd">
                <div data-aos="flip-left">
                  <img src={ppcflex} className=" mt-3" id="cun12" />
                  <div className="card body" id="cardbody">
                    <h6 style={{ textAlign: "center", margin: 25 }}>
                      Scalable and Flexible Campaigns
                    </h6>
                    <div className="underline"></div>
                    <p style={{ textAlign: "center", padding: "10px" }}>
                      All sizes and types of businesses can use Google Ads.
                      Google Ads provides the tools to scale and customise
                      campaigns to fit your goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-2">
              <div className="card shadow" id="cardhd">
                <div data-aos="flip-left">
                  <img src={ppcper} className=" mt-3" id="cun13" />
                  <div className="card body" id="cardbody">
                    <h6 style={{ textAlign: "center", margin: 25 }}>
                      Precise Targeting for Relevant Audiences
                    </h6>
                    <div className="underline"></div>
                    <p style={{ textAlign: "center", padding: "10px" }}>
                      Businesses can reach potential customers with amazing
                      precision using Google Ads. Campaigns can be customised by
                      advertisers based on demographics.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card shadow" id="cardhd">
                <div data-aos="flip-left">
                  <img src={ppcenhanced} className=" mt-3" id="cun14" />

                  <div className="card body" id="cardbody">
                    <h6 style={{ textAlign: "center", margin: 25 }}>
                      Enhanced Brand Visibility
                    </h6>
                    <div className="underline"></div>
                    <p style={{ textAlign: "center", padding: "10px" }}>
                      PPC campaigns increase brand awareness by placing
                      advertisements in key locations. Businesses can increase
                      their online visibility and attract a wider audience by
                      using targeted keywords and compelling ad material.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 className="sr text-center mt-5">
            {" "}
            Our Pay per click services
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
          </h2>
          <div className="row g-4 mt-3">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="card h-100 " id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={ppcsearch}
                    className="card-img-top "
                    alt="..."
                    id="ser1"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Search Advertising</h5>
                    <p className="card-text">
                      A key component of Google Ads is search advertising, which
                      enables companies, especially those partnered with the
                      Best digital marketing agency, to prominently display
                      text-based ads in Google's search results when customers
                      type in particular keywords.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={ppcdisplay}
                    className="card-img-top"
                    alt="..."
                    id="ser2"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Display Advertising</h5>
                    <p className="card-text">
                      Display advertising is an effective method of online
                      marketing that enables companies to present visually
                      appealing advertisements on an extensive web of websites.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={ppcsopping}
                    className="card-img-top"
                    alt="..."
                    id="ser3"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Shopping Advertising</h5>
                    <p className="card-text">
                      Google Ads' Shopping campaigns allow businesses to display
                      product images, prices, and store information, making it a
                      powerful tool for e-commerce.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100 " id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={ppcapp}
                    className="card-img-top "
                    alt="..."
                    id="ser4"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">App Advertising</h5>
                    <p className="card-text">
                      The secret to realising the full potential of your app is
                      app advertising. It all comes down to timing your message
                      to the appropriate audience. Effective app advertising
                      makes sure your app receives the attention it deserves and
                      converts users into devoted customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={ppcgeo}
                    className="card-img-top"
                    alt="..."
                    id="ser5"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      Geo-Targeting and Local Advertising
                    </h5>
                    <p className="card-text">
                      Businesses can concentrate their marketing efforts on
                      particular geographic regions using Google Ads'
                      geo-targeting and local advertising strategies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={ppcvideo}
                    className="card-img-top"
                    alt="..."
                    id="ser6"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Video Advertising</h5>
                    <p className="card-text">
                      Google Ads' effective video advertising strategy enables
                      companies to engage their audience with compelling video
                      content on websites like YouTube.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4 mt-3"></div>
          <div className="container">
            <h2 className="hd text-center" style={{ marginTop: "5rem" }}>
              Setting Up a Google Ads Campaign
              <div
                className="underline mx-auto"
                style={{
                  height: 3,
                  width: "4rem",
                  backgroundColor: "#34495E",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              ></div>
            </h2>

            <div className="container" id="ppccamp">
              <div className="row">
                <div className="col-sm-6">
                  <div data-aos="fade-right">
                    <img
                      id="clientpages"
                      src={ppccamp}
                      className="img-fluid"
                      style={{
                        marginTop: "1em",
                        width: "32rem",
                        height: "18rem",
                        borderRadius: "1rem",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div data-aos="fade-left">
                    <h3 className="sm">Campaign Structure</h3>
                    <p className="tt text-justify mt-3">
                      A well-structured campaign is necessary for efficient
                      Google Ads management. You can keep things under control,
                      keep an eye on things, and effectively optimize. The
                      following is a sample campaign structure:
                    </p>

                    <ul>
                      <li>Campaign Types and Goals</li>
                      <li>Naming Conventions</li>
                      <li>Ad Groups</li>
                      <li>Keyword Grouping</li>
                      <li>Keyword Research and Selection</li>
                    </ul>
                  </div>
                  <br />
                </div>
              </div>
            </div>

            <div className="container  " id="ppccamp">
              <div className="row">
                <div className="col-sm-6" id="mobile">
                  <div data-aos="fade-left">
                    <img
                      id="clientpages"
                      src={ppckey}
                      className="img-fluid"
                      style={{
                        width: "32rem",
                        height: "19rem",
                        borderRadius: "1rem",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6" id="smd">
                  <div data-aos="fade-right">
                    <h3 className="sm1">Keyword Research and Selection</h3>
                    <p className="text-justify mt-3">
                      {" "}
                      The foundation of any successful Google Ads campaign is
                      keyword research. By picking the appropriate keywords, you
                      can make sure that your ads are seen by the right people
                      and result in actionable clicks:
                    </p>
                    <ul>
                      <li>Brainstorm Initial Keywords</li>
                      <li>Long-Tail Keywords</li>
                      <li>Competitor Analysis</li>
                      <li>Keyword Organization</li>
                    </ul>
                  </div>
                  <br />
                </div>
                <div className="col-sm-6" id="desktop">
                  <div data-aos="fade-left">
                    <img
                      id="clientpages"
                      src={ppckey}
                      className="img-fluid"
                      style={{
                        width: "32rem",
                        height: "19rem",
                        borderRadius: "1rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="last">
          <div className="hero-section text-center">
            <h2 style={{ paddingTop: "2rem", color: "#155ea3" }}>
              Unlock Success with Pay-Per-Click Advertising
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
            <p className="mt-4">
              Are you willing to grow your company to new heights? Utilise
              Pay-Per-Click (PPC) advertising to your advantage for unrivalled
              growth in the online world. You can drive targeted traffic,
              increase conversions, and maximise your ROI with our professional
              strategies and cutting-edge techniques.
            </p>
            <button
              type="button"
              className="btn   mb-3"
              style={{ color: "white" }}
            >
              <Link
                to="/contact_us"
                onClick={handleFooterLink}
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Enquire Now
              </Link>
            </button>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default Smm;

const Wrapper = styled.div`
  #cont {
    height: 35rem;

    @media screen and (max-width: 768px) {
      height: 52rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 57rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      height: 30rem;
    }
  }
  #row1 {
    margin-left: 5rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  #clientpages {
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
  }
  #cardshadow {
    border-radius: 20px;
    box-shadow: 8px 8px 8px 8px whitesmoke;
    border: none;
  }
  #cardshadow:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .card-img-top {
    @media screen and (max-width: 768px) {
      margin: auto;
    }
  }
  #smd {
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
  }
  #pccenter {
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      margin-top: -0.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 28rem;
      margin-top: 0rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      padding: 7rem;
      margin-top: -9rem;
    }
  }
  #cun12 {
    height: 8rem;
    width: 10rem;
    margin-left: 6rem;

    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 7.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: 1.7rem;
    }
  }
  #cun13 {
    height: 8rem;
    width: 8rem;
    margin-left: 7rem;

    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 8.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: 2.5rem;
    }
  }

  #cun14 {
    height: 8rem;
    width: 8rem;
    margin-left: 7rem;

    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 8.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: 2.5rem;
    }
  }
  #ser1 {
    width: 12rem;
    margin-left: 5rem;
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 7rem;
    }
  }
  #ser2 {
    width: 15rem;
    height: 9rem;
    margin-left: 3rem;
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 5rem;
    }
  }
  #ser3 {
    width: 15rem;
    height: 9rem;
    margin-left: 4rem;
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 5.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 13rem;
      height: 9rem;
    }
  }

  #ser4 {
    width: 12rem;
    height: 9rem;
    margin-left: 5rem;
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 6.5rem;
    }
  }
  #ser5 {
    width: 15rem;
    height: 9rem;
    margin-left: 4rem;
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 13rem;
      height: 9rem;
    }
  }
  #ser6 {
    width: 15rem;
    height: 9rem;
    margin-left: 4rem;
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 5rem;
    }
  }
  .sm {
    margin-top: 1rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
    }
  }
  .sm1 {
    margin-top: 1rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0.5rem;
    }
  }
  #cardbody {
    height: 220px;
    border: none;

    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 23rem;
    }
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    font-size: 28px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  #cardhd {
    height: 26rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 33rem;
    }
  }
  #mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: block;
    }
  }
  #desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: none;
    }
  }
  #ppccamp {
    margin-top: 3rem;
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 2rem;
    }
  }
  .hdd {
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;
