import React from "react";

import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillMail,
  AiFillPhone,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { FaPinterestSquare, FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Information() {
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };

  const gradientBackground = 'linear-gradient(to right, #00e5e5, #86ff68)';
  return (
    <Wrapper>
      <div className="container-fluid py-3" id="fluid"
       style={{backgroundColor:"#002c63"}}>
        <div className="row">
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-6" id="phone">
                <AiFillPhone size="28px" color="#86FF68" />
                <span className="text-white" ><Link to="tel:+91-7440992424"  style={{ textDecoration: "none" }}
                  className="text-white">+91-7440992424 , 8827311424</Link></span>
              </div>
              <div className="col-sm-6 px-4" id="left">
                <AiFillMail size="28px"  color="#86FF68" />
                <span className="m-2 text-white"> <Link to="mailto:info@doaguru.com" style={{ textDecoration: "none" }}
                  className="text-white">info@doaguru.com</Link></span>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
          <div className="" id="logo">
                  <Link
                    to="https://www.facebook.com/Digitalmarketingandwebsitedesigncompany/"
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <AiFillFacebook
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "white")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "#86FF68")
                      }
                    />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/doaguru-infosystems/"
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <AiFillLinkedin
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "white")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "#86FF68")
                      }
                    />
                  </Link>
                  <Link
                    to="https://www.instagram.com/doaguru_infosystem?igsh=MTdzdDdjdDAxNGVuOQ=="
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <AiFillInstagram
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "white")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "#86FF68")
                      }
                    />
                  </Link>
                  <Link
                    to="https://x.com/DoaguruS"
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <FaTwitterSquare
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "white")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "#86FF68")
                      }
                    />
                  </Link>
                       <Link
                    to="https://pin.it/6yE5MBu"
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <FaPinterestSquare
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "white")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "#86FF68")
                      }
                    />
                  </Link> 
                </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Information;

const Wrapper = styled.div`
  #left {
    margin-left: -10rem;
    @media screen and (max-width: 768px) {
      margin-left: 0rem;
      margin-top: 1rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
     margin-left: -4rem;
    }
    @media screen and (min-width: 1020px) and (max-width: 1300px) {
      margin-left: -5rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      margin-left: -17rem;
    }
  }
  #logo {
    margin-left: 9rem;
    @media screen and (max-width: 768px) {
      margin-left: 0.3rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
     margin-left: 0rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      margin-left: 16rem;
    }
  }
  #phone {
    margin-left: 1rem;
    @media screen and (max-width: 768px) {
      margin-left: 0.5rem;
      
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1.2rem;
    }
  }

  
`;
