import React from "react";
import styled from "styled-components";
import Finalnav from "../../page/Homepages/Finalnav";
import bgsmm from "../images/bgsmm.webp";
import smmhr from "../images/smmhr-removebg.webp";
import ssm2 from "../images/ssm2.webp";
import ssmnew from "../images/ssmnew.webp";
import smmnew7 from "../images/smmnew7.webp";
import smmls from "../images/smmls.webp";
import smmlg3 from "../images/smmlg3.webp";
import smmlg5 from "../images/smmlgf.webp";
import a1 from "../images/a1.webp";
import a3 from "../images/a3.webp";
import a6 from "../images/a6.webp";
import stragy from "../images/stragy.webp";
import contentcre from "../images/contectcre.webp";
import comeng from "../images/comeng.webp";
import influ from "../images/influ.webp";
import paid from "../images/paid.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumb from "../Breadcrumb";
import Footer from "../../page/Homepages/Footer";
function Smm() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Finalnav />
      <Helmet>
        <title>
        Best Social Media Marketing Company in Jabalpur - DOAGuru InfoSystems

        </title>
        <meta
          name="description"
          content="DOAGuru InfoSystems is the Leading Social Media Marketing company in India. From engaging posts to targeted ads, we specialize in social media strategies that boost your brand's online presence and drive results."
        />
          <link rel="canonical" href="https://doaguru.com/Best-social-media-marketing-company-in-Jabalpur-India"/>
      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div
          className="container-fluid"
          id="cont"
          style={{
            backgroundImage: `url(${bgsmm})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row1">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
                Best Social Media Marketing Agency - Socialize Your Success
              </h1>
              <h5 className="hdd text-white">
              Your Success Story Begins With Your Social Move
              </h5>
              <p className="pdd text-white mt-4">
                Social networking is a strong tool in today's digital landscape
                for attaining commercial success. Our expertise involves
                assisting businesses of all kinds to establish connections with
                their intended market, increase brand awareness, and encourage
                significant interaction on several social media networks. With
                billions of consumers using these platforms every day, there are
                countless chances for growth and influence. Allow us to be your
                driving force as you harness social media's full potential for
                the success of your brand. Come along with us today as Doaguru
                IT Solutions takes you on a successful social media marketing.
              </p>

              <button
                type="button"
                className="btn mt-4 "
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12">
              <img src={smmhr} className="img3 d-block" alt="..." id="herobg" />
            </div>
          </div>
        </div>
        <br />

        <div className="container">
          <h2 className="hd text-center mb-5">
            Services We Offer
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 20,
                marginBottom: 10,
              }}
            ></div>
          </h2>

          <div className="container  " id="smmaudit">
            <div className="row">
              <div className="col-sm-6">
                <div data-aos="fade-right">
                  <img
                    id="clientpages"
                    src={ssmnew}
                    className="img-fluid"
                    style={{
                      marginTop: "1em",
                      height: "16rem",
                      width: "32rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div data-aos="fade-left">
                  <h3 className="sm">Social Media Audit</h3>
                  <p className="tt text-justify mt-3">
                    We start with a thorough social media audit, which is an
                    essential first step for every effective social media
                    marketing company. With the support of the top IT and
                    digital marketing firms, we can do an extensive analysis of
                    your current online presence through this audit, spotting
                    significant growth prospects and capitalising on your
                    current advantages. This serves as the cornerstone for
                    creating a clever and effective marketing strategy, laying
                    the groundwork for extraordinary success in all of your
                    digital pursuits.
                  </p>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div className="container " id="smmaudit">
            <div className="row">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={stragy}
                    className="img-fluid"
                    style={{
                      height: "16rem",
                      width: "32rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6" id="smd">
                <div data-aos="fade-right">
                  <h3 className="sd">Strategy Development</h3>
                  <p className="text-justify mt-3">
                    {" "}
                    Being a top social media marketing company, we are great at
                    combining a sound plan with your company's goals. Setting up
                    specific goals, selecting the most relevant social media
                    platforms, and creating a content calendar that works in
                    unison with your entire marketing plan are all necessary for
                    this.
                  </p>
                </div>
                <br />
              </div>

              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={stragy}
                    className="img-fluid"
                    style={{
                      height: "16rem",
                      width: "32rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container " id="smmaudit">
            <div className="row">
              <div className="col-sm-6">
                <div data-aos="fade-right">
                  <img
                    src={contentcre}
                    className="img-fluid"
                    style={{
                      marginTop: "1em",
                      height: "16rem",
                      width: "32rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div data-aos="fade-left">
                  <h3 className="cc">Content Creation</h3>
                  <p className="text-justify mt-3">
                    {" "}
                    Strategies are then transformed into compelling content by
                    our creative team specialising in social media marketing
                    services. We create dynamic videos and animations, visually
                    appealing graphics and images, and interesting text posts
                    that draw readers in and encourage interaction.
                  </p>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div className="container  " id="smmaudit">
            <div className="row">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={comeng}
                    className="img-fluid"
                    style={{
                      height: "16rem",
                      width: "32rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6" id="smd">
                <div data-aos="fade-right">
                  <h3 className="cg">Community Engagement</h3>
                  <p className="text-justify mt-3">
                    Our social media marketing agency specialises in crafting
                    strategies designed to engage your audience effectively.
                    Through prompt responses to messages and comments,
                    initiating meaningful conversations, and nurturing a sense
                    of community around your brand, we actively interact with
                    your followers.
                  </p>
                </div>
                <br />
              </div>
              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={comeng}
                    className="img-fluid"
                    style={{
                      height: "16rem",
                      width: "32rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="smmaudit">
            <div className="row">
              <div className="col-sm-6">
                <div data-aos="fade-right">
                  <img
                    src={influ}
                    className="img-fluid"
                    style={{
                      marginTop: "1em",
                      height: "16rem",
                      width: "32rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div data-aos="fade-left">
                  <h3 className="ic">Influencer Collaborations</h3>
                  <p className="text-justify mt-3">
                    {" "}
                    As a social media marketing firm, we find influencers that
                    are relevant to your industry and business. By forming smart
                    connections and working together, we are able to engage with
                    the influencer's audience, expanding your reach and building
                    genuine relationships.
                  </p>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div className="container " id="smmaudit">
            <div className="row">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={paid}
                    className="img-fluid"
                    style={{
                      height: "16rem",
                      width: "32rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6" id="smd">
                <div data-aos="fade-right">
                  <h3 className="pa">Paid Advertising</h3>
                  <p className="text-justify mt-3">
                    Your brand's visibility is greatly amplified through our
                    expertly managed social media marketing services. By
                    leveraging targeted social media advertising campaigns, in
                    collaboration with the best IT company, we ensure that your
                    ads are strategically placed before the right audience,
                    yielding the highest return on investment. Through efficient
                    budget allocation, real-time campaign tracking, and
                    continuous optimization, we guarantee that your brand
                    reaches its full potential.
                  </p>
                </div>
                <br />
              </div>
              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={paid}
                    className="img-fluid"
                    style={{
                      height: "16rem",
                      width: "32rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <h2 className="sr text-center mt-5">
            {" "}
            Our Approach to Social Media Marketing
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
          </h2>
          <div className="row row-cols-1 row-cols-md-2 g-4 mt-3">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100 " id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={a1}
                    className="card-img-top "
                    alt="..."
                    style={{
                      width: "16rem",
                      marginRight: "",
                      height: "12rem",
                      padding: "1rem",
                    }}
                    id="cent"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      Customized Social Media Strategies
                    </h5>
                    <p className="card-text">
                      Your social media strategy should be, in our opinion, tailored to the uniqueness that each business brings to
                      the marketplace.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={ssm2}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "16rem", height: "12rem", padding: "1rem" }}
                    id="cent"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      Emphasis on Audience Research and Segmentation
                    </h5>
                    <p className="card-text">
                      Effective social media marketing services require a deep
                      understanding of your target audience. This foundational
                      knowledge serves as the bedrock upon which you build your
                      strategies and campaigns.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={a3}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "16rem", height: "12rem", padding: "1rem" }}
                    id="cent"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      Content Planning and Creation
                    </h5>
                    <p className="card-text">
                      The key to a successful social media marketing strategy is
                      compelling content. Our creative team is exceptional at
                      creating compelling text posts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={a6}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "16rem", height: "12rem", padding: "1rem" }}
                    id="cent"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      {" "}
                      Posting Schedules and Frequency
                    </h5>
                    <p className="card-text">
                      In the social media world, consistency is crucial. We
                      create thoughtful posting schedules that maximise reach
                      and engagement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-12 mb-4 mt-5 text-center">
                <h2> Why Choose DOAGuru?</h2>
                <div
                  className="underline mx-auto"
                  style={{
                    height: 3,
                    width: "4rem",
                    backgroundColor: "#34495E",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>
              </div>

              <div className="col-md-6 mt-2">
                <div className="card shadow" style={{ border: "none" }}>
                  <img
                    src={smmls}
                    className="m-auto mt-3"
                    style={{ height: "5rem", width: "5rem" }}
                  />
                  <div className="card body" id="cardres">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Experienced Team
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "justify" }}>
                      Our knowledgeable team, sourced from the best digital
                      marketing company, excels at comprehending the constantly
                      evolving social media landscape. Their expertise in social
                      media marketing services allows us to create custom
                      strategies tailored to meet the unique needs of each
                      client.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-2">
                <div className="card shadow" style={{ border: "none" }}>
                  <img
                    src={smmnew7}
                    className="m-auto mt-3"
                    style={{ height: "5rem", width: "5rem" }}
                  />
                  <div className="card body" id="cardres">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Creativity and Innovation
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "justify" }}>
                      Our strategy is based on creativity and innovation. Our
                      creative team creates captivating content that stands out
                      while also building real relationships with your audience.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-2">
                <div className="card shadow" style={{ border: "none" }}>
                  <img
                    src={smmlg3}
                    className="m-auto mt-3"
                    style={{ height: "5rem", width: "5rem" }}
                  />
                  <div className="card body" id="cardres">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Data-Driven Approach
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "justify" }}>
                      Founded upon a robust bedrock of data-driven insights, our
                      operations stand on surefooted ground. With decisions
                      guided by meticulous analytics, we can assert with
                      confidence that they are meticulously contemplated and
                      aligned with the predilections of your audience.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-2">
                <div className="card shadow" style={{ border: "none" }}>
                  <img
                    src={smmlg5}
                    className="m-auto mt-3"
                    style={{ height: "5rem", width: "15rem" }}
                  />
                  <div className="card body" id="cardres">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                    Long Term Support
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "justify" }}>
                      We believe in fostering long-term partnerships with our
                      clients by providing continuous support that ensures your
                      IT infrastructure remains resilient and evolves alongside
                      your business. As a leading social media marketing agency,
                      we understand the critical role of digital presence in
                      today's competitive landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="last">
          <div className="hero-section text-center ">
            <h2 style={{ paddingTop: "4rem", color: "#155ea3" }}>
            Ready To Supercharge Your Brand With the Best Social Media Marketing Agency?
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
            <p className="">
              Dissatisfaction is over; growth is here. Come along with us on a
              journey where difficulties turn into learning opportunities,
              engagement soars, and success is within reach. As a social media
              marketing company, we bring you transformative SMM services. To
              start receiving these services right away, click the link below.
            </p>
            <button
              type="button"
              className="btn   mb-3"
              style={{ color: "white" }}
            >
              <Link
                to="/contact_us"
                onClick={handleFooterLink}
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Enquire Now
              </Link>
            </button>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default Smm;

const Wrapper = styled.div`
  #cont {
    height: 42rem;

    @media screen and (max-width: 768px) {
      height: 65rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 65rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      height: 35rem;
    }
  }
  #row1 {
    margin-left: 5rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  #clientpages {
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
  }
  #cardshadow {
    border-radius: 20px;
    box-shadow: 8px 8px 10px 1px whitesmoke;
  }
  #cardshadow:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .card-img-top {
    @media screen and (max-width: 768px) {
      margin-left: 3rem;
    }
  }
  #smd {
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
    }
  }

  #herobg {
    margin-top: 5rem;
    width: 105%;
    height: 83%;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
      margin-left: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 28rem;
      margin-top: 2rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      height: 35rem;
      margin-top: 1rem;
    }
  }

  #cent {
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 1.8rem;
    }
  }
  #cardres {
    height: 15rem;
    border: none;
    padding: 15px;
    @media screen and (max-width: 768px) {
      height: 21rem;
    }
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    font-size: 28px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  #mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: block;
    }
  }
  #desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: none;
    }
  }
  .sm {
    margin-top: 1.5rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
    }
  }
  .pa {
    margin-top: 3rem;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
    }
  }
  .cg {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
    }
  }
  .cc {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1em;
    }
  }
  .sd {
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0.5em;
    }
  }
  .ic {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0.5em;
    }
  }
  #smmaudit {
    margin-top: 3rem;
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 3em;
    }
  }
  .card-text {
    text-align: justify;
  }
  .ws {
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;
