import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogImg from "../images/Blog21img.png";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

export const Blog21Page = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
          Doaguru Infosystems: Best Digital Marketing Agency in Jabalpur
        </title>
        <meta
          name="description"
          content="Doaguru Infosystems is a leading digital marketing agency in Jabalpur offering SEO, website design, PPC, and social media marketing at affordable prices."
        />
        <link
          rel="canonical"
          href="https://doaguru.com/Digital-Marketing-Agency-in-Jabalpur"
        />
      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              Doaguru Infosystems: Digital Marketing Agency in Jabalpur
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={BlogImg} alt="" className="frontimg rounded m-4" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                    In today’s fast-paced digital world, having an online
                    presence isn’t just an option—it’s a necessity. Businesses
                    of all sizes compete for attention in the crowded digital
                    landscape, and without the right strategies, it’s easy to
                    get lost. That’s where Doaguru Infosystems comes in. As a
                    leading
                    <Link
                      to="https://doaguru.com"
                      target="_blank"
                      className="link-offset-3-hover link-offset-2 "
                    >
                      digital marketing agency in Jabalpur
                    </Link>, Doaguru Infosystems helps businesses transform their
                    online presence, generate leads, and achieve sustainable
                    growth. Whether you’re a startup aiming to create brand
                    awareness or an established company looking to scale,
                    Doaguru Infosystems has the tools, expertise, and passion to
                    drive results.
                  </p>
                  <br />
                  {/* <p>
                  In this blog, we’ll explore essential digital marketing skills, promising career opportunities, and actionable tips to succeed in this dynamic field.
                  </p> */}
                </div>
              </div>

              {isShowMore && (
                <div>
                  <div className="col-lg-12 mt-3">
                    <div data-aos="fade-left">
                      <h5>Why Choose Doaguru Infosystems?</h5>
                      <p>
                        Choosing the right digital marketing partner can
                        differentiate between stagnation and growth. At Doaguru
                        Infosystems, we don’t just offer digital marketing
                        services—we offer growth strategies tailored to your
                        business needs. Here’s why businesses in Jabalpur and
                        across India choose us as their trusted digital
                        marketing partner:
                      </p>
                      <ul>
                        <li>
                          <strong>Experienced Team of Experts</strong>{" "}
                          <p>
                            Our team consists of highly skilled professionals
                            with years of experience in various digital
                            marketing domains, including SEO, PPC, content
                            marketing, social media, and web development. We
                            stay updated on the latest industry trends, Google
                            algorithm changes, and marketing technologies to
                            deliver the best results.
                          </p>
                        </li>
                        <li>
                          <strong>Customized Marketing Strategies</strong>{" "}
                          <p>
                            We understand that every business is unique, with
                            its own set of goals, challenges, and target
                            audiences. That’s why we don’t believe in
                            one-size-fits-all solutions. Our team takes the time
                            to understand your business, analyze your
                            competitors, and create personalized marketing
                            strategies that align with your objectives.
                          </p>
                        </li>
                        <li>
                          <strong>Data-Driven Approach</strong>{" "}
                          <p>
                            In digital marketing, data is king. We rely heavily
                            on analytics and performance metrics to track the
                            success of our campaigns. By analyzing key data
                            points, we make informed decisions, optimize
                            strategies in real-time, and ensure maximum return
                            on investment (ROI) for our clients.
                          </p>
                        </li>
                        <li>
                          <strong>Transparent Communication</strong>{" "}
                          <p>
                            We believe in maintaining complete transparency with
                            our clients. From detailed monthly reports to
                            regular strategy discussions, we keep you in the
                            loop at every stage of your digital marketing
                            journey. You’ll always know how your campaigns are
                            performing and where your marketing budget is being
                            spent
                          </p>
                        </li>
                        <li>
                          <strong>Affordable Pricing</strong>{" "}
                          <p>
                            Quality digital marketing doesn’t have to break the
                            bank. At Doaguru Infosystems, we offer competitive
                            pricing packages designed to fit businesses of all
                            sizes. Whether you’re a small business with a
                            limited budget or a large enterprise, we have
                            cost-effective solutions to meet your needs.
                          </p>
                        </li>
                        {/* <li><strong>PPC Advertising:</strong> Expertise in managing paid campaigns ensures measurable results.</li>
                        <li><strong>UX/UI Design Basics:</strong> Helps create visually appealing websites that drive conversions.</li> */}
                      </ul>
                      <hr />
                      <h5>Our Comprehensive Digital Marketing Services</h5>
                      <p>
                        At Doaguru Infosystems, we offer a wide range of digital
                        marketing services designed to help businesses succeed
                        in the online world. Here’s an in-depth look at what we
                        offer:
                      </p>
                      <ul>
                        <h4>1. Search Engine Optimization (SEO)</h4>
                        <p>SEO is the foundation of any successful digital marketing strategy. It’s all about making your website visible to potential customers when they search for products or services related to your business. Our SEO experts use proven techniques to improve your website’s search engine rankings, drive organic traffic, and boost conversions.</p>
                        <h5>Our SEO Services Include:</h5>
                        <li>
                          <strong>On-Page SEO:</strong> We optimize your website’s content, meta tags, headers, images, and internal links to improve search engine visibility.
                        </li>
                        <li>
                          <strong>Off-Page SEO:</strong> Our off-page strategies focus on building high-quality backlinks, influencer outreach, and content promotion to enhance your website’s authority.

                        </li>
                        <li>
                          <strong>Technical SEO:</strong> We address technical issues such as site speed, mobile responsiveness, crawl errors, and XML sitemaps to ensure that search engines can easily index your website.

                        </li>
                        {/* <li>
                          <strong>
                            <Link
                              to="https://doaguru.com"
                              target="_blank"
                              className="link-offset-3-hover link-offset-2 "
                            >
                              digital marketing
                            </Link>{" "}
                            Manager:
                          </strong>{" "}
                          Oversees online marketing campaigns.
                        </li>
                        <li>
                          <strong>PPC Specialist:</strong> Designs and optimizes
                          paid advertising campaigns.
                        </li> */}
                        <li>
                          <strong>Local SEO:</strong> For businesses targeting a local audience, we optimize Google My Business listings, local citations, and location-based keywords to boost local search rankings.

                        </li>
                        {/* <li>
                          <strong>AI Marketing Specialist:</strong> Leverages AI
                          tools to automate tasks and drive insights.
                        </li> */}
                        <h4>2. Social Media Marketing (SMM)</h4>
                        <p>Social media has revolutionized the way businesses interact with their audience. Platforms like Facebook, Instagram, Twitter, and LinkedIn offer powerful tools to build brand awareness, engage with customers, and drive traffic to your website. At Doaguru Infosystems, we create compelling social media campaigns that resonate with your target audience.</p>
                        <h5>Our SMM Services Include:
                        </h5>
                        <li>
                          <strong>Social Media Strategy Development:</strong> We develop customized strategies based on your brand’s goals and target audience.
                        </li>
                        <li>
                          <strong>Content Creation & Management:</strong> Our creative team designs eye-catching graphics, writes engaging posts, and manages your social media profiles.

                        </li>
                        <li>
                          <strong>Paid Advertising:</strong> We run targeted ad campaigns on platforms like Facebook Ads, Instagram Ads, and LinkedIn Ads to generate leads and increase sales.

                        </li>
                        <li>
                          <strong>Community Engagement:</strong> We actively engage with your audience, respond to comments, and manage online communities to foster brand loyalty.

                        </li>
                        <h4>3. Pay-Per-Click (PPC) Advertising</h4>
                        <p>If you want immediate results, PPC advertising is the way to go. It allows you to target specific keywords and demographics, ensuring your ads reach the right audience at the right time. Our PPC specialists are Google Ads certified and have a proven track record of delivering high-converting campaigns.</p>
                        <h5>Our PPC Services Include:
                        </h5>
                        <li>
                          <strong>Search Engine Ads:</strong> We create text-based ads that appear at the top of Google search results for relevant keywords.
                        </li>
                        <li>
                          <strong>Display Ads:</strong> Our visually appealing banner ads are displayed on popular websites within Google’s Display Network.

                        </li>
                        <li>
                          <strong>Remarketing Campaigns:</strong> We target users who have previously visited your site to re-engage them and encourage conversions.

                        </li>
                        <li>
                          <strong>YouTube Advertising:</strong>  Video ads are a powerful way to connect with your audience. We create and manage YouTube ad campaigns to maximize reach and engagement.

                        </li>
                        <h4>4. Website Design & Development</h4>
                        <p>Your website is often the first impression potential customers have of your business. A professionally designed website can significantly impact user experience, conversion rates, and SEO. At Doaguru Infosystems, we design responsive, user-friendly websites that not only look great but also drive results.</p>
                        <h5>Our Web Development Services Include:
                        </h5>
                        <li>
                          <strong>Custom Website Design:</strong> We create unique website designs that reflect your brand identity.
                        </li>
                        <li>
                          <strong>Responsive Design:</strong> Our websites are optimized for all devices, including desktops, tablets, and smartphones.

                        </li>
                        <li>
                          <strong>E-commerce Solutions:</strong> We develop robust e-commerce platforms to help you sell products online.

                        </li>
                        <li>
                          <strong>UI/UX Design:</strong>  Our user interface and user experience designs ensure smooth navigation and enhanced usability.

                        </li>
                        <h4>5. Content Marketing</h4>
                        <p>Content is the heart of digital marketing. High-quality content not only attracts potential customers but also establishes your brand as an authority in your industry. Our content marketing strategies focus on creating valuable, relevant, and engaging content that drives traffic and generates leads.</p>
                        <h5>Our Content Marketing Services Include:
                        </h5>
                        <li>
                          <strong>Blog Writing:</strong> We write informative and SEO-optimized blog posts to attract organic traffic.
                        </li>
                        <li>
                          <strong>Website Content:</strong> Our team creates compelling website copy that reflects your brand’s voice and message.

                        </li>
                        <li>
                          <strong>Video Content:</strong> We produce engaging video content for social media, websites, and YouTube.
                        </li>
                        <li>
                          <strong>Infographics:</strong> Our visually appealing infographics simplify complex information and improve audience engagement.
                        </li>
                        <h4>6. Email Marketing</h4>
                        <p>Despite the rise of social media, email marketing remains one of the most effective ways to nurture leads and retain customers. We design personalized email campaigns that drive engagement, conversions, and customer loyalty.</p>
                        <h5>Our Email Marketing Services Include:
                        </h5>
                        <li>
                          <strong>Email Campaign Strategy:</strong> We develop customized email marketing strategies based on your business goals.
                        </li>
                        <li>
                          <strong>Newsletter Creation:</strong> Our creative team designs eye-catching newsletters that deliver value to your subscribers.
                        </li>
                        <li>
                          <strong>Automation: </strong> We set up automated email workflows to nurture leads and convert prospects into customers.

                        </li>
                        <li>
                          <strong>Analytics & Reporting:</strong> We track email performance metrics to optimize future campaigns.

                        </li>
                        <h4>7. Online Reputation Management (ORM)</h4>
                        <p>In the digital age, your online reputation can make or break your business. Negative reviews, social media backlash, or bad press can harm your brand’s image. Our ORM services are designed to monitor, manage, and improve your brand’s online reputation.</p>
                        <h5>Our ORM Services Include
                        </h5>
                        <li>
                          <strong>Brand Monitoring:</strong> We keep track of online mentions of your brand across social media, review sites, and forums.
                        </li>
                        <li>
                          <strong>Review Management:</strong> We respond to customer reviews, address negative feedback, and encourage positive reviews.

                        </li>
                        <li>
                          <strong>Crisis Management:</strong> In case of a reputation crisis, we implement strategies to minimize damage and restore your brand’s image.

                        </li>
                        {/* <li>
                          <strong>Community Engagement:</strong> We actively engage with your audience, respond to comments, and manage online communities to foster brand loyalty.

                        </li> */}
                      </ul>
                      <hr />
                      <h5>How Doaguru Infosystems Helps Businesses Grow</h5>
                      <p>At Doaguru Infosystems, we don’t just focus on marketing tactics—we focus on business growth. Here’s how we help businesses achieve their goals:</p>
                      <ul>
                        <li>
                          <strong>Enhanced Online Visibility:</strong> Our SEO and SMM strategies improve your online presence, making it easier for potential customers to find you.
                        </li>
                        <li>
                          <strong>Lead Generation:</strong> We create targeted marketing campaigns that generate high-quality leads and drive conversions.
                        </li>
                        <li>
                          <strong>Brand Building:</strong> Our content marketing and social media strategies help establish your brand’s authority in your industry.
                        </li>
                        <li>
                          <strong>Revenue Growth:</strong> By optimizing every aspect of your digital marketing, we help increase sales and maximize ROI.
                        </li>
                        {/* <li>
                          <strong>Experiment and Learn:</strong> Test strategies
                          on personal projects or freelance gigs.
                        </li> */}
                      </ul>
                      {/* <hr /> */}
                      {/* <h5>The Future of Digital Marketing</h5>
                      <p>
                        <Link
                          to="https://doaguru.com"
                          target="_blank"
                          className="link-offset-3-hover link-offset-2 "
                        >
                          digital marketing
                        </Link>{" "}
                        will continue to grow, driven by advancements in AI,
                        machine learning, and data-driven strategies.
                      </p> */}
                      <hr />
                      <h5>Conclusion</h5>
                      <p>
                      In today’s competitive digital landscape, having a robust online presence is essential for business growth. Doaguru Infosystems, the leading digital marketing agency in Jabalpur, is your partner in navigating the complex world of digital marketing. With our expertise, data-driven strategies, and commitment to excellence, we help businesses achieve their marketing goals and drive long-term success.
                      <br />
                      <br />
                      <Link
                            to="https://doaguru.com"
                            target="_blank"
                            className="link-offset-3-hover link-offset-2 "
                          >Contact us today</Link> to take your business to the next level!

                      </p>
                      <hr />
                      <h5>Frequently Asked Questions (FAQs)</h5>
                      <ol>
                        <li className="h6">
                        What industries do you specialize in?
                        </li>
                        <p className="mleft">
                        We work with businesses across various industries, including e-commerce, healthcare, education, real estate, hospitality, and more.
                        </p>
                        <li className="h6">
                        How do you measure the success of digital marketing campaigns?
                        </li>
                        <p className="mleft">
                        We use key performance indicators (KPIs) such as website traffic, conversion rates, engagement metrics, and ROI to measure success.
                        </p>
                        <li className="h6">
                        Can small businesses afford your digital marketing services?
                        </li>
                        <p className="mleft">
                        Yes, we offer affordable digital marketing packages tailored to meet the needs of small businesses.
                        </p>
                        <li className="h6">
                        How soon can I expect results?
                        </li>
                        <p className="mleft">
                        While PPC campaigns can deliver immediate results, SEO and content marketing may take 3-6 months to show significant improvements.
                        </p>
                        <li className="h6">
                        How can I get started with Doaguru Infosystems?
                        </li>
                        <p className="mleft">
                        Simply reach out to us at <b>+91-7440992424 / +91-8827311424</b> or visit our office at <b>1815 Wright Town, Jabalpur, Madhya Pradesh, India – 482002.</b>
                        </p>
                      </ol>
                      <p>
                        Ready to excel in{" "}
                        <Link
                          to="https://doaguru.com"
                          target="_blank"
                          className="link-offset-3-hover link-offset-2 "
                        >
                          digital marketing
                        </Link>
                        ? Stay informed, keep learning, and adapt to the
                        changing landscape.
                      </p>
                    </div>
                    <hr />
                  </div>
                </div>
              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

const Wrapper = styled.div`
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li {
    margin-bottom: 0.5rem;
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft {
    margin-left: 2rem;
  }
`;
