import React, { useState } from "react";
import axios from "axios";
import cogoToast from "cogo-toast";
import styled from "styled-components";
import { FaLongArrowAltRight } from "react-icons/fa";
// import SectionHeading from "../SectionHeading";
// import Spacing from "../Spacing";
// import Swal from "sweetalert2";

export default function ContactForm({
  sectionTitle,
  sectionTitleUp,
  onSubmit,
}) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });

  // Handler for input field changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmitForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://doaguru.com/api/auth/userdata",
        {
          name: formData.name,
          company: formData.company,
          email_id: formData.email, // Backend expects email_id
          phone_no: formData.phone, // Backend expects phone_no
          message: formData.message,
        }
      );

      if (response.status === 200) {
        cogoToast.success(
          "Thank You for Contacting Us! Our team will connect with you shortly.",
          {
            position: "top-right",
          }
        );

        setFormData({
          name: "",
          company: "",
          email: "",
          phone: "",
          message: "",
        });

        // Call the onSubmit prop to handle modal closure
        onSubmit();
      }
    } catch (error) {
      console.log(error);
      cogoToast.error(
        "An error occurred while sending data. Please try again.",
        {
          position: "top-right",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  return (
    <Container>
      <div
        className="cs_contact_form cs_style_2 cs_radius_30 modalalay"
        // style={{ backgroundImage: url("/images/contact/HeroContacts.png") }}
      >
        <div className="row">
          {/* <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} /> */}
          {/* <Spacing md="10" lg="10" /> */}
          <h1> Contact Us !</h1>
          <form
            onSubmit={onSubmitForm} // Use onSubmitForm for form submission
            className="row st-contact-form st-type1"
            method="post"
            id="contact-form"
          >
            <div className="col-lg-12">
              <label className="d-flex justify-content-start cs_input_label cs_heading_color">
                Full Name
              </label>
              <input
                type="text"
                className="cs_form_field"
                placeholder="Enter Your Name"
                id="name"
                name="name"
                onChange={handleInputChange}
                value={formData.name}
                required
              />
              <div className="mt-2" />
            </div>
            <div className="col-lg-12">
              <label className="d-flex justify-content-start cs_input_label cs_heading_color">
                Company Name
              </label>
              <input
                type="text"
                className="cs_form_field"
                placeholder="Enter Your Company Name"
                id="company"
                name="company"
                onChange={handleInputChange}
                value={formData.company}
                required
              />
              <div className="mt-2" />
            </div>
            <div className="col-lg-12">
              <label className=" d-flex justify-content-start cs_input_label cs_heading_color">
                Email
              </label>
              <input
                type="email"
                className="cs_form_field"
                placeholder="Enter Your Email"
                id="email"
                name="email"
                onChange={handleInputChange}
                value={formData.email}
                required
              />
              <div className="mt-2" />
            </div>
            <div className="col-lg-12">
              <label className="d-flex justify-content-start cs_input_label cs_heading_color">
                Contact Number
              </label>
              <input
                type="text"
                className="cs_form_field"
                id="phone"
                name="phone"
                placeholder="7000XXXXXX"
                required
                onChange={handleInputChange}
                value={formData.phone}
                onKeyPress={handleKeyPress}
              />
              <div className="mt-2" />
            </div>
            <div className="col-lg-12">
              <label className="d-flex justify-content-start cs_input_label cs_heading_color">
                Message
              </label>
              <textarea
                cols={30}
                rows={3}
                id="message"
                name="message"
                className="cs_form_field"
                placeholder="Write something..."
                onChange={handleInputChange}
                value={formData.message}
              />
              <div className="" />
            </div>
            <div className="col-lg-12 d-flex justify-content-center">
              <div className=" mt-5  " />
              <button
                className="cs_btn cs_style_1 submit-button"
                type="submit"
                id="submit"
                name="submit"
              >
                <span>{loading ? "Sending..." : "Submit"}</span>
                <i>
                  <FaLongArrowAltRight />
                </i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  h1 {
    color: black;
    font-weight: 700;
  }
  .form-container {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 700px;
    margin: auto;
    font-family: "Poppins", sans-serif;
  }

  .form-row {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  .form-label {
    flex-basis: 30%;
    text-align: left;
  }

  .form-field {
    flex-basis: 70%;
  }

  .cs_input_label {
    font-size: 15px;
    color: #444;
    font-weight: 600;
  }

  .cs_form_field {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }

  .cs_form_field:focus {
    border-color: #00aaff;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 170, 255, 0.2);
  }

  textarea.cs_form_field {
    resize: none;
  }

  .submit-button {
    background: linear-gradient(135deg, #00aaff, #00d4ff);
    border: none;
    padding: 0px 30px;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 500;
  }

  .submit-button:hover {
    background: linear-gradient(135deg, #008fcc, #00b8ff);
  }

  .submit-button i {
    margin-left: 12px;
    transition: transform 0.3s ease;
    font-size: 30px;
  }

  .submit-button:hover i {
    transform: translateX(8px);
  }

  .row{
      display: flex;
    justify-content: center;

  }
`;
