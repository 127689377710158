import React, { useEffect } from "react";
import Finalnav from "./Homepages/Finalnav";
import Breadcrumb from "../components/Breadcrumb";
import casebg from "../components/images/casebg.webp";
import { Link } from "react-router-dom";
import styled from "styled-components";
import casehero from "../components/images/casehero.webp";
import jp from "../components/images/jp.webp";
import value from "../components/images/value.webp";
import mission from "../components/images/mission.webp";
import vission from "../components/images/vision.webp";
import spark from "../components/images/sparkweb.webp";
import pawan from "../components/images/pawanstapak.webp";
import karam from "../components/images/karam.webp";
import AOS from "aos";
import { Helmet } from "react-helmet";
import Footer from "./Homepages/Footer";
function Case_Studies() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Finalnav />
      <Breadcrumb />
      <Helmet>
        <title>DOAGuru -Best Digital Marketing Company- Case Studies</title>
        <meta
          name="description"
          content="Explore the Success Stories with DOAGuru, the Best Digital Marketing Company. Discover the Impactful Case Studies Reflecting our Expertise and Results


          "
        />
        <link rel="canonical" href="https://doaguru.com/case-studies" />
      </Helmet>
      <Wrapper>
        <div
          className="container-fluid"
          id="cont7"
          style={{
            backgroundImage: `url(${casebg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row67">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "5rem" }}>
              <h2 className="hdd1 text-white" style={{ marginBottom: "1rem" }}>
                Successful Case Study by
              </h2>
              <h1 className="hdd text-white">
                DOAGuru IT Solutions the Best Digital Marketing Company
              </h1>

              <button
                type="button"
                className="btn  mt-4"
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12" id="heroimg">
              <img src={casehero} className="img3" alt="..." id="fullscreen" />
            </div>
          </div>
        </div>
        <div className="container">
          <h2 className="mt-5 text-center">Case Studies</h2>
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "4rem",
              backgroundColor: "#34495E",
              marginTop: 10,
              marginBottom: 10,
            }}
          ></div>
          <div data-aos="fade-up" data-aos-duration="3000">
            <p className="text-center mb-5">
              Dedicated to our clients, DOA Guru Solution strives for 100%
              client satisfaction. In addition to seeing our clients succeed, we
              enjoy receiving recognition from them as well. Our expertise can
              change your perspective on digital marketing by delivering the
              results you never expected. With our multichannel experts, you
              won't just be able to feel your business' presence, but also its
              impact.
            </p>
          </div>
          <div className="">
            <h2 className="text-center">"The key to success is simplicity"</h2>
            <h6 className="text-center">
              Here are some case studies that give a peek idea about our
              working:-
            </h6>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6 col-md-12">
              <div data-aos="fade-right">
                <img
                  id="clientpages1"
                  src={jp}
                  style={{ animation: "fadeIn 15s;" }}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div data-aos="fade-left">
                <h3 style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }}>
                  Jan Jyoti Super Specialty Eye Hospital
                </h3>
                <text>
                  Jan Jyoti Eye Hospital is one of the renowned eye care centres
                  in Jabalpur. A team of experienced and passionate
                  ophthalmologists, optometrists, and support staff are the
                  pride of this Hospital By consistently implementing
                  cutting-edge innovations, Janjyoti Eye Hospital maintains its
                  leadership in a time of rapid technological growth.
                </text>
                <br />
                <br />
                <ul>
                  <li className="h5">Challenges</li>
                  <li className="h5">Solution</li>
                  <li className="h5">Result</li>
                </ul>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 ">
                <div data-aos="flip-left">
                  <img src={mission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4"> Challenges</h5>

                  <h6 className="">1)Many competitors.</h6>
                  <h6 className="">
                    2)Building trust in their services and managing patient
                    feedback were priorities in reputation management.
                  </h6>
                  <h6 className="">3)Website that isn't Mobile Friendly.</h6>
                  <h6 className="">4)Untapped Social Media Potential.</h6>
                  <h6 className="">5)Limited online visibility.</h6>
                </div>
              </div>
              <div className="col-md-12  mt-4">
                <div data-aos="flip-left">
                  <img src={vission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4"> Solutions</h5>
                  <p className="par-text">
                    We have used specialised solutions to address a variety of
                    issues with our digital marketing efforts. We put accuracy
                    first for Google My Business, use appropriate keywords, and
                    interact with feedback on a regular basis.
                  </p>
                  <p>
                    <p>
                      We've mastered local search terms, created location-based
                      content, and carefully maintained directories.
                    </p>
                  </p>
                  <p>
                    Partnerships and social interaction are key to the success
                    of our local backlinks. We produce top-notch content,
                    network efficiently, and use social media to overcome the
                    backlink difficulty.
                  </p>
                  <p>
                    Image optimisation, fewer queries, and browser caching all
                    contribute to faster websites.
                  </p>
                  <p>
                    {" "}
                    In terms of technical SEO, we offer a user-friendly layout,
                    quick page loads, security with SSL certificates, sitemaps,
                    and structured data for improved user experience and search
                    engine performance.
                  </p>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div data-aos="flip-left">
                  <img src={value} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4"> Results</h5>
                  <p className="par-text">
                    Expanded Patient Base: Notable influx of new patients,
                    attributed to the hospital's online presence.
                  </p>
                  <p>
                    Enhanced User Experience: Improved website and booking
                    system, reducing bounce rates.
                  </p>
                  <p>
                    Stronger Brand Recognition: Janjyoti Eye Hospital became
                    synonymous with quality eye care.
                  </p>
                  <p>
                    Positive Patient Testimonials: Genuine testimonials added
                    credibility to their online reputation
                  </p>
                  <p>
                    Optimized Marketing Costs: Efficient digital marketing
                    delivered a higher ROI.
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-md-12">
              <div data-aos="fade-right">
                <img
                  id="clientpages1"
                  src={spark}
                  style={{ animation: "fadeIn 15s;" }}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div data-aos="fade-left">
                <h3>Sparkbuild Construction</h3>
                <text>
                  Sparkbuild Associates, established in 2016 by Er. Pankaj Sen,
                  is a dynamic player in the Indian infrastructure sector.
                  Originally founded as a civil engineering consultancy, the
                  company has since evolved to offer a wide spectrum of
                  services, including architecture, civil and structural
                  engineering, construction, advanced technologies, and real
                  estate. With a commitment to innovation and adaptability,
                  Sparkbuild Associates takes on projects of all sizes, from
                  intricate micro-level endeavors to ambitious mega-projects,
                  all while ensuring a focus on simplicity and clarity in its
                  content.
                </text>
                <br />
                <br />
                <ul>
                  <li className="h5">Challenges</li>
                  <li className="h5">Solutions</li>
                  <li className="h5">Results</li>
                </ul>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 ">
                <div data-aos="flip-left">
                  <img src={mission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Challenges</h5>

                  <h6 className="">
                    Our client came to us with a unique challenge: they needed a
                    mobile website that not only had a distinctive custom logo
                    but also featured straightforward and concise content, with
                    a strong emphasis on visuals over text. Their main objective
                    was to make sure the website not only looked clean and
                    visually appealing but also left the client fully satisfied.
                  </h6>
                </div>
              </div>
              <div className="col-md-12  mt-5">
                <div data-aos="flip-left">
                  <img src={vission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Solutions</h5>
                  <p className="par-text">
                    Client Satisfaction: Our client's happiness was our top
                    priority. We maintained open lines of communication,
                    ensuring that their feedback and preferences were at the
                    heart of the design and content choices we made. This
                    client-centric approach resulted in a website that fully
                    aligned with their vision and exceeded their expectations.
                  </p>
                  <p>
                    Innovative Ideas and Valuable Suggestions: Throughout the
                    project, we provided the client with innovative ideas and
                    practical suggestions to enhance the overall user
                    experience. Our recommendations covered everything from
                    interactive features to layout improvements, all aimed at
                    maximizing the website's effectiveness.
                  </p>
                  <p>
                    24/7 Availability: We were there for our client around the
                    clock, addressing their inquiries and concerns promptly.
                    This high level of accessibility ensured a seamless and
                    efficient project development process.
                  </p>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div data-aos="flip-left">
                  <img src={value} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Results</h5>
                  <p className="par-text">
                    We rose to the challenge and delivered a mobile website that
                    not only met but exceeded our client's expectations. Here's
                    what we achieved:
                  </p>
                  <p>
                    Customized Logo: We crafted a one-of-a-kind logo that not
                    only looked stunning but also perfectly captured the essence
                    of the client's brand. Our goal was to ensure that the logo
                    resonated with their target audience, leaving a lasting and
                    memorable impression.
                  </p>
                  <p>
                    Sweet and Simple Content: Simplicity and clarity were at the
                    forefront of our content strategy. We designed the website's
                    content to be concise, easy to grasp, and effective in
                    conveying the client's message without overwhelming users
                    with excessive text.
                  </p>
                  <p>
                    More Images, Less Text: To enhance user engagement and the
                    site's visual appeal, we strategically integrated
                    high-quality images throughout the website. These images not
                    only complemented the content but also served as visual
                    aids, making the website more engaging and user-friendly.
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-md-12">
              <div data-aos="fade-right">
                <img
                  id="clientpages1"
                  src={pawan}
                  style={{ animation: "fadeIn 15s;" }}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div data-aos="fade-left">
                <h3>Dr.Pawan Sthapak</h3>
                <text>
                  Dr Pawan Sthapak is a highly regarded eye specialist in
                  Jabalpur, known for his exceptional expertise in the field of
                  ophthalmology. He is the visionary founder of Janjyoti Super
                  Eye Specialty Hospital, a renowned healthcare institution
                  dedicated to providing top-quality eye care services to the
                  local community. Dr Sthapak's unwavering commitment to
                  improving vision and eye health has earned him the blessings
                  and recognition of Dvjei Netralaya, further solidifying his
                  reputation as a respected figure in the world of eye care.
                </text>
                <br />
                <br />
                <ul>
                  <li className="h5">Challenges</li>
                  <li className="h5">Solutions</li>
                  <li className="h5">Results</li>
                </ul>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 ">
                <div data-aos="flip-left">
                  <img src={mission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Challenges</h5>

                  <h6 className="">
                    Dr. Pawan Sthapak, a highly skilled medical practitioner
                    specializing in orthopedics, was facing challenges with low
                    visibility and limited engagement on social media platforms.
                    Despite being an expert in his field, his website was not
                    reaching its full potential in terms of online presence and
                    patient outreach.
                  </h6>
                </div>
              </div>
              <div className="col-md-12  mt-5">
                <div data-aos="flip-left">
                  <img src={vission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Solutions</h5>
                  <h6>
                    To address this challenge, a comprehensive SMO strategy was
                    implemented:
                  </h6>
                  <p className="par-text">
                    Profile Optimization: All social media profiles associated
                    with Dr. Pawan Sthapak were optimized with professional
                    photos, consistent branding, and comprehensive bios.
                  </p>
                  <p>
                    Content Strategy: A content calendar was established,
                    featuring a variety of content, including informative
                    articles, patient success stories, health tips, and visually
                    engaging content such as infographics and videos.
                  </p>
                  <p>
                    Engagement and Interaction: Dr. Pawan Sthapak and his team
                    actively engaged with the audience by responding to
                    inquiries, comments, and direct messages in a timely and
                    informative manner.
                  </p>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div data-aos="flip-left">
                  <img src={value} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Results</h5>
                  <h6>
                    {" "}
                    The implementation of the SMO strategy resulted in
                    significant improvements:
                  </h6>
                  <p className="par-text">
                    Increased Follower Base: The number of followers across all
                    social media platforms saw a steady increase, with a 50%
                    growth in the first six months. Enhanced Engagement: The
                    engagement rate, measured by likes, comments, and shares,
                    improved by 60% as a result of consistent posting and timely
                    responses to inquiries.
                  </p>
                  <p>
                    Website Traffic: The website http://drpawansthapak.com/
                    experienced a 40% increase in traffic from social media
                    sources.
                  </p>
                  <p>
                    Reputation Growth: Dr. Pawan Sthapak's reputation as a
                    trusted orthopedic expert was solidified through active
                    engagement and content sharing, leading to an increase in
                    patient inquiries and appointments.
                  </p>
                  <p>
                    Educational Resource: Social media became a valuable source
                    of orthopedic information for patients, with a growing
                    audience seeking advice from Dr. Pawan Sthapak. .
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-md-12">
              <div data-aos="fade-right">
                <img
                  id="clientpages1"
                  src={karam}
                  style={{ animation: "fadeIn 15s;" }}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div data-aos="fade-left">
                <h3>Karamchand Studio</h3>
                <text>
                  Meet Karamchand Studio, an illustrious photography heaven,
                  where the art of freezing life's cherished moments finds its
                  true essence. Specializing in the art of wedding photography,
                  pre-wedding shoots, birthday party captures, and heart warming
                  family photo sessions, we invite you to experience the magic
                  of memories through our lens.
                </text>
                <br />
                <br />
                <ul>
                  <li className="h5">Challenges</li>
                  <li className="h5">Solutions</li>
                  <li className="h5">Results</li>
                </ul>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 ">
                <div data-aos="flip-left">
                  <img src={mission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Challenges</h5>

                  <h6 className="">
                    Karamchand Studio, a prominent photography studio
                    specializing in wedding photography, pre-wedding shoots,
                    birthday party shoots, family photo shoots, and claiming to
                    be the "best studio," faced the challenge of low online
                    visibility. They wanted to improve their local presence to
                    attract more customers.
                  </h6>
                </div>
              </div>
              <div className="col-md-12  mt-5">
                <div data-aos="flip-left">
                  <img src={vission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Solutions</h5>
                  <h6>
                    To address this challenge, a comprehensive Google My
                    Business (GMB) optimization strategy was implemented:
                  </h6>
                  <p className="par-text">
                    Claim and Verify GMB Listing: The first step was to claim
                    and verify their GMB listing if not already done. This
                    helped ensure that they had control over the information
                    presented on Google.
                  </p>
                  <p>
                    Profile Completeness: The GMB profile was filled out
                    completely with accurate information, including the business
                    name, address, phone number, website link, business hours,
                    and a brief description of services offered.
                  </p>
                  <p>
                    High-Quality Photos: High-resolution photos showcasing their
                    portfolio, studio, and staff members were uploaded to create
                    an appealing visual representation of their services.
                  </p>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div data-aos="flip-left">
                  <img src={value} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Results</h5>
                  <h6>
                    {" "}
                    The GMB optimization strategy led to significant
                    improvements for Karamchand Studio:
                  </h6>
                  <p className="par-text">
                    Increased Visibility: The business became more visible in
                    local search results, attracting potential clients looking
                    for photography services in the area.
                  </p>
                  <p>
                    Improved Click-Through Rate: An enhanced GMB listing with
                    attractive photos and compelling posts led to a higher
                    click-through rate to their website. Positive Reviews: By
                    encouraging satisfied customers to leave reviews and
                    promptly responding to feedback, the studio accumulated
                    positive reviews that improved their reputation.
                  </p>
                  <p>
                    Positive Reviews: By encouraging satisfied customers to
                    leave reviews and promptly responding to feedback, the
                    studio accumulated positive reviews that improved their
                    reputation.
                  </p>
                  <p>
                    Customer Engagement: Regular posts, updates, and the Q&A
                    section increased customer engagement and provided valuable
                    information to potential clients. Local Authority:
                    Karamchand Studio established itself as a local authority in
                    photography services, especially for weddings, pre-wedding
                    shoots, and birthday events.
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <Footer />
      </Wrapper>
    </>
  );
}

export default Case_Studies;
const Wrapper = styled.div`
  #cont7 {
    height: 25rem;

    @media screen and (max-width: 768px) {
      height: 45rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 42rem;
    }
  }
  #row67 {
    margin-left: 8rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: 3.5rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: -1rem;
    }
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    margin-bottom: 1.5rem;
    margin-top: 0rem;
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
  }
  .hdd {
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
  .hdd1 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;
