import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogImg from "../images/blog27img.jpg";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

export const Blog27Page = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
        Best Social Media Marketing Agency in Jabalpur | DOAGuru InfoSystems
        </title>
        <meta
          name="description"
          content="Looking for the best social media marketing agency in Jabalpur? DOAGuru InfoSystems provides expert social media strategies, SEO & brand growth solutions. Contact us now! "
        />
        <link rel="canonical" href="https://doaguru.com/Best-Social-Media-Marketing-Agency-in-Jabalpur-DOAGuru-InfoSystems" />


      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              Best Social Media Marketing Agency in Jabalpur - DOAGuru InfoSystems
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={BlogImg} alt="" className="frontimg rounded m-4" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                    In today’s digital era, having a strong online presence is essential for any business. If you are looking for the <Link to="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2">best social media marketing agency in Jabalpur</Link>, look no further than DOAGuru InfoSystems. We specialize in SEO, content marketing, digital marketing, brand awareness, social media management, and business growth strategies to help businesses establish a powerful online presence.
                  </p>
                  <br />
                </div>
              </div>


              {isShowMore && (
                <div>
                  <div className="col-lg-12 mt-3">
                    <div data-aos="fade-left">
                      <h5>Why Choose DOAGuru InfoSystems for Social Media Marketing?</h5>
                      <p>At DOAGuru InfoSystems, we understand that social media marketing is more than just posting content; it’s about creating an engaging digital experience that drives customer interaction and conversions. Here’s why we are the top choice for businesses in Jabalpur:</p>
                      <ul>
                        <li><strong>Expertise in Social Media Management:</strong> We handle social media platforms like Facebook, Instagram, LinkedIn, Twitter, and YouTube, ensuring your brand reaches the right audience with the right content.</li>
                        <li><strong>Result-Driven Digital Marketing Strategies:</strong> Our data-driven approach ensures that our campaigns boost engagement, increase conversions, and generate high ROI.</li>
                        <li><strong>SEO and Content Marketing for Maximum Reach:</strong> We integrate SEO strategies with high-quality content marketing to enhance your visibility on search engines.</li>
                        <li><strong>Lead Generation and Brand Awareness:</strong> Our team focuses on customer engagement and lead generation to help your business grow exponentially.</li>
                        <li><strong>Web Development and Business Growth Solutions:</strong> Apart from social media marketing, we offer website development, e-commerce solutions, and tech innovation to enhance your digital presence.</li>
                        <li><strong><Link href="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2"> Best Digital Marketing Agency in Jabalpur: </Link> </strong>  Proven track record of success in digital marketing. Get ranked higher on search engines with our expert SEO strategies.</li>
                        <li><strong>Best Digital Marketing Firm in Jabalpur:</strong> Comprehensive solutions for all your marketing needs. Trusted by businesses for effective and result-driven SEO services.</li>
                        <li><strong>Best SEO Agency in Jabalpur:</strong> Get ranked higher on search engines with our expert SEO strategies. Proven track record of success in digital marketing.</li>
                      </ul>
                      <hr />
                      <h5 className="h5">Our Social Media Marketing Services</h5>
                      <ul>
                        <li>Social Media Strategy Development</li>
                        <li>Content Creation and Scheduling</li>
                        <li>Paid Advertising Campaigns (Facebook Ads, Instagram Ads, LinkedIn Ads, etc.)</li>
                        <li>Influencer Marketing</li>
                        <li>Analytics and Performance Tracking</li>
                        <li>Reputation Management</li>
                        <li>Video Marketing and Graphic Design</li>
                      </ul>
                      <hr />
                      <h5 className="h5">Our Clients’ Success Stories</h5>
                      <p>Many businesses in Jabalpur and across India have trusted <Link href="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2"> DOAGuru InfoSystems</Link> to elevate their digital marketing efforts. Our marketing strategies have increased engagement, generated leads, and improved online sales for several brands.</p>
                      <hr />
                      <h5 className="h5">FAQs</h5>
                      <ul>
                        <li><strong>What makes DOAGuru the best social media marketing agency in Jabalpur?</strong> We offer customized digital marketing strategies, expert SEO, and data-driven campaigns to ensure the highest engagement and ROI.</li>
                        <li><strong>Which platforms do you manage for social media marketing?</strong> We manage Facebook, Instagram, LinkedIn, Twitter, YouTube, and other social media platforms as per your business needs.</li>
                        <li><strong>How can social media marketing benefit my business?</strong> It helps in brand awareness, customer engagement, lead generation, and increased sales through targeted marketing.</li>
                        <li><strong>Do you offer paid advertising services?</strong> Yes, we specialize in Facebook Ads, Instagram Ads, Google Ads, and LinkedIn Ads for optimal brand reach and conversions.</li>
                        <li><strong>How can I get started with your services?</strong> Simply visit our website
                          <Link href="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2"> www.doaguru.com </Link>, give us a call at 7440992424, or visit our office in Jabalpur for a consultation.</li>
                      </ul>
                    </div>
                    <hr />
                  </div>
                </div>



              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}


const Wrapper = styled.div`
  button {
    background-color: #fe7604;
    
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li{
    margin-bottom: 0.5rem;
    
  }
  ul{
    list-style  : none;
    
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft{
    margin-left: 2rem;
  }
`;
