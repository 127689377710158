import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import axios from "axios";

const UserSign = () => {
  const [formData, setFormData] = useState({
    email: "",
    mobile_no: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await axios.post(
        "https://doaguru.com/api/auth/register",
        formData
      );
      setSuccessMessage(response.data.message);
      setFormData({ email: "", mobile_no: "", password: "" });
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  return (
    <Wrapper>
      <div className="main">
        <div className="container-fluid">
          <div className="row flex-nowrap">
            <div className="col-lg-1 col-1 p-0">
              <Sidebar />
            </div>
            <div className="col-lg-11 col-11 ps-0">
              <div className="container">
                <div className="row text-center">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="d-flex flex-column justify-content-center align-item-center text-center pt-4">
                      <h3>Welcome To DOAGuru InfoSystems</h3>
                      <br />
                      <h3 className="fw-bold">User Register Page</h3>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <form
                        onSubmit={handleSubmit}
                        className="shadow-sm p-3 bg-body rounded mt-5"
                      >
                        <h4 className="p-3">User Register For Portfolio</h4>
                        <div data-mdb-input-init class="form-outline mb-4">
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            id="form2Example1"
                            class="form-control"
                            placeholder="Enter user email address"
                          />
                          <label class="form-label" for="form2Example1">
                            Email address
                          </label>
                        </div>

                        <div data-mdb-input-init class="form-outline mb-4">
                          <input
                            type="tel"
                            name="mobile_no"
                            value={formData.mobile_no}
                            onChange={handleChange}
                            id="form2Example1"
                            class="form-control"
                            placeholder="Enter user mobile number"
                            minLength={10}
                            maxLength={10}
                          />
                          <label class="form-label" for="form2Example1">
                            Mobile number
                          </label>
                        </div>

                        <div data-mdb-input-init class="form-outline mb-4">
                          <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            id="form2Example2"
                            class="form-control"
                            placeholder="Enter Password min length 5"
                          />
                          <label class="form-label" for="form2Example2">
                            Password
                          </label>
                        </div>

                        <button
                          data-mdb-ripple-init
                          type="submit"
                          class="btn btn-primary btn-block mb-4"
                        >
                          Sign in
                        </button>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                        {successMessage && (
                          <p style={{ color: "green" }}>{successMessage}</p>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default UserSign;

const Wrapper = styled.div`
  overflow-x: hidden;
  .sc-jiaSJS {
    @media (min-width: 1024px) {
      width: 64rem;
    }
  }
  .main {
    height: 100%;
    background-color: #e6ecf1;
  }
  .chart {
    background-color: white;
    border-radius: 5px;
  }
  .blDkbe #sidebar {
    width: 100%;
    height: 54rem;
    background-color: #004aad;
  }
  h3 {
    font-family: "Poppins", sans-serif;
    font-size: 2.5rem;
  }
  p {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    margin: 0 0 8px;
  }
  .appointTable {
    @media screen and (min-width: 768px) and (max-width: 850px) {
      width: 44rem;
      margin-left: 1.5rem;
    }
    @media screen and (min-width: 851px) and (max-width: 1024px) {
      width: 58rem;
      margin-left: 1.1rem;
    }
  }
`;
