import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "./page/Homepages/Homepage";
import About from "./page/About";
import Career from "./page/Career";
import Contact from "./page/Contact";
import Faq from "./page/Faq";
import Portfolio from "./page/Portfolio";
import DigitalMarketing from "./components/Service/DigitalMarketing";
import Graphic from "./components/Service/Graphic";
import Mobiledevelopment from "./components/Service/Mobiledevolopment";
import Ppc from "./components/Service/Ppc";
import SEO from "./components/Service/SEO";
import Smm from "./components/Service/Smm";
import Web from "./components/Service/Web";
import Service from "./page/Homepages/Service";
import Blogs from "./page/Blogs";
import Case_Studies from "./page/Case_Studies";
import Sitemap from "./page/Sitepmap";
import Dentalguru from "./page/Dentalguru";
import Blog1 from "./components/blogs/Blog1";
import Blog2 from "./components/blogs/Blog2";
import Blog3 from "./components/blogs/Blog3";
import Blog4 from "./components/blogs/Blog4";
import Blog5 from "./components/blogs/Blog5";
import Pr_Service from "./components/Service/Pr_Service";
import Blog6 from "./components/blogs/Blog6";
import Terms from "./components/Terms&Condition/Terms";
import Privacy from "./components/Terms&Condition/Privacy";
import Refund from "./components/Terms&Condition/Refund";
import Blog7 from "./components/blogs/Blog7";
import Blog8 from "./components/blogs/Blog8";
import Blog9 from "./components/blogs/Blog9";
import Webbased from "./page/Portfoliov1/Webbased";
import Animation from "./page/Portfoliov1/Animation";
import Softwares from "./page/Portfoliov1/Softwares";
import Smosmm from "./page/Portfoliov1/Smosmm";
import F404page from "./page/F404page";
import { Axios } from "axios";
import CareerAdmin from "./AdminDashBoard/CareerAdmin";
import ContactAdmin from "./AdminDashBoard/ContactAdmin";
import UserSign from "./AdminDashBoard/UserSign";
import AdminLogin from "./AdminDashBoard/AdminLogin";
import { useSelector } from "react-redux";
import Blog10 from "./components/blogs/Blog10";
import Blog11Page from "./components/blogs/Blog11";
import Blog12Page from "./components/blogs/Blog12";

import ModalComponent from "./components/ModalComponent";
import { useLocation } from "react-router-dom";
import MiniDentalguru from "./page/MiniDentalGuru";
import { QuotationGenerator } from "./page/QuotationGenerator";
import { DGCrm } from "./page/DGCRM";
import Blog13Page from "./components/blogs/Blog13";
import InitiateCheckoutPage from "./page/PaymentGatewayPages/InitiateCheckoutPage";
import AddToCartPage from "./page/PaymentGatewayPages/AddToCartPage";
import PaymentPage from "./page/PaymentGatewayPages/PaymentPage";
import Blog14Page from "./components/blogs/Blog14";
import Blog15Page from "./components/blogs/Blog15";
import Blog16Page from "./components/blogs/Blog16";
import Blog17Page from "./components/blogs/Blog17";
import Blog18Page from "./components/blogs/Blog18";
import Blog19Page from "./components/blogs/Blog19";
import Blog20Page from "./components/blogs/Blog20";
import { Blog21Page } from "./components/blogs/Blog21";
import { Blog22Page } from "./components/blogs/Blog22";
import { Blog23Page } from "./components/blogs/Blog23";
import { Blog24Page } from "./components/blogs/Blog24";
import Whatsappicon from "./page/Whatsappicon";
import { Blog25Page } from "./components/blogs/Blog25";
import Blog26Page from "./components/blogs/Blog26";
import { Blog27Page } from "./components/blogs/Blog27";
import {ChatBotComponent} from "./chatBot/chatBotComponents";
import { Blog28Page } from "./components/blogs/Blog28";
import { Blog29Page } from "./components/blogs/Blog29";
import TextEditor from "./components/Editor";
import { Blog30Page } from "./components/blogs/Blog30";
import { Blog31Page } from "./components/blogs/Blog31";
import { Blog32Page } from "./components/blogs/Blog32";
import { Blog33Page } from "./components/blogs/Blog33";
import { Blog34Page } from "./components/blogs/Blog34";
import { Blog35Page } from "./components/blogs/Blog35";
import AdminBlogList from "./AdminDashBoard/AdminBlogList";
import AdminBlogForm from "./AdminDashBoard/AdminBlogForm";
import BlogList from "./components/blogs/BlogList";
import BlogDetail from "./components/blogs/BlogDetail";


function App() {
  const user = useSelector((state) => state.user);
  let [toggleBtn, setToggelBtn] = useState(true);

  let protecteApi = async (z) => {
    try {
      let data = await Axios.post(
        `https://doaguru.com/api/auth/protected/${z}`
      );
      let { user } = data.data;
      if (user) {
        setToggelBtn(false);
      } else {
        setToggelBtn(true);
      }
    } catch (error) {
      console.log("err protected");
    }
  };

  let FechAuth = () => {
    let token = localStorage.getItem("token");
    if (token?.length > 0) {
      protecteApi(token);
    } else {
      protecteApi("null");
    }
  };

  useEffect(() => {
    FechAuth();
  }, []);

  // modal

  const { pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    localStorage.setItem("formSubmitted", "true");
  };

  const handleShow = () => setShowModal(true);

  useEffect(() => {
    // Show modal only on the Homepage
    if (pathname === "/") {
      const timer = setTimeout(() => {
        handleShow();
      }, 5000);

      return () => clearTimeout(timer); 
    } else {
      setShowModal(false); 
    }
  }, [pathname]);



  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top whenever component renders
  }, []);

  const handleFormSubmit = () => {
    handleClose(); // Close the modal
  };

  return (
    <>
      {showModal && (
    

        <ModalComponent
          show={showModal}
          handleClose={handleClose}
          onSubmit={handleFormSubmit}
          />
       
      )}

      <div style={{ overflow: "hidden" }}>
        <Routes>
          <Route path="/" Component={Homepage} />
          <Route path="/about_us" Component={About} />
          <Route path="/career" Component={Career} />
          <Route path="/contact_us" Component={Contact} />
          <Route path="/faq" Component={Faq} />
          <Route
            path="/Best-Digital-Marketing-Company-Jabalpur"
            
            Component={DigitalMarketing}
          />
          <Route 
          path="/Best-Software-Company-In-Jabalpur" 
          Component={Webbased} />
          <Route path="/graphic-designing" Component={Graphic} />
          <Route path="/mobile-development" Component={Mobiledevelopment} />
          
          <Route path="/ppc" Component={Ppc} />
          <Route path="/Best-SEO-Company-in-Jabalpur" Component={SEO} />
          <Route
            path="/Best-social-media-marketing-company-in-Jabalpur-India"
            Component={Smm}
          />
          <Route path="/Top-Software-Company-in-Jabalpur" Component={Web} />
          <Route path="/service" Component={Service} />
          <Route path="/blogs" Component={Blogs} />
          <Route
            path="/10-Essential-Skills-Every-Digital-Marketer-Needs-to-Know"
            Component={Blog1}
          />
          <Route
            path="/Optimizing-Your-Business-Because-Google-Can't-Read-Your-Mind-Yet"
            Component={Blog2}
          />
          <Route
            path="/Email-Marketing-Because-Your-Inbox-Was-Feeling-Lonely"
            Component={Blog3}
          />
          <Route
            path="/Unveiling-the-Secrets-of-the-Best-Graphic-Designing-Company-for-Your-Creative-Dreams!"
            Component={Blog4}
          />
          <Route
            path="/Best-Graphic-Designing-Company-in-Jabalpur-Elevate-Your-Brand-Presence"
            Component={Blog5}
          />
          <Route
            path="/Cybersecurity-Essentials-Protecting-Your-Business-in-a-Digital-Age"
            Component={Blog6}
          />
          <Route
            path="/The-Future-of-SEO:-Predictions-and-Strategies-for-2024"
            Component={Blog7}
          />
          <Route
            path="/Performance-Marketing-vs-Digital-Marketing:-Understanding-the-Key-Differences"
            Component={Blog8}
          />
          <Route path="/Best-SEO-company-in-Madhya-pradesh" Component={Blog9} />
          <Route
            path="/The-Best-IT-Company-in-Jabalpur-DOAGuru-Infosystems"
            Component={Blog10}
          />
          <Route
            path="/Best-Software-Development-Company-in-Jabalpur"
            Component={Blog11Page}
          />
          <Route
            path="/Best-Digital-Marketing-Company-in-Madhya-Pradesh"
            Component={Blog12Page}
          />
          <Route
            path="/Ultimate-Dental-Practice-Management-Software"
            Component={Blog13Page}
          />
          <Route
            path="/ai-in-digital-marketing"
            Component={Blog14Page}
          />
          <Route
            path="/Can-You-Get-a-Digital-Marketing-Job-Without-Any-Experience-?"
            Component={Blog15Page}
          />
          <Route
            path="/What-is-Digital-Marketing-A-Comprehensive-Guide-by-DOAGuru-InfoSystems"
            Component={Blog16Page}
          />
          <Route
            path="/10-Skills-You-Need-to-Become-a-Digital-Marketing-Manager"
            Component={Blog17Page}
          />
          <Route
            path="/How-to-Create-an-AI-Digital-Marketing-Strategy?"
            Component={Blog18Page}
          />
          <Route
            path="/Digital-Marketing-Career-Opportunities-in-2025"
            Component={Blog19Page}
          />
          <Route
            path="/DeepSeek-The-New-AI-Challenger-to-ChatGPT"
            Component={Blog20Page}
          />
          <Route
            path="/Digital-Marketing-Agency-in-Jabalpur"
            Component={Blog21Page}
          />
           <Route
            path="/Top-Digital-Marketing-Tools-for-Success-in-2025"
            Component={Blog22Page}
          />
          <Route path="/Influencer-Marketing-The-Ultimate-Guide" element={<Blog23Page />} />
          <Route path="/Should-You-Choose-a-Career-in-Digital-Marketing?" element ={<Blog24Page />} />
          <Route path="/How-to-Do-a-Competitive-Analysis-in-Digital-Marketing" element={<Blog25Page />} />
          <Route path="/Best-Mobile-App-Development-in-Jabalpur" element={<Blog26Page />} />
          <Route path="/Best-Social-Media-Marketing-Agency-in-Jabalpur-DOAGuru-InfoSystems" element={<Blog27Page />} />
          <Route
           path="/Best-Digital-Marketing-Company-in-Indore"
            element={<Blog28Page />} />
          <Route 
          path="/Best-Digital-Marketing-Company-in-Bhopal" 
          element={<Blog29Page />} />
          <Route 
          path="/Best-SEO-Company-in-Bhopal" 
          element={<Blog30Page />} />
          <Route 
          path="/Best-SEO-Company-in-Indore" 
          element={<Blog31Page />} />
          <Route 
          path="/Best-Website-Development-Company-in-Indore" 
          element={<Blog32Page />} />
          <Route 
          path="/Best-Website-Development-Company-in-Bhopal" 
          element={<Blog33Page />} />
          <Route 
          path="/Best-SEO-Services-Companies-in-India" 
          element={<Blog34Page />} />
          <Route 
          path="/seo-company-in-India" 
            element={<Blog35Page />} />
 
          {/* <Route
            path="/Best-TextEditor"
            element={<TextEditor />} /> */}

          <Route path="/blogslist" element={<BlogList />} />
          <Route path="/blogslist/:slug" element={<BlogDetail />} />



          <Route path="/case-studies" Component={Case_Studies} />
          <Route path="/sitemap" Component={Sitemap} />
          <Route path="/dental-guru" Component={Dentalguru} />
          <Route path="/mini-dental-guru" Component={MiniDentalguru} />
          <Route path="/Quotation Generator Management System" Component={QuotationGenerator} />
          <Route path="/Doaguru CRM" Component={DGCrm} />
          <Route path="/pr-service" Component={Pr_Service} />
          <Route path="/terms-&-condition" Component={Terms} />
          <Route path="/privacy-policy" Component={Privacy} />
          <Route path="/refund-&-cancel" Component={Refund} />

          {/*------------- Payment Gateway Route ---------------*/}
          <Route path="/InitiateCheckoutPage" Component={InitiateCheckoutPage} />
          <Route path="/All-products-Page" Component={AddToCartPage} />
          <Route path="/payment" element={<PaymentPage />} />
          
          {/*------------- Payment Gateway Route ---------------*/}

          {/*------------- portfolio ---------------*/}
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="*" element={<F404page />} />
          {toggleBtn ? (
            <>
              <Route path="/webbased" element={<Webbased />} />
              <Route path="/animation" element={<Animation />} />
              <Route path="/software" element={<Softwares />} />
              <Route path="/smosmm" element={<Smosmm />} />
            </>
          ) : null}
          {/*------------- portfolio-----------------*/}

          {/* *********** Admin Part Start ********* */}
          <Route path="/Admin-Login" element={<AdminLogin />} />
          <Route
            path="/Admin-Page-Career"
            element={
              user?.currentUser === null ? <AdminLogin /> : <CareerAdmin />
            }
          />
          <Route
            path="/Admin-Page-Contact"
            element={
              user?.currentUser === null ? <AdminLogin /> : <ContactAdmin />
            }
          />
          <Route
            path="/Admin-Page-UserSign"
            element={user?.currentUser === null ? <AdminLogin /> : <UserSign />}
          />

            <Route 
            path="/AdminAdd-blogs"
            element={user?.currentUser === null ? <AdminLogin /> : <AdminBlogList />} />
            <Route 
            path="blogs/newAdd" 
            element={user?.currentUser === null ? <AdminLogin /> : <AdminBlogForm />} />
            <Route 
            path="Updateblog/:id" 
            element={user?.currentUser === null ? <AdminLogin /> : <AdminBlogForm />} />

          {/* ****Admin Part End****** */}

        </Routes>

            {/* Chat Bot Icon  */}
            <div>

        <ChatBotComponent/>
            </div>


        {/* Whatsapp icon button */}
        <Whatsappicon />
      </div>
    </>
  );
}

export default App;
