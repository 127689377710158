

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Finalnav from '../../page/Homepages/Finalnav';
import Footer from '../../page/Homepages/Footer';
import Breadcrumb from '../Breadcrumb';

const BlogDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://doaguru.com/api/blogs/${slug}`);
        setBlog(response.data);
        setLoading(false);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          navigate('/blogslist'); // Redirect to blog list if not found
        } else {
          setError('Failed to load blog');
        }
        setLoading(false);
      }
    };

    fetchBlog();
    window.scrollTo(0, 0);
  }, [slug, navigate]);

  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  if (loading) return <div className="container text-center my-5"><h3>Loading...</h3></div>;
  if (error) return <div className="container text-center my-5"><h3>{error}</h3></div>;
  if (!blog) return null;

  // Parse the content to separate intro and main content
  // This is simplified - you may need to adjust based on your actual content structure
  let introContent = '';
  let mainContent = '';
  
  // Simple approach: first paragraph is intro, rest is main content
  // For better control, you might want to add special markers in your CMS
  const contentWithoutTags = blog.content.replace(/<[^>]*>/g, ' ');
  const firstParagraphEnd = contentWithoutTags.indexOf('\n\n');
  
  if (firstParagraphEnd > 0) {
    introContent = contentWithoutTags.substring(0, firstParagraphEnd);
    mainContent = blog.content;
  } else {
    // If no clear paragraph break, use the first 150 characters as intro
    introContent = contentWithoutTags.substring(0, 200) + '...';
    mainContent = blog.content;
  }

  return (
    <>
      <Finalnav />
      <Helmet>
        <title>{blog.title} | DOAGuru InfoSystems</title>
        <meta name="description" content={blog.meta_description} />
        <meta name="keywords" content={blog.meta_keywords} />
        <link rel="canonical" href={`https://doaguru.com/blogslist/${blog.slug}`} />
      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              {blog.title}
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
            <div className="text-center blog-meta">
              <span className="blog-date">
                Published: {new Date(blog.published_date).toLocaleDateString()}
              </span>
              {blog.updated_date !== blog.published_date && (
                <span className="blog-updated ms-3">
                  Updated: {new Date(blog.updated_date).toLocaleDateString()}
                </span>
              )}
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  {blog.image_url && (
                     <img 
                     src={`https://doaguru.com/${blog.image_url || 'uploads/1743677335230-704855352.jpg'}`} 
                     alt={blog.title || 'Default Image'}  
                     className="frontimg rounded m-4" 
                   />
                  )}
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  {/* ye suru me jo intro karega us ke liye he  */}
                  <p dangerouslySetInnerHTML={{ __html: introContent }}></p>
                  <br />
                </div>
              </div>

              {isShowMore && (
                <div className="col-lg-12 mt-3">
                  <div data-aos="fade-left">
                    {/* or ye line pure content ke liye he samjhe */}
                    <div 
                      className="blog-content"
                      dangerouslySetInnerHTML={{ __html: mainContent }}
                    />
                  </div>
                  <hr />
                </div>
              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogslist"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

export default BlogDetail;

const Wrapper = styled.div`
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li{
    margin-bottom: 0.5rem;
  }
  ul{
    list-style: none;
  }
  .frontimg {
    width: 20rem;
    // height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
      margin-top: -4rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
      margin-top: -4rem;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft{
    margin-left: 2rem;
  }
  
  .blog-meta {
    color: #5a626d;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  
  .blog-content {
    color: #5a626d;
    text-align: justify;
    
    h5 {
      font-size: 20px;
      color: #4034db;
      margin-top: 2rem;
    }
    
    ul {
      list-style: none;
      padding-left: 1rem;
    }
    
    li {
      margin-bottom: 0.5rem;
    }
  }
`;