import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogImg from "../images/Blog20img.jpg";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

export default function Blog20Page() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
          DeepSeek: The New AI Challenger to ChatGPT
        </title>
        <meta
          name="description"
          content="Discover DeepSeek, a powerful open-source AI model from China, designed as a cost-effective alternative to ChatGPT. Learn about its development, features, advantages, and why it's gaining popularity in the AI world."
        />
        <link rel="canonical" href="https://doaguru.com/DeepSeek-The-New-AI-Challenger-to-ChatGPT" />


      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              DeepSeek: The New AI Challenger to ChatGPT
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={BlogImg} alt="" className="frontimg rounded m-4" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                    DeepSeek is a Chinese AI model that has gained attention for being an open-source alternative to ChatGPT. It is designed to be cost-effective, powerful, and capable of handling various language tasks.

                  </p>
                  <br />

                </div>
              </div>


              {isShowMore && (


                <div>
                  <div className="col-lg-12 mt-3">
                    <div data-aos="fade-left">
                      <h5>What is DeepSeek?</h5>

                      <ul>
                        <li>DeepSeek is an artificial intelligence model designed for natural language processing, similar to ChatGPT.
                        </li>
                        <li>It was developed as an open-source model, meaning its code and algorithms are accessible to the public.
                        </li>
                        <li>The AI model is focused on delivering efficient and cost-effective solutions for businesses and users.
                        </li>

                      </ul>
                      <hr />
                      <h5 className="h5">Who Created DeepSeek?</h5>
                      <p className="mleft">DeepSeek was created by a Chinese AI company based in Hangzhou, Zhejiang, China.<br />
                        The project is funded by High-Flyer, a Chinese hedge fund.<br />
                        The CEO of DeepSeek is Liang Wenfeng, who has experience in AI development.</p>
                      <hr />
                      <h5 className="h5">How Much Did DeepSeek Cost to Develop?</h5>
                      <p className="mleft">DeepSeek was built with an estimated cost of $6 million.<br />
                        This is significantly lower than the cost of training OpenAI’s GPT-4, which is estimated at $100 million.<br />
                        The lower cost is due to optimized training techniques and the use of less expensive computing resources.</p>
                      <hr />
                      <h5 className="h5">Why is DeepSeek Better Than ChatGPT?</h5>
                      <ul>
                        <li className="mleft"><strong>Open-Source Model:</strong> DeepSeek is open-source, meaning developers can freely access, modify, and improve its AI models. In contrast, ChatGPT is proprietary, and OpenAI restricts access to its core algorithms.</li>
                        <li className="mleft"><strong>Lower Development & Operational Cost:</strong> Training and maintaining DeepSeek is much cheaper than ChatGPT. Businesses can use and deploy DeepSeek without paying expensive API fees.</li>
                        <li className="mleft"><strong>Faster & Task-Specific Optimization:</strong> DeepSeek allows developers to fine-tune it for specific industries and tasks, whereas ChatGPT is a general-purpose AI.</li>
                        <li className="mleft"><strong>Better for AI Research & Innovation:</strong> Since DeepSeek is open-source, researchers can study and improve the model transparently.</li>
                        <li className="mleft"><strong>Compliance with Local Regulations:</strong> DeepSeek follows Chinese government AI policies, making it a safer choice for companies operating in China.</li>
                      </ul>
                      <hr />
                      <h5 className="h5">Features of DeepSeek</h5>
                      <ul>
                        <li className="mleft"><strong>Open-Source:</strong> Unlike ChatGPT, which is proprietary, DeepSeek allows developers to use, modify, and improve its models.</li>
                        <li className="mleft"><strong>Optimized for Specific Tasks:</strong> It focuses on modular AI, meaning it can be fine-tuned for specific applications.</li>
                        <li className="mleft"><strong>Low Cost & Efficient Training:</strong> Uses fewer computational resources while maintaining high performance.</li>
                        <li className="mleft"><strong>Supports Multiple Languages:</strong> Designed to work with various languages, especially those relevant to Chinese users.</li>
                      </ul>
                      <hr />
                      <h5 className="h5">Why is DeepSeek Gaining Popularity?</h5>
                      <ul>
                        <li className="mleft"><strong>Free & Open-Source:</strong> Many developers prefer open-source models over closed systems like ChatGPT.</li>
                        <li className="mleft"><strong>Cost-Effective:</strong> Companies can train and deploy AI without the huge investment required for proprietary models.</li>
                        <li className="mleft"><strong>China’s AI Expansion:</strong> DeepSeek aligns with China's goal to develop independent AI technology and reduce reliance on Western models.</li>
                        <li className="mleft"><strong>Performance vs. Cost:</strong> Offers strong performance at a fraction of the cost of similar AI models.</li>
                      </ul>
                      <hr />
                      <h5 className="h5">Limitations of DeepSeek</h5>
                      <ul>
                        <li className="mleft"><strong>Political Restrictions:</strong> Since it is developed in China, it follows government policies and may restrict certain topics.</li>
                        <li className="mleft"><strong>Less Training Data than ChatGPT:</strong> Due to its lower budget, it might not be as well-rounded as GPT-4.</li>
                        <li className="mleft"><strong>Limited Global Reach:</strong> While powerful in China, it is still growing in popularity worldwide.</li>
                      </ul>
                      <hr />
                      <p>
                        Ready to excel in <Link to="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2 ">digital marketing</Link>? Stay informed, keep learning, and adapt to the changing landscape.
                      </p>
                    </div>
                    <hr />
                  </div>
                </div>


              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}


const Wrapper = styled.div`
  button {
    background-color: #fe7604;
    
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li{
    margin-bottom: 0.5rem;
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft{
    margin-left: 2rem;
  }
`;
