import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Blog12 from "../images/Blog12.jpg";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

function Blog12Page() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
        Best Digital Marketing Company in Madhya Pradesh

        </title>
        <meta
          name="description"
          content="Discover DOAGuru InfoSystems, the Best Digital Marketing Company in Madhya Pradesh, offering top-notch SEO, social media, and online advertising services to boost your business growth. Contact us today!"
        />
        <link rel="canonical" href="https://doaguru.com/Best-Digital-Marketing-Company-in-Madhya-Pradesh" />


      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              The Best Digital Marketing Company in Madhya Pradesh

            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={Blog12} alt="" className="frontimg rounded m-4" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                  At DOAGuru InfoSystems, we pride ourselves on being
                  <Link to="" target="_blank" className="text-decoration-none"> the best digital marketing company in Madhya Pradesh
                  </Link>. Our commitment to excellence, innovative strategies, and customer-centric approach make us the preferred choice for businesses looking to enhance their online presence and achieve remarkable growth.
                  </p>
                </div>
              </div>


              {isShowMore && (
                <p>
                  <h3>
                  Why Choose DOAGuru InfoSystems?
                  </h3>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>
                      Proven Track Record
                      </h5>
                      <p>
                      With years of experience in the digital marketing industry, DOAGuru InfoSystems has helped numerous businesses in Madhya Pradesh and beyond achieve their marketing goals. Our portfolio is a testament to our ability to deliver results that exceed expectations.

                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Comprehensive Digital Marketing Services
                      </h5>
                      <p>
                      We offer a full spectrum of digital marketing services tailored to meet the unique needs of your business. Our services include:

                      </p>
                      <ul>
                        <li> <b>Search Engine Optimization (SEO): </b>Improve your website’s visibility on search engines like Google to attract organic traffic and rank higher for relevant keywords.
                        </li>

                        <li><b>Pay-Per-Click (PPC) Advertising: </b>Drive immediate traffic and conversions with strategically crafted and managed PPC campaigns.
                        </li>

                        <li><b>Social Media Marketing: </b> Engage and grow your audience on platforms like Facebook, Instagram, Twitter, and LinkedIn with compelling content and targeted ads.

                        </li>

                        <li><b>Content Marketing:</b> Create and distribute relevant content to attract and retain a clearly defined audience, driving profitable customer action.
                        </li>
                        <li><b>Email Marketing:</b> Build and nurture customer relationships through personalised and effective email campaigns.
                        </li>
                        <li><b>Web Design and Development:</b> Develop a user-friendly, responsive, and aesthetically pleasing website that is the cornerstone of your digital presence.
                        </li>
                      </ul>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>
                      Customized Strategies
                      </h5>
                      <p>
                      At <Link to="https://doaguru.com/" target="_blank" className="text-decoration-none">DOAGuru InfoSystems</Link>, we understand that each business is unique. Our team of experts takes the time to understand your specific needs, goals, and target audience to create a customised digital marketing strategy that delivers optimal results.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                    
                      <h5>
                      Expert Team
                      </h5>
                      <p>
                      Our team of certified digital marketing professionals is equipped with the latest tools, techniques, and industry knowledge. We stay ahead of trends to ensure your business remains competitive in the ever-evolving digital landscape.
                      </p>
                      <h5>
                      Transparent Reporting
                      </h5>
                      <p>
                      We believe in transparency and accountability. Our clients receive detailed reports and insights into the performance of their digital marketing campaigns. We provide you with the data you need to make informed decisions and understand the value of our services.
                      </p>
                      <h5>
                      Customer Satisfaction
                      </h5>
                      <p>
                      Customer satisfaction is at the core of everything we do. We strive to build long-term client relationships by delivering exceptional service and support. Our dedicated account managers are always available to address your concerns and provide assistance whenever needed.
                      </p>
                      
                    </div>
                    <hr />
                  </div>
                  
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Client Success Stories</h3>
                      <p>
                      Here are some of the success stories of our clients who have benefited from our digital marketing expertise:
                      </p>
                      <ul>
                        <li className="h6">
                        School Management Website

                        </li>
                      </ul>
                      <p className="mleft">
                      This website we developed for a school to automate all school-related work, including admission, attendance, exams, results, timetables, admit cards, fee management, school staff management, library management, and lots of other features. 
                      </p>
                      <ul>
                        <li className="h6">
                        Prince Viraj Hotel
                        </li>
                      </ul>
                      <p className="mleft">
                      This is a website for doing a hotel business virtually. In this section, the administrator can manage hotels and rooms, and there is a restaurant section too, in which the restaurant system is managed.QR codes are also used in this software for restaurant management.
                      </p>
                      <ul>
                        <li className="h6">
                        Shadi Vivah Matrimonial Website
                        </li>
                      </ul>
                      <p className="mleft">
                      This is a matrimonial website that allows users to make their profile, view other members’ profiles, and find their match here on this website. This website helps in finding the perfect life partner.
                      </p>
                      
                      <p className="">
                      <Link to="https://doaguru.com/portfolio" target="_blank" className="link-offset-3-hover link-offset-2 ">Click Here</Link> to check our full portfolio.
                      </p>
                    </div>
                    <hr />
                    <h3>
                      Our Process
                    </h3>
                    <ul>
                      <li className="h6">
                        <b>Consultation </b>We start with a detailed consultation to understand your business, goals, and challenges.
                      </li>
                      <li >
                        <b>Strategy Development </b>Based on our analysis, we develop a customized digital marketing strategy.
                      </li>
                      <li className="h6">
                        <b>Implementation </b>Our team executes the strategy using best practices and cutting-edge tools.
                      </li>
                      <li className="h6">
                        <b>Monitoring and Optimization </b>We continuously monitor performance and make necessary adjustments to optimize results.
                      </li>
                      <li className="h6">
                        <b>Reporting</b>Regular reports are provided to keep you informed of progress and outcomes.
                      </li>
                    </ul>
                    <hr />
                    <h3>
                      Get Started with DOAGuru InfoSystems Today!
                    </h3>
                    <p>
                    We’re excited to help your business thrive in the digital world. Whether you have questions about our services, need a custom digital marketing strategy, or want to discuss how we can help you achieve your business goals, our team is here to assist you. <Link to="https://doaguru.com/contact_us" target="_blank" className="text-decoration-none">Reach out to us</Link>  using any of the methods below, and let’s start a conversation about your success.
                    </p>
                    <ul>
                      <li>
                      <b>Address:</b> 1815 Wright Town, Jabalpur, Madhya Pradesh, INDIA 482002
                      </li>
                      <li>
                      <b>Phone:</b> +917440992424
                      </li>
                      <li>
                      <b>Email:</b> info@doaguru.com
                      </li>
                      <li>
                      <b>Website:</b> https://doaguru.com/
                      </li>
                    </ul>
                    <p>
                    DOAGuru InfoSystems is committed to your success. Let us be your trusted partner in navigating the digital landscape and driving your business towards greater heights.
                    </p>
                  </div>
                </p>
              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Blog12Page;
const Wrapper = styled.div`
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li{
    margin-bottom: 0.5rem;
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft{
    margin-left: 2rem;
  }
`;
