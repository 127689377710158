import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogImg from "../images/blog33img.jpg";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

export const Blog33Page = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
          Best Website Development Company in Bhopal | DOAGuru InfoSystems
        </title>
        <meta
          name="description"
          content="Need a professional website? DOAGuru InfoSystems is the best website development company in Bhopal, offering custom, SEO-friendly, e-commerce, and responsive web solutions to help your business grow online. Contact us today! 
 "
        />
        <link rel="canonical" href="https://doaguru.com/Best-Website-Development-Company-in-Bhopal" />


      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              Best Website Development Company in Bhopal – DOAGuru InfoSystems

            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={BlogImg} alt="" className="frontimg rounded m-4" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                    In today’s digital era, having a robust online presence is no longer optional; it’s a necessity. If you’re searching for the <Link to="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2">best website development company in Bhopal</Link>, look no further than DOAGuru InfoSystems. Our team specializes in creating high-performance, SEO-friendly, and visually stunning websites tailored to your business needs. Whether you're a startup, a small business, or a large enterprise, we provide customized solutions that help you stand out in the digital landscape.
                  </p>
                  <br />
                </div>
              </div>


              {isShowMore && (
                <div>
                  <div className="col-lg-12 mt-3">
                    <div data-aos="fade-left">
                      <h5>Why Choose DOAGuru InfoSystems?</h5>
                      <ul>
                        <li><strong>Extensive Industry Experience:</strong> With years of experience in website development, we have successfully delivered projects across various industries, including healthcare, education, real estate, e-commerce, and more.</li>
                        <li><strong>Tailor-Made Website Solutions:</strong> We understand that every business has unique needs. Our team works closely with you to design and develop a website that aligns perfectly with your brand identity and business objectives.</li>
                        <li><strong>SEO-Optimized Websites for Higher Rankings:</strong> Our websites are built with SEO best practices, ensuring your business ranks higher on search engines and attracts more organic traffic.</li>
                        <li><strong>Advanced Technologies for Better Performance:</strong> We use cutting-edge web development technologies like React, Angular, Node.js, and Laravel to ensure fast-loading, secure, and scalable websites.</li>
                        <li><strong>Cost-Effective & Transparent Pricing:</strong> We offer high-quality website development services at competitive rates with no hidden charges. Get the best value for your investment.</li>
                        <li><strong>Ongoing Support & Maintenance:</strong> Our commitment doesn’t end with website launch. We provide continuous support, updates, and security maintenance to keep your website running smoothly.</li>
                      </ul>

                      <h5>Our Website Development Services</h5>
                      <ul>
                        <li><strong>Custom Website Development:</strong> We create unique, tailor-made websites designed specifically for your brand, offering seamless functionality and an engaging user experience.</li>
                        <li><strong>E-Commerce Website Development:</strong> We build fully functional online stores with secure payment gateways, inventory management, and an intuitive shopping experience to maximize sales.</li>
                        <li><strong>Mobile-Friendly & Responsive Web Design:</strong> Our websites are fully optimized for mobile devices, ensuring a smooth and engaging experience for users on smartphones and tablets.</li>
                        <li><strong>WordPress Website Development:</strong> We offer custom WordPress solutions for businesses looking for an easy-to-manage and scalable website platform.</li>
                        <li><strong>Web Application Development:</strong> From interactive dashboards to CRM systems, we develop web applications that enhance business productivity and streamline operations.</li>
                        <li><strong>SEO-Optimized Web Development:</strong> We incorporate SEO best practices into our web development process, ensuring your site ranks higher on search engines and attracts targeted traffic.</li>
                        <li><strong>Website Redesign & Maintenance:</strong> If your existing website isn’t performing well, we can redesign it for better aesthetics, functionality, and conversions. We also provide regular maintenance services.</li>
                      </ul>

                      <h5>Why Your Business Needs a Website in Bhopal</h5>
                      <ul>
                        <li><strong>Enhanced Credibility:</strong> A well-designed website makes your business look more professional and trustworthy.</li>
                        <li><strong>Increased Visibility:</strong> Rank higher on Google and get discovered by potential customers online.</li>
                        <li><strong>Better Customer Engagement:</strong> Provide valuable information and interact with customers 24/7.</li>
                        <li><strong>Higher Conversion Rates:</strong> Convert website visitors into paying customers with a well-optimized site.</li>
                        <li><strong>Competitive Advantage:</strong> Stay ahead of competitors with a feature-rich, modern website.</li>
                        <li><strong>Local & Global Reach:</strong> Expand your customer base beyond geographical limitations.</li>
                      </ul>

                      <h5>Latest Web Development Trends in Bhopal</h5>
                      <ul>
                        <li><strong>AI-Driven Chatbots:</strong> Improve customer support with smart AI chatbots.</li>
                        <li><strong>Progressive Web Apps (PWAs):</strong> Enhance user experience with fast, responsive web apps.</li>
                        <li><strong>Dark Mode UI:</strong> Provide an aesthetically pleasing, eye-friendly design option.</li>
                        <li><strong>Voice Search Optimization:</strong> Optimize your website for voice-based searches.</li>
                        <li><strong>Motion UI & Micro-Interactions:</strong> Engage users with interactive animations and effects.</li>
                      </ul>

                      <h5>FAQs</h5>
                      <ul>
                        <li><strong>What makes DOAGuru InfoSystems the best website development company in Bhopal?</strong><br />We offer cutting-edge technology, custom solutions, and SEO-optimized websites that help businesses grow online.</li>
                        <li><strong>How long does it take to develop a website?</strong><br />The timeline varies based on project complexity but typically ranges from 2 to 6 weeks.</li>
                        <li><strong>Do you offer SEO services with website development?</strong><br />Yes! We build SEO-friendly websites that rank higher and drive more traffic.</li>
                        <li><strong>What industries do you serve?</strong><br />We provide website development services across multiple industries, including healthcare, e-commerce, real estate, education, and more.</li>
                        <li><strong>How can I get started?</strong><br />Simply visit our website <Link href="https://doaguru.com/" target="_blank">DOAGuru Infosystems</Link> or call us to discuss your project.</li>
                      </ul>

                      <h5>Contact DOAGuru InfoSystems Today!</h5>
                      <p>Looking for a professional website development company in Bhopal? We create stunning, high-performing websites that drive results. Get in touch with us today and take your online presence to the next level! 🚀</p>
                    </div>
                    <hr />
                  </div>
                </div>




              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}


const Wrapper = styled.div`
  button {
    background-color: #fe7604;
    
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li{
    margin-bottom: 0.5rem;
    
  }
  ul{
    list-style  : none;
    
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft{
    margin-left: 2rem;
  }
`;
