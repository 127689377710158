import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Styled from "styled-components";
import { Helmet } from "react-helmet";
import { ChevronDown, Search } from "lucide-react";

// Components
import Finalnav from "./Homepages/Finalnav";
import Footer from "./Homepages/Footer";

// Images
import fbg from "../components/images/fbg.webp";



// FAQ Data
const faqData = [
  {
    question: "Why choose DoaGuru for Web Development?",
    answer: "DoaGuru Software is a top web development service provider in India. We offer comprehensive services including web design, e-commerce development, software creation, graphic design, and online marketing. Our expertise ensures high-quality, tailored solutions for your business needs."
  },
  {
    question: "Do you offer Search Engine Compatibility?",
    answer: "Absolutely! We create websites with full search engine compatibility. Our expert team ensures your website is optimized for visibility across all web browsers, meeting your specific business requirements and digital marketing goals."
  },
  {
    question: "Are your web applications mobile-friendly?",
    answer: "Yes, we specialize in responsive web design. Our applications are meticulously crafted to adapt seamlessly across various mobile devices, ensuring optimal performance and user experience on smartphones, tablets, and different screen sizes."
  },
  {
    question: "Can you create custom-designed websites?",
    answer: "We pride ourselves on delivering fully customized web solutions. Our design and development team creates unique websites from scratch, tailored to your specific requirements, ensuring a perfect blend of functionality, aesthetics, and brand identity."
  },
  {
    question: "Do you provide project management support?",
    answer: "We assign a dedicated project manager for each project. They provide regular updates, maintain clear communication, and ensure your project progresses smoothly while incorporating your feedback and meeting your specific objectives."
  }
];

function Faq() {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);

  // Filter FAQs based on search term
  const filteredFAQs = faqData.filter(faq => 
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <FAQContainer>
      <Finalnav />
      
      <Helmet>
        <title>FAQ - Professional Web Development Services | DoaGuru</title>
        <meta
          name="description"
          content="Get answers to frequently asked questions about our web development, design, and digital marketing services."
        />
      </Helmet>

      {/* Hero Section */}
      <div className="hero-section">
        <img
          src={fbg}
          alt="DoaGuru FAQ Background"
          style={{ 
            position: 'absolute', 
            width: '100%', 
            height: '100%', 
            objectFit: 'cover' 
          }}
        />
        <div className="hero-overlay">
          <motion.h1 
            className="hero-title"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            Looking for Answers?
          </motion.h1>
          <motion.p 
            className="hero-subtitle"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.7 }}
          >
            Find quick solutions to common questions about our web development and digital services
          </motion.p>
        </div>
      </div>

      {/* Search Container */}
      <div className="search-container">
        <input 
          type="text" 
          placeholder="Search frequently asked questions..."
          className="search-input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Search className="search-icon" size={24} />
      </div>

      {/* FAQ Section */}
      <div className="faq-section">
        <AnimatePresence>
          {filteredFAQs.map((faq, index) => (
            <motion.div 
              key={index}
              className="faq-item"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <div 
                className={`faq-question ${activeIndex === index ? 'open' : ''}`}
                onClick={() => setActiveIndex(activeIndex === index ? null : index)}
              >
                <h3>{faq.question}</h3>
                <motion.div 
                  className="icon"
                  animate={{ rotate: activeIndex === index ? 180 : 0 }}
                >
                  <ChevronDown size={24} />
                </motion.div>
              </div>
              
              <AnimatePresence>
                {activeIndex === index && (
                  <motion.div
                    className="faq-answer"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {faq.answer}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <Footer />
    </FAQContainer>
  );
}

export default Faq;

// Styled Components
const FAQContainer = Styled.div`
  background-color: #f4f7fa;

  .hero-section {
    position: relative;
    height: 400px;
    overflow: hidden;

    .hero-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(30, 58, 138, 0.7);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
      padding: 0 20px;
    }

    .hero-title {
      font-size: 3.5rem;
      font-weight: 800;
      margin-bottom: 15px;
      text-shadow: 0 4px 6px rgba(0,0,0,0.1);

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    .hero-subtitle {
      font-size: 1.2rem;
      max-width: 700px;
      opacity: 0.9;
    }
  }

  .search-container {
    max-width: 600px;
    margin: -30px auto 50px;
    position: relative;
    z-index: 10;

    .search-input {
      width: 100%;
      padding: 15px 50px 15px 20px;
      border-radius: 10px;
      border: none;
      box-shadow: 0 10px 25px rgba(0,0,0,0.1);
      font-size: 1rem;
    }

    .search-icon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #4a5568;
    }
  }

  .faq-section {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .faq-item {
    background-color: white;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
    overflow: hidden;

    .faq-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      cursor: pointer;
      background-color: #f9fafb;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f1f5f9;
      }

      h3 {
        margin: 0;
        font-size: 1.1rem;
        color: #2c3e50;
        font-weight: 600;
      }

      .icon {
        transition: transform 0.3s ease;
      }

      &.open .icon {
        transform: rotate(180deg);
      }
    }

    .faq-answer {
      padding: 20px;
      background-color: white;
      color: #4a5568;
      line-height: 1.6;
    }
  }
`;