import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogImg from "../images/blog31img.jpg";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

export const Blog31Page = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
          Best SEO Company in Indore - Boost Your Online Visibility
        </title>
        <meta
          name="description"
          content="Looking for the best SEO company in Indore? Get expert SEO services to improve your website ranking, traffic, and business growth. Contact us today. "
        />
        <link rel="canonical" href="https://doaguru.com/Best-SEO-Company-in-Indore" />


      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              Best SEO Company in Indore – Get Found Online & Grow Your Business

            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={BlogImg} alt="" className="frontimg rounded m-4" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                    In today’s digital world, having a website is not enough. If your business is not appearing on the first page of Google, you are losing potential customers. This is where an SEO company comes in. If you are searching for the <Link to="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2">best SEO company in Indore</Link>, you are at the right place.
                    At Doaguru Infosystems, we offer top-notch SEO services to help businesses improve their online presence and rank higher on search engines. Let’s explore why SEO is important and how our services can help your business.
                  </p>
                  <br />
                </div>
              </div>


              {isShowMore && (
                <div>
                  <div className="col-lg-12 mt-3">
                    <div data-aos="fade-left">
                      <h5>What is SEO & Why is it Important?</h5>
                      <p>SEO (Search Engine Optimization) is the process of optimizing your website to rank higher on search engines like Google, Bing, and Yahoo. Here’s why SEO is important:</p>
                      <ul>
                        <li><strong>Increases organic traffic</strong></li>
                        <li><strong>Enhances online visibility</strong></li>
                        <li><strong>Builds credibility and trust</strong></li>
                        <li><strong>Improves user experience</strong></li>
                        <li><strong>Boosts conversions and sales</strong></li>
                      </ul>
                      <p>If you want long-term growth, investing in SEO is a smart choice for your business.</p>
                      <hr />
                      <h5>Why Choose Doaguru Infosystems as the Best SEO Company in Indore?</h5>
                      <p>When it comes to SEO services, choosing the right company is crucial. Here’s why Doaguru Infosystems is the best SEO company.</p>
                      <ul>
                        <li><strong>Experienced SEO Professionals:</strong> Our team of experts has years of experience in SEO. We use the latest techniques to improve your website ranking.</li>
                        <li><strong>Customized SEO Strategies:</strong> Every business is unique, and so are our SEO strategies. We analyze your business needs and create a plan that suits you best.</li>
                        <li><strong>White Hat SEO Techniques:</strong> We follow ethical SEO practices to ensure sustainable results. Our methods comply with Google’s guidelines.</li>
                        <li><strong>Regular Performance Reports:</strong> We provide monthly reports so you can track your website’s progress.</li>
                        <li><strong>Affordable Pricing:</strong> We offer competitive pricing without compromising on quality. Get the best SEO services in Indore within your budget.</li>
                      </ul>

                      <h5>Our SEO Services</h5>
                      <p>At Doaguru Infosystems, we provide a complete range of SEO services:</p>
                      <ul>
                        <li><strong>On-Page SEO:</strong> We optimize your website’s content, structure, and HTML to improve rankings.</li>
                        <li><strong>Off-Page SEO:</strong> We build high-quality backlinks and improve your website’s authority.</li>
                        <li><strong>Local SEO:</strong> If you run a local business, we help you rank higher in Indore with Google My Business optimization.</li>
                        <li><strong>Technical SEO:</strong> We fix website speed, mobile-friendliness, and other technical aspects to enhance user experience.</li>
                        <li><strong>E-commerce SEO:</strong> We optimize your online store to attract more customers and increase sales.</li>
                      </ul>
                      <hr />

                      <h5>How Our SEO Process Works</h5>
                      <p>Our SEO strategy involves multiple steps:</p>
                      <ul>
                        <li><strong>Website Audit:</strong> We analyze your website’s current performance.</li>
                        <li><strong>Keyword Research:</strong> We find the best keywords for your business.</li>
                        <li><strong>On-Page Optimization:</strong> We optimize your website’s content and structure.</li>
                        <li><strong>Content Creation:</strong> We create high-quality, SEO-friendly content.</li>
                        <li><strong>Link Building:</strong> We generate high-quality backlinks to improve your authority.</li>
                        <li><strong>Performance Tracking:</strong> We monitor your website’s progress and make necessary adjustments.</li>
                      </ul>
                      <hr />

                      <h5>Conclusion</h5>
                      <p>If you are looking for the best SEO company in Indore, Doaguru Infosystems is here to help. Our expert SEO services will boost your rankings, increase traffic, and grow your business. Contact us today to get started.</p>

                      <h5>FAQs About SEO Services in Indore</h5>
                      <ul>
                        <li><strong>What is the cost of SEO services in Indore?</strong><br />SEO pricing varies depending on your business needs.</li>
                        <li><strong>How long does it take to see SEO results?</strong><br />SEO is a long-term strategy. You can start seeing results in 3-6 months, depending on competition and industry trends.</li>
                        <li><strong>Do I need SEO for a small business in Indore?</strong><br />Yes! SEO helps small businesses attract local customers and grow their online presence.</li>
                        <li><strong>What makes your SEO company the best?</strong><br />Our expert team, result-oriented strategies, and ethical SEO practices help businesses achieve higher rankings and long-term success. 🚀</li>
                        <li><strong>Can I do SEO on my own?</strong><br />While basic SEO can be done independently, hiring professionals ensures better and long-lasting results.</li>
                      </ul>

                      <h5>Contact DOAGuru InfoSystems Today!</h5>

                      <p>Boost your online visibility with the Best SEO agency in Bhopal and take your business to new heights with our expert SEO solutions! 🚀</p>
                      <hr />



                      <li>Visit our website: <Link href="https://doaguru.com/" target="_blank">DOAGuru Infosystems</Link></li>
                      <li>Fill out the contact form on our Contact Us page: <Link href="https://doaguru.com/contact_us" target="_blank">Contact Us</Link></li>

                    </div>
                    <hr />
                  </div>
                </div>



              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}


const Wrapper = styled.div`
  button {
    background-color: #fe7604;
    
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li{
    margin-bottom: 0.5rem;
    
  }
  ul{
    list-style  : none;
    
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft{
    margin-left: 2rem;
  }
`;
