import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import backimg from "../../components/images/1.png";

const HomeContent = () => {
    const handleFooterLink = () => {
        window.scrollTo(0, 0);
      };
  return (
    <>
      <Wrapper>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="text gap-4">
                    <h1>Best Digital Marketing Company and IT Solutions!</h1>

                    <p>Welcome to DOAGuru InfoSystem, where we provide all kinds of digital expertise and IT solutions. As the Best Digital Marketing Company in Jabalpur, we help you lead the way by offering customised marketing strategies that will enhance your presence in the digital world. Our skilled team develops solutions using advanced technology to drive your business towards success. With a clear focus on accuracy, creativity and unwavering commitment, we redefine what it means to excel in the industry while wholeheartedly ensuring that your brand stands out in the landscape. Discover the transformative experience, with DOAGuru InfoSystem- one of the leading names in the digital industry.</p>

                    <Link to="/about_us" onClick={handleFooterLink} id="aboutus">
        
            Know About
          </Link>
                </div>            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default HomeContent;
const Wrapper = styled.div`
/* background-image: url(${backimg});
background-size: cover;
background-position: right;
background-repeat: no-repeat; */
background: #002c63;
.text{
display: flex
;
    flex-direction: column;
    align-items: center;
    margin: 5rem;
    @media screen and (min-width: 280px) and (max-width: 768px) {
      margin: 2rem;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      margin: 2rem;
    }
}
.text h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: white;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    margin: 0;
    }
  }
  .text p {
    font-size: 1.2rem;
    color: white;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
   
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 42rem;
    }
  }
  #aboutus {
    display: inline-block;
    padding: 10px 20px;
    background: linear-gradient(to right, #00e5e5, #86ff68);
    color: #002c63;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
    /* &:hover {
    background-color:  #ff7d7d;
    color: white; */
  }
  `;
