import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogImg from "../images/blog35img.jpg";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

export const Blog35Page = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
        SEO Company in India – Rank Higher with DOAGuru InfoSystems
        </title>
        <meta
          name="description"
          content="Need more organic traffic? DOAGuru InfoSystems is a top SEO company in India, helping businesses rank higher on Google with expert SEO strategies. Get in touch today! "
        />
        <link rel="canonical" href="https://doaguru.com/seo-company-in-India" />
      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
            SEO Company in India – Boost Your Organic Traffic with DOAGuru InfoSystems
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 25,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={BlogImg} alt="" className="frontimg rounded m-4" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                  In today’s competitive digital world, ranking higher on search engines is essential for business success. DOAGuru InfoSystems is a <Link to="https://doaguru.com" target="_blank" className="link-offset-3-hover link-offset-2">leading SEO company in India</Link>, helping businesses increase their organic traffic and dominate search results. With our result-driven SEO strategies, we empower brands to enhance their online visibility, attract the right audience, and drive higher conversions.
                  </p>
                  <br />
                </div>
              </div>


              {isShowMore && (
                <div>
                <div className="col-lg-12 mt-3">
                  <div data-aos="fade-left">
                    <h5>Why Choose DOAGuru InfoSystems for SEO?</h5>
                    <ul>
                      <li><strong>Proven Track Record of Success:</strong> We have successfully worked with 200+ clients across various industries, helping them achieve exponential growth in organic traffic and rankings.</li>
                      <li><strong>Data-Driven SEO Strategies:</strong> Our SEO approach is 100% data-backed, ensuring that every decision we make is based on analytics and performance insights.</li>
                      <li><strong>White-Hat SEO Techniques:</strong> We strictly adhere to Google’s best practices, avoiding black-hat techniques that can lead to penalties. Our ethical SEO strategies ensure long-term rankings.</li>
                      <li><strong>Customized SEO Solutions:</strong> Every business is unique, and so are our strategies. We offer custom SEO solutions tailored to your industry, target audience, and goals.</li>
                      <li><strong>Comprehensive SEO Services:</strong> From keyword research and on-page SEO to link building and technical SEO, we provide an end-to-end solution for maximum results.</li>
                    </ul>

              
                    <h5>FAQs</h5>
                    <ul>
                      <li><strong>Why is DOAGuru InfoSystems the best SEO company in India?</strong><br />We have a proven track record, data-driven strategies, and a team of experienced SEO professionals delivering measurable results.</li>
                      <li><strong>How long does it take to see SEO results?</strong><br />SEO is a long-term strategy, and results can be seen within 3 to 6 months, depending on competition and website optimization.</li>
                      <li><strong>Do you offer local SEO services?</strong><br />Yes! We specialize in local SEO to improve visibility for location-based businesses.</li>
                      <li><strong>Do you provide SEO reports?</strong><br />Yes, we provide detailed monthly reports with insights on rankings, traffic, and strategy improvements.</li>
                      <li><strong>How do I get started with your SEO services?</strong><br />Simply visit <Link href="https://doaguru.com/" target="_blank">DOAGuru Infosystems</Link> or contact us today for a free SEO consultation!</li>
                    </ul>

                    <h5>Contact DOAGuru InfoSystems Today!</h5>
                    <p>Want to increase your organic traffic and dominate search engines? DOAGuru InfoSystems is your go-to SEO agency in India. Let’s grow your business together with proven SEO strategies! 🚀</p>
                  </div>
                  <hr />
                </div>
              </div>





              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read More"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}


const Wrapper = styled.div`
  button {
    background-color: #fe7604;
    
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  li{
    margin-bottom: 0.5rem;
    
  }
  ul{
    list-style  : none;
    
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
  .mleft{
    margin-left: 2rem;
  }
`;
