import React from 'react'
import { Link } from 'react-router-dom'

function Sitemap() {
  return (
    <div>
      <div className="container mt-5">
        <div className="heading text-center">
          
         <h1>Sitemap</h1>
         <div
                  className="underline mx-auto"
                  style={{
                    height: 3,
                    width: "4rem",
                    backgroundColor: "#34495E",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>
                
        </div>
        <div className="pages">
           <h6>Links of Pages</h6>
           <ul>
           
           <li>  <a className="nav-link">
                  <Link to="/" style={{ textDecoration: "none" }}>
                    Home
                  </Link>
                  </a></li>
                  <li><a className="nav-link">
                  <Link to="/about_us" style={{ textDecoration: "none" }}>
                    {" "}
                    About Us
                  </Link>
                </a></li>
                <li>
                    <a className="dropdown-item">
                      <Link to="/Best-SEO-Company-in-Jabalpur" style={{ textDecoration: "none" }}>
                        SEO Service
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      <Link to="/Best-social-media-marketing-company-in-Jabalpur-India" style={{ textDecoration: "none" }}>
                        SMM Service
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      <Link to="/ppc" style={{ textDecoration: "none" }}>
                        {" "}
                        PPC Google Adwordes
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      <Link
                        to="/Best-Digital-Marketing-Company-in-Jabalpur"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        Digital Marketing
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      <Link to="/Best-Software-Company-In-Jabalpur" style={{ textDecoration: "none" }}>
                        Website Desiginig
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      <Link
                        to="/mobile-development"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        Mobile App Development{" "}
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      <Link to="/graphic-designing" style={{ textDecoration: "none" }}>
                        {" "}
                        Graphic Designing{" "}
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                    <Link to="/pr-service" style={{ textDecoration: "none" }}>
                        {" "}
                        Pr Service{" "}
                      </Link>
                    </a>
                  </li>
                 
                  <li className="nav-item">
                <a className="nav-link" to="/portfolio">
                  <Link to="/portfolio" style={{ textDecoration: "none" }}>
                    {" "}
                    Portfolio
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <Link to="/career" style={{ textDecoration: "none" }}>
                    Career
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <Link to="/blogs" style={{ textDecoration: "none" }}>
                    Blogs
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <Link to="/case-studies" style={{ textDecoration: "none" }}>
                    Case Studies
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <Link to="/faq" style={{ textDecoration: "none" }}>
                    FAQ
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                <Link
                      to="/contact_us"
                      style={{ textDecoration: "none"}}
                    >
                      Contact Us
                    </Link>
                </a>
              </li>
           </ul>
        </div>
      </div>
    </div>
  )
}

export default Sitemap
