import { createChatBotMessage } from "react-chatbot-kit";
import Options from "./Options";

const config = {
  
  initialMessages: [
    createChatBotMessage("Welcome to Doaguru InfoSystems! How can I assist you?", {
      widget: "options",
    }),
  ],
  customComponents: {
    botAvatar: () => <div className="bot-avatar">🤖</div>,
    userAvatar: () => <div className="user-avatar">👤</div>,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "#007bff",
      color: "white",
    },
    chatButton: {
      backgroundColor: "#007bff",
    },
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    },
  ],
  state: {
    userMessages: [],
  },
};

export default config;
