import React from "react";
import styled from "styled-components";
import Finalnav from "../../page/Homepages/Finalnav";
import bgseo from "../images/bgseo.webp";
import seohero from "../images/seohero.webp";
import seoweb from "../images/seoweb.webp";
import seokey from "../images/seokey.webp";
import seobuild from "../images/seobulid.webp";
import seo1page from "../images/seop1page.webp";
import seoreg from "../images/seoreg.webp";
import seocom from "../images/seocom.webp";
import seoint from "../images/seoint.webp";
import seostr from "../images/seostr.webp";
import seoQ from "../images/seoQ.webp";
import seolocal from "../images/seolocal.webp";
import seocost from "../images/cost.webp";
import seouser from "../images/seouser.webp";
import seofirst from "../images/seoloc.webp";
import seoper from "../images/seoper.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumb from "../Breadcrumb";
import Footer from "../../page/Homepages/Footer";
function SEO() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Finalnav />
      <Helmet>
        <title>
        Best SEO Company in Jabalpur - DOAGuru InfoSystems

        </title>
        <meta
          name="description"
          content="DOAGuru InfoSystems stands as the Leading SEO company in India. With our expertise in crafting tailored SEO strategies, We help businesses all over India succeed online, whether they're in big cities or small towns.
          "
        />
        <link
          rel="canonical"
          href="https://doaguru.com/Best-SEO-Company-in-Jabalpur"
        />
      </Helmet>
      <Wrapper>
        <Breadcrumb />

        <div
          className="container-fluid"
          id="cont7"
          style={{
            backgroundImage: `url(${bgseo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row67">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
            <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
            Best SEO Company in Jabalpur
              </h1>

              {/* <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
                Your SEO Dreams, Our Expertise – Achieving Success- Best SEO
                Agency
              </h1>
              <h5 className="hdd text-white">
                "Stay Visible, Stay Ahead: SEO Solutions for Success"
              </h5> */}
              <p className="pdd text-white mt-4">
                As a top SEO firm, our goal is to improve user experience and
                website ranking while providing the best SEO services out there.
                We are aware of how crucial Search Engine Optimisation (SEO) is
                to improving your online exposure as a strategic strategy.
                Getting targeted organic traffic from major search engines like
                Google, Bing, and others is our aim. Our goal is to not only
                raise your website's search engine ranking but also make sure
                that each and every visitor has a smooth and enjoyable
                experience.
              </p>
              <button
                type="button"
                className="btn  mt-4"
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12" id="heroimg">
              <img
                src={seohero}
                className="img3 d-block"
                alt="..."
                id="fullscreen"
              />
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12 mb-4 mt-5 text-center">
              <h3 style={{ color: "#34495E" }}>
                Services of Search Engine Optimisation
              </h3>
              <div
                className="underline mx-auto"
                style={{
                  height: 3,
                  width: "4rem",
                  backgroundColor: "#34495E",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img src={seoweb} className=" mt-3" id="seo1" />
                  <div className="card body" id="seohd1">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Website Audit and Analysis
                    </h5>
                    <div className="underline"></div>
                    <p className="para-text">
                      As a Best SEO Company we carry out a thorough audit of
                      your website. This comprehensive analysis finds structural
                      problems, technical flaws, and potential problems that
                      might harm the functionality or the SEO of your website
                      and reduce your visibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img src={seokey} className=" mt-3" id="seo2" />

                  <div className="card body" id="seohd1">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Keyword Research
                    </h5>
                    <div className="underline"></div>
                    <p className="para-text">
                      As a Best SEO Company, we leverage our expertise to
                      perform thorough keyword research, aiming to pinpoint the
                      most relevant and high-impact keywords for your business.
                      This meticulous process involves uncovering the specific
                      terms actively sought after by your potential customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img src={seobuild} className=" mt-3" id="seo2" />
                  <div className="card body" id="seohd1">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Link Building and Outreach
                    </h5>
                    <div className="underline"></div>
                    <p className="para-text">
                      Our collaborative approach, guided by the expertise of the
                      Best SEO Services, revolves around creating top-notch
                      content and forging strategic partnerships. This
                      encompasses outreach and link-building strategies,
                      guaranteeing the acquisition of high-quality backlinks
                      from reputable sources.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img src={seo1page} className="mt-3" id="seo4" />
                  <div className="card body" id="seohd1">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      On-Page Optimization
                    </h5>
                    <div className="underline"></div>
                    <p className="para-text">
                      Our precise on-page optimization techniques, implemented
                      by the Top SEO Company, are designed to enhance the
                      visibility of your website pages on search engines. We
                      fine-tune every element, including headings, content, and
                      meta descriptions.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img src={seoreg} className=" mt-3" id="seo5" />
                  <div className="card body" id="seohd1">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Regular Reporting and Analysis
                    </h5>
                    <div className="underline"></div>
                    <p className="para-text">
                      Our strategy is based on transparency. We regularly report
                      on the results and progress of our SEO efforts. We provide
                      insights into keyword rankings, organic traffic growth,
                      and conversion rates in our data-driven reports.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img src={seocom} className=" mt-3" id="seo2" />
                  <div className="card body" id="seohd1">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Consultation and Communication
                    </h5>
                    <div className="underline"></div>
                    <p className="para-text">
                      Our services are based on collaboration. Throughout the
                      SEO process, our dedicated team works closely with you and
                      is committed to open communication. We ensure the
                      effectiveness of our strategies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <h2 className="hd text-center mb-5 mt-5">
            Comprehensive SEO Approach by the Best SEO Agency
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 20,
                marginBottom: 10,
              }}
            ></div>
          </h2>

          <div className="container" id="seoagency">
            <div className="row">
              <div className="col-sm-6">
                <div data-aos="fade-right">
                  <img
                    id="clientpages"
                    src={seoint}
                    className="img-fluid"
                    style={{
                      marginTop: "1em",
                      width: "32rem",
                      height: "15rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div data-aos="fade-left">
                  <h3 className="sm ">Initial Website Analysis</h3>

                  <p className="tt text-justify mt-3">
                    To conduct a thorough analysis of the client's website and
                    identify its strengths and weaknesses, our approach as the
                    best SEO agency involves meticulous research and strategic
                    planning for keywords to ensure optimal performance.
                  </p>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div className="container  " id="seoagency">
            <div className="row">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={seoQ}
                    className="img-fluid"
                    style={{
                      width: "32rem",
                      height: "15rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>

              <div className="col-sm-6" id="smd">
                <div data-aos="fade-right">
                  <h3 style={{ marginBottom: "1rem" }} className="qcc ">
                    Quality Content Creation
                  </h3>

                  <p className="text-justify mt-3">
                    We follow a process to create engaging, informative, and
                    shareable content that appeals to both users and search
                    engines. The foundation of organic growth and backlink
                    acquisition relies heavily on content.
                  </p>
                </div>
                <br />
              </div>
              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={seoQ}
                    className="img-fluid"
                    style={{
                      width: "32rem",
                      height: "15rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container  " id="seoagency">
            <div className="row">
              <div className="col-sm-6">
                <div data-aos="fade-right">
                  <img
                    src={seostr}
                    className="img-fluid"
                    style={{
                      marginTop: "1em",
                      width: "32rem",
                      height: "16rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div data-aos="fade-left">
                  <h3 className="tse">Technical SEO Enhancements</h3>
                  <p className="text-justify mt-3">
                    {" "}
                    Leveraging our extensive experience as the Best IT company,
                    we address technical issues that directly impact a website's
                    usability and search engine rankings. This includes a strong
                    focus on optimising crucial features such as URL structure,
                    schema markup, and ensuring mobile friendliness.
                  </p>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div className="container" id="seoagency">
            <div className="row" id="str">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={seolocal}
                    className="img-fluid"
                    style={{
                      width: "32rem",
                      height: "15rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>

              <div className="col-sm-6" id="smd">
                <div data-aos="fade-right">
                  <h3 className="lss">Local SEO Strategies</h3>
                  <p className="text-justify mt-3">
                    We implement strategies like Google My Business optimization
                    to improve the client's online presence for local searches.
                    We also provide regular analysis and reporting.
                  </p>
                </div>
                <br />
              </div>
              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <img
                    id="clientpages"
                    src={seolocal}
                    className="img-fluid"
                    style={{
                      width: "32rem",
                      height: "15rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <h2 className="sr text-center mt-5">
            {" "}
            Why Choose SEO
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
          </h2>
          <div className="row row-cols-1 row-cols-md-2 g-4 mt-3">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100 " id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={seofirst}
                    className="card-img-top "
                    alt="..."
                    style={{}}
                    id="animatedrt"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Drive Organic Traffic</h5>

                    <p className="card-text">
                      The primary goal of any top SEO company is to increase a
                      website's visibility in search engine results. Driving
                      organic traffic means attracting visitors through
                      non-paid, natural methods by optimizing various aspects of
                      a website and its content to rank higher in search engine
                      results pages.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={seocost}
                    className="card-img-top"
                    alt="..."
                    id="animate2A"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Cost-Effectiveness</h5>
                    <p className="card-text">
                      Comparing the cost-effectiveness of the best SEO services
                      with other digital marketing tactics like paid advertising
                      involves evaluating the relative efficiency and return on
                      investment (ROI) of each approach.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={seoper}
                    className="card-img-top"
                    alt="..."
                    id="animate2B"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Precise Targeting</h5>
                    <p className="card-text">
                      Effective SEO, with the assistance of the best SEO agency,
                      allows you to finely target your marketing efforts to
                      specific demographics and keywords that are highly
                      relevant to your business. This enables strategic outreach
                      to the audience most likely to be interested in your
                      products or services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card h-100" id="cardshadow">
                <div data-aos="zoom-in-up">
                  <img
                    src={seouser}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "15rem", height: "10rem" }}
                    id="animate"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title"> User Enhancement</h5>
                    <p className="card-text">
                      Certainly! Search Engine Optimization (SEO) constitutes a
                      holistic approach in the realm of digital marketing, with
                      a core emphasis on augmenting a website's prominence
                      within search engine result listings. A pivotal facet of
                      triumphant SEO entails the refinement of user engagement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="last">
          <div className="hero-section text-center mt-5">
            <h2 style={{ paddingTop: "4rem", color: "#155ea3" }}>
              Increase Online Visibility with the Company
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
            <p className="mt-4">
              In the realm of digital existence, the actualization of your
              website's potential lies within our time-tested SEO remedies. Our
              adept cadre is poised to guide you towards triumph, be it a
              diminutive enterprise aspiring to regional eminence or a colossal
              entity in pursuit of worldwide acclaim.
            </p>
            <button
              type="button"
              className="btn   mb-3"
              style={{ color: "white" }}
            >
              <Link
                to="/contact_us"
                onClick={handleFooterLink}
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Enquire Now
              </Link>
            </button>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default SEO;

const Wrapper = styled.div`
  .ws {
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
  #cont7 {
    height: 40rem;

    @media screen and (max-width: 768px) {
      height: 63rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 68rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      height: 33rem;
    }
  }
  #row67 {
    margin-left: 3rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }

  #cardshadow {
    border-radius: 20px;
    box-shadow: 8px 8px 10px 1px whitesmoke;
    border: none;
  }
  #cardshadow:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  #smd {
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
    }
  }

  #heroimg {
    @media screen and (max-width: 768px) {
      margin-left: -5rem;
      margin-top: -1rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
      width: 50rem;
      margin-left: 1rem;
    }
  }
  .para-text {
    text-align: justify;
    padding: 0 12px 0px 17px;
  }

  .card-img-top {
    @media screen and (max-width: 768px) {
      margin-left: 3.5rem;
    }
  }
  #animate2A {
    width: 14rem;
    height: 10rem;
    margin-left: 1.5rem;

    @media screen and (max-width: 768px) {
      width: 16rem;

      margin-left: 3.5rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      padding-left: 2.5rem;
    }
  }
  #animate2B {
    width: 12rem;
    height: 10rem;
    margin-left: 2rem;
    @media screen and (max-width: 768px) {
      margin-left: 5.5rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 4rem;
    }
  }

  #animate {
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      padding-left: 2.5rem;
    }
  }
  .img3 {
    margin-top: 7rem;
    width: 60%;
    margin-left: 9rem;
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      margin-top: 2rem;
      width: 50%;
      margin-left: 10rem;
    }
  }

  #seo1 {
    height: 5rem;
    width: 10rem;
    margin-left: 6.5rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: 5.6rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 8rem;
    }
  }

  #seo2 {
    height: 5rem;
    width: 5rem;
    margin-left: 9rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: 8rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 10.5rem;
    }
  }
  #seo4 {
    height: 5rem;
    width: 8rem;
    margin-left: 7.5rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: 6.5rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 8.5rem;
    }
  }
  #seo5 {
    height: 5rem;
    width: 5rem;
    margin-left: 8.8rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: 7.9rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 10rem;
    }
  }
  #seohd1 {
    height: 290px;
    border: none;
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    font-size: 28px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }

  #mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  #desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .sm {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 2rem;
    }
  }
  .qcc {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1rem;
    }
  }
  .tse {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1rem;
    }
  }
  .lss {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1rem;
    }
  }
  #seoagency {
    margin-top: 3rem;
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 3rem;
    }
  }
  #str {
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: -2rem;
    }
  }
  #animatedrt {
    width: 17rem;
    height: 10rem;
    margin-left: -1rem;
    @media screen and (max-width: 768px) {
      margin-left: 3rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-left: 0.5rem;
    }
  }
  .card-text {
    text-align: justify;
  }
`;
