// import { useState } from "react";
// import { RiRobot3Fill } from "react-icons/ri";
// import Chatbot from "react-chatbot-kit";
// import "react-chatbot-kit/build/main.css";
// import config from "./config";
// import MessageParser from "./MessageParser";
// import ActionProvider from "./ActionProvider";

// const ChatbotComponent = () => {
//   const [showChatbot, setShowChatbot] = useState(false);

//   const handleOpenChatbot = () => {
//     setShowChatbot(!showChatbot);
//   };

//   return (
//     // <div className="z-10">
//     //   <button
//     //     className="fixed bottom-20 z-10  h-16 flex justify-center items-center w-16 right-4 bg-gray-400 shadow-lg shadow-gray-950 text-white px-4 py-2 rounded-full"
//     //     onClick={handleOpenChatbot}
//     //   >
//     //     {showChatbot ? (
//     //       <RiRobot3Fill className="size-5 text-black" />
//     //     ) : (
//     //       <RiRobot3Fill className="size-7 hover:text-orange-400" />
//     //     )}
//     //   </button>
//     //   {showChatbot && (
//     //     <div className="fixed z-10  bottom-36 right-4">
//     //       <Chatbot
//     //         config={config}
//     //         messageParser={MessageParser}
//     //         actionProvider={ActionProvider }
//     //       />
//     //     </div>
//     //   )}
//     // </div>


//     <div style={{ position: "relative" }}>
//   <button
//     style={{
//       position: "fixed",
//       bottom: "100px",
//       right: "16px",
//       width: "64px",
//       height: "64px",
//       backgroundColor: "#6c757d", // Bootstrap gray-400 equivalent
//       color: "white",
//       borderRadius: "50%",
//       boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.8)",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       border: "none",
//       cursor: "pointer",
//       zIndex: "9999",
//     }}
//     onClick={handleOpenChatbot}
//   >
//     {showChatbot ? (
//       <RiRobot3Fill style={{ fontSize: "20px", color: "black" }} />
//     ) : (
//       <RiRobot3Fill
//         style={{
//           fontSize: "28px",
//           transition: "color 0.3s",
//         }}
//         onMouseEnter={(e) => (e.target.style.color = "orange")}
//         onMouseLeave={(e) => (e.target.style.color = "white")}
//       />
//     )}
//   </button>
//   {showChatbot && (
//     <div
//       style={{
//         position: "fixed",
//         bottom: "144px",
//         right: "16px",
//         zIndex: 10,
//       }}
//     >
//       <Chatbot config={config} messageParser={MessageParser} actionProvider={ActionProvider} />
//     </div>
//   )}
// </div>

//   );
// };

// export default ChatbotComponent;

import React, { useState } from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import ChatBot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import styled, { keyframes } from "styled-components";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import config from "./config";
import { IoMdClose,IoMdSend,IoMdChatbubbles } from "react-icons/io";

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const ChatbotWrapper = styled.div`
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1000;
  scroll-bar: hide;
`;

// const ChatbotContainer = styled.div`
//   width: 400px;
//   height: 550px;
//   border-radius: 15px;
//   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
//   background: rgba(255, 255, 255, 0.9);
//   backdrop-filter: blur(10px);
//   overflow: hidden;
//   animation: ${fadeIn} 0.5s ease-in-out;
//   display: ${(props) => (props.open ? "block" : "none")};
// `;

const ChatbotHeader = styled.div`
  background: #002c63;
  color: white;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  border-radius: 15px 15px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// const CloseButton = styled(IoMdClose)`
//   font-size: 20px;
//   cursor: pointer;
// `;

const ChatIcon = styled(IoMdChatbubbles)`
  font-size: 50px;
  color: #007bff;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const CloseButton = styled(IoMdClose)`
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    color: red;
  }
`;

const SendButton = styled(IoMdSend)`
  font-size: 24px;
  color: #007bff;
  cursor: pointer;
  margin-left: 10px;
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  transition: border 0.3s ease;
  &:focus {
    border-color: #007bff;
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  }
`;

const ChatbotContainer = styled.div`
  width: 270px;
  height: 550px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(15px);
  overflow: hidden;
  animation: ${fadeIn} 0.5s ease-in-out;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
`;


export const ChatBotComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ChatbotWrapper>
      {!isOpen && <ChatIcon onClick={() => setIsOpen(true)} />}
      <ChatbotContainer open={isOpen}>
        <ChatbotHeader>
          DOAGuru Assistant
          <CloseButton onClick={() => setIsOpen(false)} />
        </ChatbotHeader>
        <ChatBot 
          config={config} 
          actionProvider={ActionProvider} 
          messageParser={MessageParser} 
          // headerText='Doaguru Infosystems Assistant'
          placeholderText='Type your message here...'
          
        />
      </ChatbotContainer>
    </ChatbotWrapper>
  );
};

