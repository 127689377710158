import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';


// Main Component
const DoaguruBannerDisplay = () => {
  useEffect(() => {
    // Add Google Fonts dynamically
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <Container>
      <BackgroundEffect />
      <GridLines />
      
      {/* Floating particles for visual interest */}
      <FloatingParticle size="8px" top="15%" left="10%" duration="8s" />
      <FloatingParticle size="12px" top="65%" left="15%" duration="9s" delay="0.5s" />
      <FloatingParticle size="6px" top="35%" left="85%" duration="7s" delay="1s" />
      <FloatingParticle size="10px" top="75%" left="80%" duration="10s" delay="1.5s" />
      <FloatingParticle size="15px" top="25%" left="60%" duration="11s" delay="0.7s" />
      <FloatingParticle size="7px" top="85%" left="40%" duration="9s" delay="2s" />
      
      <ContentWrapper>
        <Title>Doaguru Infosystems</Title>
        <Divider />
        <Subtitle>Innovating Digital Success with Expert Development & Marketing Solutions</Subtitle>
          <Link to="/contact_us">
        <Button >
          Discover More <ArrowRight size={22} strokeWidth={2.5} />
        </Button>
          </Link>
      </ContentWrapper>
    </Container>
  );
};

export default DoaguruBannerDisplay;

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(83, 145, 255, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(83, 145, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(83, 145, 255, 0);
  }
`;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  padding: 5rem 2rem;
  text-align: center;
  min-height: 500px;
  position: relative;
  overflow: hidden;
`;

const BackgroundEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(76, 161, 255, 0.15) 0%, transparent 20%),
    radial-gradient(circle at 80% 70%, rgba(76, 161, 255, 0.1) 0%, transparent 20%);
  z-index: 0;
`;

const GridLines = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, #002c63 1px);
  background-size: 40px 40px;
  z-index: 0;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  background: linear-gradient(to right, #b8d0f2, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
  letter-spacing: -1px;
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  animation: ${float} 6s ease-in-out infinite;
`;

const Divider = styled.div`
  width: 180px;
  height: 4px;
  background: linear-gradient(90deg, #4e95ff, #73b4ff, #4e95ff);
  background-size: 200% auto;
  margin: 1.5rem auto;
  border-radius: 4px;
  animation: ${shimmer} 3s linear infinite;
`;

const Subtitle = styled.h2`
  font-size: 2.2rem;
  font-weight: 500;
  color: #e0eaff;
  margin-bottom: 3rem;
  font-family: 'Poppins', sans-serif;
  opacity: 0.9;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  background: linear-gradient(to right, #3366cc, #5e91ff);
  color: white;
  border: none;
  border-radius: 50px;
  padding: 1rem 2.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(30, 60, 114, 0.4);
  position: relative;
  overflow: hidden;
  animation: ${pulse} 2s infinite;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 15px 25px rgba(30, 60, 114, 0.5);
    background: linear-gradient(to right, #2855b2, #4a7deb);
  }

  &::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(45deg);
    transition: all 0.3s ease;
  }

  &:hover::after {
    transform: rotate(45deg) translate(100%, 100%);
  }
`;

const FloatingParticle = styled.div`
  position: absolute;
  width: ${props => props.size || '10px'};
  height: ${props => props.size || '10px'};
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: ${props => props.top || '10%'};
  left: ${props => props.left || '10%'};
  animation: ${float} ${props => props.duration || '6s'} ease-in-out infinite;
  animation-delay: ${props => props.delay || '0s'};
`;

