import React from "react";
import Mobile from "../../components/images/moblieapp.webp";
import web from "../../components/images/website1.webp";
import pcc from "../../components/images/ppcservice.webp";
import Digital from "../../components/images/digitalser.webp";
import seo from "../../components/images/seoser.webp";
import graphic from "../../components/images/graphicser.webp";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import backimg from "../../components/images/1.png";

const servicesData = [
  {
    title: 'Mobile App Development',
    image: Mobile,
    description: (
      <>
        Our <Link to="/moblie-development" style={{ color: '#86ff68' }}>Mobile App Development Company</Link> brings ideas to life with seamless, engaging mobile experiences.
      </>
    ),
    link: '/mobile-development',
    btnColor: '#184fca',
    imgStyle: { width: '10rem' }
  },
  {
    title: 'Software and Website Development',
    image: web,
    description: 'Explore comprehensive software and website development services tailored to your needs...',
    link: '/Top-Software-Company-in-Jabalpur',
    btnColor: '#FE7604',
    imgStyle: { width: '10rem' }
  },
  {
    title: 'PPC GOOGLE ADWORDS',
    image: pcc,
    description: 'We harness the power of pay-per-click (PPC) advertising to your benefit, unlocking unparalleled growth...',
    link: '/ppc',
    btnColor: '#FE7604',
    imgStyle: { width: '10rem' }
  },
  {
    title: 'Digital Marketing',
    image: Digital,
    description: (
      <>
        As the best{' '}
        <Link 
        to="/Best-Digital-Marketing-Company-in-Jabalpur" 
         style={{ color: '#86ff68' }}>
          Digital Marketing Company
        </Link>{' '}
        we harness Performance marketing to expedite your brand's digital evolution...
      </>
    ),
    link: '/Best-Digital-Marketing-Company-Jabalpur',
    btnColor: '#FE7604',
    imgStyle: { width: '14rem' }
  },
  {
    title: 'Search Engine Optimisation Service',
    image: seo,
    description: (
      <>
        Search engine optimization (SEO), offered by the{' '}
        <Link to="/Best-SEO-Company-in-Jabalpur" style={{ color: '#86ff68' }}>
          Best SEO company
        </Link>, plays a major role in improving your footfalls and online presence...
      </>
    ),
    link: '/Best-SEO-Company-in-Jabalpur',
    btnColor: '#FE7604',
    imgStyle: { width: '14rem' }
  },
  {
    title: 'Graphic Designing',
    image: graphic,
    description: 'We provide all-encompassing Graphic Design Services, adeptly crafting visual creations through...',
    link: '/graphic-designing',
    btnColor: '#FE7604',
    imgStyle: { width: '14rem' }
  },
];

const ServiceCard = ({ title, image, description, link, imgStyle }) => (
  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
    <div className="card " style={{
      // width: '25rem',
      height: '25rem',
      overflow: 'hidden',
      background: 'rgb(0 0 0 / 0%)',
      borderRadius: '16px',
      boxShadow: 'rgb(69 74 100 / 77%) 0px 4px 30px',
      backdropFilter: 'blur(4.9px)',
      WebkitBackdropFilter: 'blur(4.9px)',
      border: '1px solid rgba(206, 206, 234, 0.07)',
    }}>
      <div data-aos="zoom-in-up" className="p-3" style={{ height: "inherit", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={image} className="card-img-top " alt={title} style={imgStyle} />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text" style={{ textAlign: 'justify' }}>
            {description}
          </p>
        </div>
        <Link
          to={link}
          className="btn "
          style={{
            backgroundColor: 'transparent',
            color: '#ffff',
            fontWeight: '500',
            border: 'solid',
          }}
          onClick={() => window.scrollTo(0, 0)}
        >
          Read on
        </Link>
      </div>
    </div>
  </div>
);

function Service() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <>
      <Wrapper>
        {/* <div className="container-fluid" id="contbg">
          <div className="container">
            <div className="services">
              <div className="heading  text-center">
                <h2 className="ser"> Our Services</h2>
                <div
                  className="underline mx-auto"
                  style={{
                    height: 3,
                    width: "10rem",
                    backgroundColor: "#86ff68",
                  }}
                ></div>
                <div className="para">
                  <div data-aos="fade-up" data-aos-duration="3000">
                    <p className="pp">
                      We are proud to be one of the Best digital marketing
                      agency and top IT companies in Jabalpur and India, and we
                      strive to maintain that status by providing exceptional
                      service to our clients. Our commitment to excellence is
                      reflected in our work, and we are confident that we can
                      help your business to achieve its digital goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3">

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                <div className="card rounded border bg-transparent  " >
                  <div data-aos="zoom-in-up">
                    <img
                      src={Mobile}
                      className="card-img-top"
                      alt="..."
                      id="card1"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Mobile App Developement</h5>
                      <p className="card-text" style={{ textAlign: "justify" }}>
                        Our esteemed{" "}
                        <Link
                          to="/moblie-development"
                          style={{ color: "black" }}
                          onClick={handleFooterLink}
                        >
                          Mobile App Development Company
                        </Link>{" "}
                        excels in the art of turning innovative ideas into
                        seamless and captivating user interactions. We possess a
                        distinct expertise in the domain of mobile app
                        development.
                      </p>
                      <Link
                        to="/mobile-development"
                        onClick={handleFooterLink}
                        className="btn "
                        style={{
                          backgroundColor: "#184fca",
                          color: "#002c63",
                          fontWeight: "500",
                        }}
                        id="btn1"
                      >
                        Read on
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                <div className="card rounded  ">
                  <div data-aos="zoom-in-up">
                    <img
                      src={web}
                      className ="card-img-top"
                      alt="..."
                      id="card2"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
Software and Website Development
</h5>
                      <p className="card-text" style={{ textAlign: "justify" }}>
                      Explore comprehensive software and website development services tailored to your needs. Our expert team specializes in creating innovative software solutions and user-friendly websites that drive growth and enhance your online presence.

                      </p>
                      <Link
                        to="/Best-Software-Company-In-Jabalpur"
                        onClick={handleFooterLink}
                        className="btn  "
                        style={{
                          backgroundColor: " #FE7604",
                          color: "#002c63",
                          fontWeight: "500",
                        }}
                        id="btn2"
                      >
                        Read on
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                <div className="card rounded ">
                  <div data-aos="zoom-in-up">
                    <img
                      src={pcc}
                      className="card-img-top"
                      alt="..."
                      id="card3"
                    />
                    <div className="card-body">
                      <h5 className="card-title">PPC GOOGLE ADWORDS</h5>
                      <p className="card-text" style={{ textAlign: "justify" }}>
                        We harness the power of pay-per-click (PPC) advertising
                        to your benefit, unlocking unparalleled growth in the
                        digital realm. With our expertise, you can steer
                        targeted traffic, elevate conversion rates, and optimise
                        your return on investment (ROI)
                      </p>
                      <Link
                        to="/ppc"
                        onClick={handleFooterLink}
                        className="btn "
                        style={{
                          backgroundColor: " #FE7604",
                          color: "#002c63",
                          fontWeight: "500",
                        }}
                        id="btn3"
                      >
                        Read on
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                <div className="card  rounded">
                  <div data-aos="zoom-in-up">
                    <img
                      src={Digital}
                      className="card-img-top"
                      alt="..."
                      id="card4"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Digital Marketing</h5>
                      <p className="card-text" style={{ textAlign: "justify" }}>
                        As the best{" "}
                        <Link
                          to="/Best-Digital-Marketing-Company-in-Jabalpur"
                          onClick={handleFooterLink}
                          style={{ color: "#4c42d5" }}
                        >
                          Digital Marketing Company
                        </Link>{" "}
                        we harness Performance marketing to expedite your
                        brand's digital evolution. We utilise an all integrated
                        approach of SEO, SMO, SEM, and
                        <Link
                          to="/Best-social-media-marketing-company-in-Jabalpur-India"
                          onClick={handleFooterLink}
                          style={{ color: "#4c42d5" }}
                        >
                          {" "}
                          Social Media Marketing Services{" "}
                        </Link>
                        to increase traffic and generate high quality leads
                        using retargeting and remarketing.
                      </p>
                      <Link
                        to="/Best-Digital-Marketing-Company-in-Jabalpur"
                        onClick={handleFooterLink}
                        className="btn btn "
                        style={{
                          backgroundColor: "#FE7604",
                          color: "#002c63",
                          fontWeight: "500",
                        }}
                        id="btn4"
                      >
                        Read on
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                <div className="card  rounded">
                  <div data-aos="zoom-in-up">
                    <img
                      src={seo}
                      className="card-img-top"
                      alt="..."
                      id="card5"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                        Search Engine Optimisation Service
                      </h5>
                      <p className="card-text" style={{ textAlign: "justify" }}>
                        Search engine optimization (SEO), offered by the{" "}
                        <Link
                          to="/Best-SEO-Company-in-Jabalpur"
                          onClick={handleFooterLink}
                          style={{ color: "#4c42d5" }}
                        >
                          Best SEO company
                        </Link>
                        , is the cornerstone that plays a major role in
                        improving your footfalls and online presence
                      </p>
                      <Link
                        to="/Best-SEO-Company-in-Jabalpur"
                        onClick={handleFooterLink}
                        className="btn "
                        style={{
                          backgroundColor: "#FE7604",
                          color: "#002c63",
                          fontWeight: "500",
                        }}
                        id="btn5"
                      >
                        Read on
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                <div className="card  rounded">
                  <div data-aos="zoom-in-up">
                    <img
                      src={graphic}
                      className="card-img-top"
                      alt="..."
                      id="card6"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Graphic Designing</h5>
                      <p className="card-text" style={{ textAlign: "justify" }}>
                        We provide all-encompassing Graphic Design Services,
                        adeptly crafting visual creations through meticulous
                        typography, imagery, colour, and design according to
                        your brand
                      </p>
                      <Link
                        to="/graphic-designing"
                        onClick={handleFooterLink}
                        className="btn "
                        style={{
                          backgroundColor: " #FE7604",
                          color: "#002c63",
                          fontWeight: "500",
                        }}
                        id="btn6"
                      >
                        Read on
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        <div className="container-fluid" id="contbg">
          <div className="container">
            <div className="services">
              <div className="heading text-center">
                <h2 className="ser pt-5">Our Services</h2>
                <div
                  className="underline mx-auto"
                  style={{
                    height: 3,
                    width: '10rem',
                    backgroundColor: '#86ff68',
                  }}
                ></div>
                <div className="para">
                  <div data-aos="fade-up" data-aos-duration="3000" className="mt-4">
                    <p className="pp">
                      We are proud to be one of the best digital marketing agencies and top IT companies in Jabalpur and India...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3 bg-transparent mt-5">
              {servicesData.map((service, index) => (
                <ServiceCard key={index} {...service} />
              ))}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default Service;

const Wrapper = styled.div`
  .para {
    font-size: 1.4rem;
    @media screen and (max-width: 768px) {
      text-align: justify;
    }
  }
  .card {
    padding: 5px;
    text-align: center;
    height: 33rem;
    border: none;

    @media screen and (max-width: 768px) {
      height: 35rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      height: 30rem;
    }
  }
  .card:hover {
    /* box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  #card1 {
    height: 12rem;
    width: 15rem;

    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 12rem;
    }
  }
  #card2 {
    height: 12rem;
    width: 15rem;

    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 12rem;
    }
  }
  #card3 {
    height: 12rem;
    width: 15rem;

    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 12rem;
    }
  }

  #card4 {
    height: 12rem;
    width: 25rem;
    @media screen and (max-width: 768px) {
      margin-left: -2rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: -2rem;
    }
  }
  #card5 {
    height: 12rem;
    width: 25rem;
    @media screen and (max-width: 768px) {
      margin-left: -1.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: -2rem;
    }
  }
  #card6 {
    height: 12rem;
    width: 25rem;
    @media screen and (max-width: 768px) {
      margin-left: -1.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-left: -1rem;
    }
  }

  a {
    text-decoration: none;
  }
  .card-title {
    margin-top: 0.5rem;
  }
  .card-text {
    margin-top: 1rem;
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: white;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #ffff; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  #contbg {
    background-image: url(${backimg});
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    /* background: #002c63; */
    height: 70rem;
    @media screen and (max-width: 768px) {
      height: 242rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 125rem;
    }
  }
  .ser {
    padding-top: 2rem;
    color: white;
  }

  #btn1 {
    margin-top: 2rem;
    background: linear-gradient(to right, #00e5e5, #86ff68);
  }
  #btn2 {
    margin-top: 2rem;
    background: linear-gradient(to right, #00e5e5, #86ff68);
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 0.5rem;
    }
  }
  #btn3 {
    margin-top: 2rem;
    background: linear-gradient(to right, #00e5e5, #86ff68);
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1.5rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 0.5rem;
    }
  }
  #btn4 {
    background: linear-gradient(to right, #00e5e5, #86ff68);
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: -0.8rem;
    }
  }
  #btn5 {
    margin-top: 3rem;
    background: linear-gradient(to right, #00e5e5, #86ff68);
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 4.3rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 3.5rem;
    }
  }
  #btn6 {
    margin-top: 4.5rem;
    background: linear-gradient(to right, #00e5e5, #86ff68);
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 3.5rem;
    }
  }
  .pp {
    padding-top: 0.5rem;
    font-size: 1rem;
    color: white;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;
