import React from "react";
import styled from "styled-components";
import googleads from "../images/googleads.webp";
import facebook from "../images/facebook.webp";
import analyics from "../images/analytics.webp";
import v from "../images/v.webp";
import console from "../images/console.webp";
import canva1 from "../images/canva1.webp";
import Finalnav from "../../page/Homepages/Finalnav";
import bgdigital from "../images/bgdigital2.webp";
import digitalhero from "../images/digitalhero.webp";
import serve1 from "../images/serve1.webp";
import serve2 from "../images/serve2.webp";
import serve3 from "../images/serve3.webp";
import serve4 from "../images/serve4.webp";
import serve5 from "../images/serve5.webp";
import serve6 from "../images/serve6.webp";
import digitalwhy from "../images/digitalwhy.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumb from "../Breadcrumb";
import Footer from "../../page/Homepages/Footer";
function Smm() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Finalnav />
      <Helmet>
        <title>
        Leading Digital Marketing Company in Jabalpur
        </title>
        <meta
          name="description"
          content="DOAGuru InfoSystems is the leading digital marketing company in Jabalpur. We provide Best Digital Marketing services that are made just for our client's needs and goals. Get in touch with us today to start your journey to digital marketing success."
        />
        <link
          rel="canonical"
          href="https://doaguru.com/Best-Digital-Marketing-Company-Jabalpur
"
        />
      </Helmet>
      <Breadcrumb />
      <Wrapper>
        <div
          className="container-fluid"
          id="cont"
          style={{
            backgroundImage: `url(${bgdigital})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row1">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <h1 className="hdd text-white">
              Best Digital Marketing Company in Jabalpur
              </h1>
              {/* <h1 className="hdd text-white">
                Best Digital Marketing Agency - Digital Dreams, Real Results
              </h1> */}
              {/* <h5 className="hdd1 text-white mt-3">
                "Empowering Brands in the Digital Age with Best digital
                marketing company"
              </h5> */}
              <p className="pdd text-white mt-4">
                In the realm of online promotion, digital marketing manifests as
                a dynamic and multi-dimensional strategy. This comprehensive
                endeavour harnesses an expansive range of avenues, resources,
                and tactics to engage with specific demographics, cultivate
                brand recognition, and ultimately spur intended responses
              </p>
              <button
                type="button"
                className="btn  mt-4"
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12">
              <img
                src={digitalhero}
                className="img3 d-block w-75"
                alt="..."
                id="imghero"
              />
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="head text-center">
            <h2>Digital Marketing</h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 20,
                marginBottom: 10,
              }}
            ></div>
            <p className="pp mt-4">
              <div data-aos="fade-up" data-aos-duration="3000">
                In today's fast-paced digital landscape, where technology reigns
                supreme, businesses have revolutionised their audience
                engagement strategies through collaborations with the best IT
                company. A merged entity of the best digital marketing company
                and IT powerhouse empowers companies to adeptly connect,
                captivate, and convert customers online. This comprehensive
                article offers insights into pivotal digital marketing
                components, furnishing in-depth expertise on each aspect and
                furnishing a step-by-step guide to crafting a highly successful
                digital marketing plan.
              </div>
            </p>
          </div>

          <h2 className="hh text-center mt-5">Services We Provide:</h2>
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "4rem",
              backgroundColor: "#34495E",
              marginTop: 20,
              marginBottom: 10,
            }}
          ></div>

          <div className="container  " id="dm">
            <div className="row">
              <div className="col-sm-6">
                <div data-aos="fade-right">
                  <img id="clientpages" src={serve1} className="img-fluid" />
                </div>
              </div>
              <div className="col-sm-6 text-center">
                <div data-aos="fade-left">
                  <h4 className="sm">Website and User Experience</h4>
                  <p className="tt text-justify mt-3">
                    Your business's website acts as its online storefront. To
                    ensure easy navigation and visitor engagement, a website
                    must be responsive and user-friendly. In order to keep and
                    convert potential customers, user experience (UX)
                    optimization is essential.
                  </p>

                  <button className="btn ">
                    <Link
                      to="/Top-Software-Company-in-Jabalpur"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      Learn More
                    </Link>
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="container  " id="dm">
            <div className="row">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <img id="clientpages" src={serve2} className="img-fluid" />
                </div>
              </div>
              <div className="col-sm-6 text-center" id="smd">
                <div data-aos="fade-right">
                  <h4 className="ipaid">Search Engine Optimization (SEO):</h4>
                  <p className="text-justify mt-3">
                    {" "}
                    To unlock your website's full potential, it's imperative to
                    ensure it's highly visible, and this is precisely where the
                    expertise of the best SEO company comes into play. By
                    mastering both on-page and off-page optimization along with
                    strategic keyword research, you have the power to dominate
                    search engine results pages and attract a steady stream of
                    organic traffic..
                  </p>
                  <button className="btn ">
                    {" "}
                    <Link
                      to="/Best-SEO-Company-in-Jabalpur"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      Learn More
                    </Link>
                  </button>
                </div>
                <br />
              </div>
              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <img id="clientpages" src={serve2} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>

          <div className="container" id="dm">
            <div className="row">
              <div className="col-sm-6">
                <div data-aos="fade-right">
                  <img
                    src={serve3}
                    className="img-fluid"
                    style={{
                      marginTop: "1em",
                      width: "32rem",
                      height: "18rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6 text-center">
                <div data-aos="fade-left">
                  <h4 className="sm ">Social Media Marketing:</h4>
                  <p className="text-justify mt-3">
                    {" "}
                    In our contemporary digital era, forging a profound
                    connection with your audience across various social media
                    platforms stands as a pivotal pursuit for both individuals
                    and enterprises alike. A preeminent agency specialising in
                    social media marketing comprehends the paramount importance
                    of crafting riveting content, meticulously orchestrating
                    posts, and judiciously deploying paid promotions on
                    platforms like Facebook, Instagram, and Twitter to augment
                    the sway of your brand.
                  </p>
                  <button className="btn ">
                    {" "}
                    <Link
                      to="/Best-social-media-marketing-company-in-Jabalpur-India"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      Learn More
                    </Link>
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div className="container " id="dm">
            <div className="row">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <img id="clientpages" src={serve4} className="img-fluid" />
                </div>
              </div>
              <div className="col-sm-6 text-center" id="smd">
                <div data-aos="fade-right">
                  <h4 className="ipaid ">Pay-Per-Click Advertising (PPC):</h4>
                  <p className="text-justify mt-3">
                    {" "}
                    In the world of paid advertising, PPC campaigns on websites
                    like Google Ads and Bing Ads offer precise targeting.
                    Choosing the right keywords and developing engaging ad copy
                    will help you draw in potential customers who are actively
                    looking for products or services that are similar to those
                    you offer.
                  </p>
                  <button className="btn ">
                    {" "}
                    <Link
                      to="/ppc"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      Learn More
                    </Link>
                  </button>
                </div>
                <br />
              </div>
              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <img id="clientpages" src={serve4} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>

          <div className="container " id="dm">
            <div className="row">
              <div className="col-sm-6">
                <div data-aos="fade-right">
                  <img
                    src={serve5}
                    className="img-fluid"
                    style={{
                      marginTop: "1em",
                      width: "32rem",
                      height: "18rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6 text-center">
                <div data-aos="fade-left">
                  <h4 className="sm ">Graphic Designing:</h4>
                  <p className="text-justify mt-3">
                    {" "}
                    Delivering top-notch Graphic Design Services means
                    skillfully crafting visual content to effectively
                    communicate messages, ideas, or information. This creative
                    discipline plays a pivotal role in various industries such
                    as marketing, advertising, web design, and entertainment,
                    ensuring your brand stands out with compelling visuals.
                  </p>
                  <button className="btn ">
                    {" "}
                    <Link
                      to="/graphic-designing"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      Learn More
                    </Link>
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="container " id="dm">
            <div className="row">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <img id="clientpages" src={serve6} className="img-fluid" />
                </div>
              </div>
              <div className="col-sm-6 text-center" id="smd">
                <div data-aos="fade-right">
                  <h4 style={{ marginBottom: "1rem" }} className="ipaid ">
                    Mobile App Development:
                  </h4>
                  <p className="text-justify mt-3">
                    The process of developing software applications for mobile
                    platforms, such as smartphones and tablets, is known as
                    mobile app development. Mobile apps are used for many
                    different things, from communication and entertainment to
                    productivity and business operations.
                  </p>
                  <button className="btn ">
                    {" "}
                    <Link
                      to="/mobile-development"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      Learn More
                    </Link>
                  </button>
                </div>
                <br />
              </div>
              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <img id="clientpages" src={serve6} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>

          <h5>
            Choose us as your Best digital marketing company and take your
            business to new heights.
          </h5>
          <h2>"Why Choose Us?"</h2>
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "4rem",
              backgroundColor: "#34495E",
              marginTop: 20,
              marginBottom: 30,
            }}
          ></div>

          <div className="row">
            <div className="col-sm-6">
              <li>Experience:</li>
              <p>
                With around 5 years of experience, as the Best IT Company and
                top-tier social media marketing agency merge, we have helped
                many businesses achieve unprecedented success in a remarkably
                short span of time. Our unparalleled expertise and cutting-edge
                solutions, spanning both IT services and social media marketing,
                have consistently propelled our clients towards excellence in
                the digital realm.
              </p>
              <li>Expertise: </li>
              <p>
                Our team of experts, sourced from the best digital marketing
                agencies and best IT companies, possesses unparalleled
                proficiency in the most current trends and technologies. Through
                a seamless integration of industry-leading skills, we provide
                innovative solutions precisely customised to meet your distinct
                requirements
              </p>
              <li>Customized Solutions: </li>
              <p>
                {" "}
                Within our agency, we exude a sense of accomplishment in
                formulating bespoke resolutions meticulously fashioned to
                harmonise with the particular requisites and aspirations of your
                business. We acknowledge the distinctiveness of each venture,
                each one carrying its unique set of challenges, strengths, and
                visions.
              </p>
              <li>Results-Driven Approach:</li>
              <p>
                At the heart of our mission lies an unwavering commitment to
                delivering tangible results and propelling conversions for our
                valued clients. We understand that in the dynamic landscape of
                digital marketing, achieving meaningful outcomes is paramount.
              </p>
              <li>Competitive Pricing:</li>
              <p>
                In our digital marketing agency, we derive immense satisfaction
                from delivering exceptional value to our clients. Our dedication
                to perfection is evident in our pricing strategy - we extend
                highly competitive rates without any compromise on the
                exceptional quality of our services.
              </p>
            </div>
            <div className="col-sm-6">
              <img src={digitalwhy} alt="" id="dw" />
            </div>
          </div>
        </div>
        <div className="container">
          <p className="p mt-5 text-center">
          Choose DOAGuru InfoSystems, the Best Digital Marketing Company in Jabalpur & across India. Discover why we're the best. Reach your business's full potential with us!
          </p>
          <h3>Technology we use</h3>
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "4rem",
              backgroundColor: "#34495E",
              marginTop: 20,
              marginBottom: 30,
            }}
          ></div>
        </div>
        <div className="container-2">
          <div className="card-group my-5" id="dm1">
            <div
              className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
              id="cards"
            >
              <img className="card-img-top" src={googleads} alt="card1" />
            </div>
            <div
              className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
              id="cards"
            >
              <img className="card-img-top" src={facebook} alt="card1" />
            </div>
            <div
              className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
              id="cards"
            >
              <img className="card-img-top" src={analyics} alt="card1" />
            </div>
            <div
              className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
              id="cards"
            >
              <img className="card-img-top" src={v} alt="card1" />
            </div>
            <div
              className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
              id="cards"
            >
              <img className="card-img-top" src={console} alt="card1" />
            </div>
            <div
              className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
              id="cards"
            >
              <img className="card-img-top" src={canva1} alt="card1" />
            </div>
          </div>
        </div>
        <div className="container" id="last">
          <div className="hero-section text-center">
            <h2 style={{ paddingTop: "3rem", color: "#155ea3" }}>
              Unleash Your Potential with Our Exclusive Digital Transformation
              Package{" "}
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
            <p className="mt-4">
              Mobile app development pertains to the process of crafting
              software applications tailored for mobile platforms, including
              smartphones and tablets. These applications serve a diverse array
              of functions, encompassing communication, entertainment,
              productivity, and facilitation of business operations. The
              progression entails planning, design, development, testing, and
              deployment, among other stages. When in search of the finest
              digital marketing agency to promote your mobile app, it is vital
              to align with a partner well-versed in the nuances of app
              marketing, capable of guiding you towards maximum visibility and
              triumph.
            </p>
            <button
              type="button"
              className="btn  mb-3 "
              style={{ color: "white" }}
            >
              <Link
                to="/contact_us"
                onClick={handleFooterLink}
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Enquire Now
              </Link>
            </button>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default Smm;

const Wrapper = styled.div`
  .container h5 {
    text-align: center;
    padding-top: 3rem;
  }
  .container h2 {
    text-align: center;
    padding-top: 30px;
  }
  .container h3 {
    text-align: center;
  }
  .container li {
    font-weight: 600;
  }
  .container .p {
    text-align: center;
    color: black;
    font-weight: 500;
    font-size: 20px;
  }
  .img1 {
    border-radius: 20px;
    width: 100%;
    height: 300px;
  }
  #cont {
    height: 38rem;

    @media screen and (max-width: 768px) {
      height: 55rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 60rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      height: 30rem;
    }
  }
  #row1 {
    margin-left: 5rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  #clientpages {
    width: 32rem;
    height: 18rem;
    border-radius: 1rem;
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
  }
  #smd {
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
  }
  #imghero {
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
      margin-left: 2.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: 5rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 0rem;
      height: 30rem;
    }
  }
  #dw {
    width: 40rem;
    height: 48rem;
    @media screen and (max-width: 768px) {
      width: 23rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 75rem;
      margin-top: -2rem;
    }
  }

  #cards {
    border-radius: 2rem;
    @media screen and (max-width: 768px) {
      height: 14rem;
      width: 14rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 5rem;
    }
  }
  #dm1 {
    @media screen and (max-width: 768px) {
      margin-left: 3.2rem;
    }
  }
  .sm {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1.2rem;
      font-size: 1.3rem;
    }
  }
  .ipaid {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0.5rem;
      font-size: 1.3rem;
    }
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    font-size: 28px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  #mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: block;
    }
  }
  #desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: none;
    }
  }
  #dm {
    margin-top: 3rem;
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1rem;
    }
  }
  .hdd {
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
  .hdd1 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;
