import React from "react";
import imp from "../../components/images/doafinal_f.mp4";
import styled from "styled-components";
import { Link } from "react-router-dom";
import poster from "../../components/images/poster.jpg"

const Header = () => {
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Wrapper>
      <section className="showcase">
        <video
        // is ka video google drive me dala he jis ka link ye raha https://drive.google.com/file/d/18pYUKotVwzoLi61dvX7fYlkpr8fS2EWy/view?usp=drive_link yadi ap ko video nhi milta he git clone karne me to video ap drive se download karke laga sakte ho apko kewal video ko image folder me dalna he jis ka path ap ko import imp me mil jye ga.
          src={imp}
          poster={poster}
          muted
          loop
          autoPlay
          className="background-video"
        ></video>
        <div className="overlay"></div>
        <div className="text">
          {/* <h1>Best Digital Marketing Company And IT Solutions
</h1>
          <p className="just" style={{ textAlign: "justify"  }}>
          Welcome to DOAGuru InfoSystems, where we provide expertise and IT solutions. As the Best Digital Marketing Company, in Jabalpur, we lead the way by offering customized marketing strategies to enhance your presence. Our skilled team develops solutions using advanced technology to drive your business towards success. With a focus on accuracy, creativity, and unwavering commitment we redefine what it means to excel in the industry ensuring that your brand stands out in the landscape. Discover the transformation experience, with DOAGuru InfoSystems—the industry's leading name.


          </p> */}

          
          <br />
          {/* <Link to="/about_us" onClick={handleFooterLink} id="aboutus">
        
            Know About Us
          </Link> */}
        </div>
      </section>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div`
  .showcase {
    position: relative;
    overflow: hidden;
    height: 100%;
    z-index: 2;
    @media screen and (max-width: 768px) {
      height: 40rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
    }
    @media screen and (min-width: 1021px) and (max-width: 1399px) {
      height: 43.5rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3300px) {
      height: 49rem;
    }
  }

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(106, 153, 227, 0.5); */
    z-index: -1;
  }
  a {
    text-decoration: none;
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 80%;
  }

  .text h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    margin-bottom: 1rem;
    }
  }

  .text p {
    font-size: 1.2rem;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
   
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 42rem;
    }
  }

  #aboutus {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ff2222;
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
    &:hover {
    background-color:  #ff7d7d;
    color: white;
  }
  }

  /* #aboutus:hover {
    background-color:  #ff7d7d;
    color: white;
  } */

  @keyframes glowing {
    0% {
      box-shadow: 0 0 5px #ff2222, 0 0 20px #f90d7c, 0 0 30px #0b1ae6;
    }
    50% {
      box-shadow: 0 0 5px #f5efef, 0 0 20px #f90d7c,
        0 0 50px #0b1ae6 0 0 100px #ff2222;
    }
    100% {
      box-shadow: 0 0 5px #0b1ae6, 0 0 20px #f90d7c, 0 0 30px #0b1ae6;
    }
  }

  @media (max-width: 375px),
    (max-width: 414px),
    (max-width: 393px),
    (max-width: 360px),
    (max-width: 412px) {
    .text {
      width: 90%;
    }
  }

  @media (max-width: 414px) and (max-height: 896px) {
    .text {
      width: 90%;
    }
  }
  a {
    text-decoration: none;
  }
`;
